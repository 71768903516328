import React, { Component } from 'react';
import styles from './ActivationTile.module.scss';
import cn from 'classnames';

class CancelModal extends Component {
    
    render() {
        const {onCancel, onConfirm} = this.props;

        return(
            <div className={styles.cancelModal}>
                <div className={cn(styles.title, "big-size-font")}>Cancel Subscription</div>
                <text>Are you sure you want to cancel your subscription?</text>   
                <div className={styles.buttons}>
                    <div className={styles.button} onClick={onCancel}>Cancel</div>
                    <div className={cn(styles.button, styles.confirm)} 
                        onClick={() => {onConfirm(); onCancel();}}>
                            Confirm                    
                    </div>
                </div>                             
            </div>
        );
    }
}


export default CancelModal;