import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import ModalWindow from '../../common/ModalWindow/ModalWindow';
import ServiceTerms from '../../containers/Terms/ServiceTerms';
import { getPaymentMethods, calculateCoupon, clearCalculateCoupon } from '../../store/AC/billing';
import styles from './BillingModal.module.scss';
import cn from "classnames";
import { Input } from "react-materialize";
import { Loader } from 'semantic-ui-react'
import {
    CardNumberElement,
    CardExpiryElement,
    CardCVCElement,
    injectStripe
} from 'react-stripe-elements';
import api from '../../utils/api';
import { Link } from 'react-router-dom';
import Spinner from '../../common/Spinner/Spinner';
import PlaidLink from 'react-plaid-link';
import PaymentMethods from './sections/PaymentMethods';
import PaymentSchedule from './sections/PaymentSchedule';
import PaymentReview from './sections/PaymentReview';

const PLAID_KEY = process.env.REACT_APP_PLAID_KEY;
const PLAID_ENV = process.env.REACT_APP_PLAID_ENV;

class BillingModal extends PureComponent {

    constructor(props) {
        super(props);                
        const step = this.props.license ? 1 : 0;
        this.state = {
            open: this.props.open,
            step: step,
            showButton: false,
            selectedPlan: this.props.plan,
            paymentType: 'credit',
            isLoading: false,
            activationsNumber: 1,
            activationsNumberError: false,
            coupon: '',
            loadingCoupon: false,
            couponError: false,
            stripeCardNumberEmpty: true,
            stripeCardNumberError: false,
            stripeCardExpiryEmpty: true,
            stripeCardExpiryError: false,
            stripeCVCElementEmpty: true,
            stripeCVCElementError: false,
            account_holder_name: '',
            account_holder_type: '',
            routing_number: '',
            account_number: '',
            chargeError: false,
            chargeErrorTitle: '',
            chargeErrorDescription: '',
            selectedMethod: null,
            showCreateMethods: false
        };        

        props.getPaymentMethods(this.selectMethod);
    }

    selectPlan = (id) => {        
        const plan = this.props.plans.find(plan => plan.id === id);        
        this.setState({
            ...this.state,
            selectedPlan: plan            
        })        
    }

    choosePaymentType = (type) => {
        this.setState({
            ...this.state,
            paymentType: type,
        })
    }

    showButton = () => {
        if(this.state.showButton === false)
            this.setState({ showButton: true});
        else
            this.setState({ showButton: false});
    }

    changeStep = (step) => {
        if (!this.state.activationsNumberError) {
            this.setState({
                step,
                stripeCardNumberEmpty: true,
                stripeCardNumberError: false,
                stripeCardExpiryEmpty: true,
                stripeCardExpiryError: false,
                stripeCVCElementEmpty: true,
                stripeCVCElementError: false
            });
        }        
    }

    handleCouponInput = (e) => {
        const couponValue = e.target.value;
        if (couponValue === '') { 
            this.props.clearCalculateCoupon();
        }
        this.setState({
            coupon: couponValue
        });
    }
    
    handleApplyCoupon = (callback) => {
        const { selectedPlan, activationsNumber, loadingCoupon, coupon } = this.state;
        if (loadingCoupon) return;
        const purchase = {
          price: selectedPlan.price,
          activationsAmount: activationsNumber,
          couponId: coupon  
        };
        this.setState({ loadingCoupon: true });
        this.props.calculateCoupon(purchase, callback);
    }
    
    clearCalculateCoupon = () => {
        this.setState({ loadingCoupon: false });
        this.props.clearCalculateCoupon();
    }
 
    handleQuantity = (quantity) => {
        this.setState({
            quantity
        });
    }

    handleActivationsNumber = (e) => {
        const { target } = e;
        let value = target.value;     
        this.clearCalculateCoupon();   
        if (isNaN(value) || value <= 0) {            
            this.setState({
                activationsNumberError: true,
                activationsNumber: value
            });
        } else {             
            this.setState({     
                activationsNumberError: false,           
                activationsNumber: value
            });
        }                
    }

    handleSubmit = (ev) => {
        ev.preventDefault();
        const { step } = this.state;
        const { stripeCardNumberEmpty, stripeCardExpiryEmpty, stripeCVCElementEmpty } = this.state;
        const { stripeCardNumberError, stripeCardExpiryError, stripeCVCElementError } = this.state;

        if (step === 2 && stripeCardNumberEmpty) {
            this.setState({ stripeCardNumberError: true});
        }

        if (stripeCardExpiryEmpty) {
            this.setState({stripeCardExpiryError: true});
        }

        if (stripeCVCElementEmpty) {
            this.setState({ stripeCVCElementError: true });
        }

        if (!((step === 2 && stripeCardNumberError) || stripeCardExpiryError || stripeCVCElementEmpty ||
           ((step === 2 && stripeCardNumberEmpty) || stripeCardExpiryEmpty || stripeCVCElementError))) {
                         
            this.setState({
                isLoading: true,
                open: false
            });

            const { stripe, license } = this.props;

            if (stripe) {
                stripe
                    .createToken()
                    .then(async (payload) => {                        
                        const data = {
                            stripe: payload,
                            plan: this.state.selectedPlan.id,
                            coupon: this.state.coupon,
                            activationsAmount: this.state.activationsNumber,
                            paymentSourceMethod: 'credit_card'
                        }
                        if (license) {
                            data.keycodeId = license.keycodeId;
                        }
                        this.createSubscription(data);

                    });
            } else {
                console.log("Stripe.js hasn't loaded yet.");
            }
        }
    };

    handleExistingMethodSubmit = () => {
        this.setState({
            isLoading: true,
        });
        const data = {
            existingMethod: this.state.selectedMethod,
            coupon: this.state.coupon,
            plan: this.state.selectedPlan.id,
            activationsAmount: this.state.activationsNumber,
            keycodeId: this.props.license ? this.props.license.keycodeId : null
        }
        this.createSubscription(data);
    }

    createSubscription = async (data) => {
        try {            
            const response = await api.post('api/Subscriptions/charge', data);
             this.clearCalculateCoupon();                  
            if (response.status === 200) {
                const { data: charged } = response;
                this.setState({
                    isLoading: false,
                    open: true
                });                
                if (charged && charged.status === 'succeeded') {
                    this.changeStep(4);
                }
            } else {
                this.setState({
                    chargeError: true,
                    chargeErrorTitle: 'Payment Error',
                    chargeErrorDescription: response.response.data.msg + '\nIf the problem persists please contact our support team at support@ailatech.com',
                    isLoading: false,
                    open: true,                    
                });
                this.changeStep(99);
            }
            
        } catch (error) {            
            this.setState({
                chargeError: true,
                chargeErrorTitle: 'Unexpected Error',
                chargeErrorDescription: 'Please contact our support team at support@ailatech.com or please try again',
                isLoading: false,
                open: true
            })            
            console.log(error);
            this.changeStep(99);
        }
    }

    termsOfUse = () => {        
        if (this.state.selectedPlan.id === 'trial') {
            this.createSubscription({
                plan: 'trial'
            });
        } else {
            this.changeStep(1);
        }
    }

    stripeElementChange = (element, name) => {

        if(element.empty) {
            this.setState({ [name + 'Empty']: true });
            this.setState({ [name + 'Error']: true });
        }

        if(!element.empty && !element.complete) {
            this.setState({ [name + 'Empty']: false });
            this.setState({ [name + 'Error']: true });
        }

        if (!element.empty && element.complete) {
            this.setState({ [name + 'Empty']: false });
            this.setState({ [name + 'Error']: false });
        }
    }

    handleOnExit = (ev) => {
        //Handle On Exit ACH Option
    };

    handleOnSuccess = (token, metadata) => {        

        this.setState({
            isLoading: true,
        });
        
        const { license } = this.props;
        
        let data = {
            plan: this.state.selectedPlan.id,
            coupon: this.state.coupon,
            paymentSourceMethod: 'ach',
            plaid: {
                token: token,
                account_id: metadata.account_id
            },
            activationsAmount: this.state.activationsNumber,
            keycodeId: license ? license.keycodeId : null
        }        
        this.createSubscription(data);
    };

    toggleCreateMethods = () => {
        this.setState({
            showCreateMethods: !this.state.showCreateMethods
        })
    }

    selectMethod = (method) => {        
        const selected = method.data && method.data.length > 0 ? method.data[0] : method;               
        this.setState({ selectedMethod: selected});                    
    }

    handleContinuePaymentOptions = () => {
        const  { showCreateMethods, paymentType, coupon } = this.state;
        const { paymentMethods } = this.props;
        let newStep;
        if (showCreateMethods || (paymentMethods && paymentMethods.length === 0)){
            if (paymentType === 'credit') {
                newStep = 2;
            } else {
               newStep = 3;
            }
        } else if(paymentMethods) {
            newStep = 5;
        }       
        if (coupon === '') {
            this.changeStep(newStep); 
        } else {
            this.handleApplyCoupon((res) => {
                const error = res.response && res.response.data.error;
                this.setState({ loadingCoupon: false });
                if (!error) this.changeStep(newStep);
            })
        }
    }

    render() {
        const { onClose, isPartner, assignedKeycodes, plans, license, paymentMethods, calculatedCoupon } = this.props;
        const { open, step, paymentType, isLoading, 
            form, activationsNumber, activationsNumberError, coupon, loadingCoupon, stripeCardNumberError,
            stripeCardExpiryError, stripeCVCElementError, selectedPlan,
            chargeErrorTitle, chargeErrorDescription, selectedMethod, showCreateMethods } = this.state;

        const planId = selectedPlan.id;

        return (
           <div>
                <ModalWindow  open={open} onClose={onClose} center>
                <div className={styles.container}>
                    {step === 0 ? 
                        <div>                        
                            <ServiceTerms></ServiceTerms>                        
                            <div className={cn(styles.footer, styles.flexCenter)}>
                                <button onClick={() => this.termsOfUse()}>CONTINUE</button>
                            </div>
                        </div> 
                    : null}
                    {step === 1 ? <div>
                        <div>
                            <div className={cn(styles.title, "big-size-font")}>Payment Options</div>
                            <div className={styles.body}>
                                <PaymentSchedule
                                    plans={plans}
                                    isPartner={isPartner}
                                    assignedKeycodes={assignedKeycodes}
                                    license={license}
                                    planId={planId}
                                    activationsNumber={activationsNumber}
                                    activationsNumberError={activationsNumberError}
                                    selectedPlan={selectedPlan}
                                    selectPlan={this.selectPlan}
                                    handleActivationsNumber={this.handleActivationsNumber}
                                    coupon={coupon}
                                    calculatedCoupon={calculatedCoupon}
                                    handleCouponInput={this.handleCouponInput}>                                    
                                </PaymentSchedule>
                                <hr className={'d-none d-md-block'}/>
                                <PaymentMethods 
                                    methods={paymentMethods} 
                                    select={this.selectMethod} 
                                    selected={selectedMethod}
                                    choosePaymentType={this.choosePaymentType}    
                                    paymentType={paymentType}
                                    showCreateMethods={showCreateMethods} 
                                    toggleCreateMethods={this.toggleCreateMethods}>                                    
                                </PaymentMethods>            
                            </div>
                        </div>
                        <div className={styles.footer}>
                            <button onClick={() => this.handleContinuePaymentOptions()}>{loadingCoupon ? <Loader inverted size='small' active inline /> : 'CONTINUE' }</button>
                        </div>
                    </div> : null}
                    {step === 2 ? <div>                        
                        <div className={cn(styles.title, "big-size-font")}>Credit Card Details</div>
                        <form className={isLoading ? styles.hide : null} onSubmit={this.handleSubmit}>
                            <div>                                                               
                                <div className={cn("input-field col s6")}>
                                    <CardNumberElement
                                        placeholder="Credit Card Number"
                                        className={stripeCardNumberError ? styles.dashed : null}
                                        name="stripeCardNumber"
                                        onChange={(element) => this.stripeElementChange(element, 'stripeCardNumber')}
                                    />
                                </div>
                                <div className={styles.cardSecureData}>
                                    <CardExpiryElement
                                        className={stripeCardExpiryError ? styles.dashed : null}
                                        name="stripeCardExpiry"
                                        onChange={(element) => this.stripeElementChange(element, 'stripeCardExpiry')}
                                    />
                                    <CardCVCElement
                                        className={stripeCVCElementError ? styles.dashed : null}
                                        name="stripeCVCElement"
                                        onChange={(element) => this.stripeElementChange(element, 'stripeCVCElement')}
                                    />
                                </div>
                            </div>
                            <div className={styles.summary}>                            
                                <p><strong>Selected Plan:</strong> {selectedPlan.title}</p>
                                <p><strong>Activations:</strong> {activationsNumber}</p>
                                {calculatedCoupon.calculated && (
                                    <p><strong>Coupon:</strong> -{calculatedCoupon.calculated.couponDiscount}</p>
                                    )
                                }
                                <p><strong>Total Cost: </strong> 
                                    { calculatedCoupon.calculated ? 
                                    (<span><del>${selectedPlan.price * activationsNumber}</del> {" $" + calculatedCoupon.calculated.total}</span>) : 
                                    (<span>${selectedPlan.price * activationsNumber}</span>)
                                    }
                                </p>
                             </div> 
                            <div className={cn(styles.return, styles.footer)}>
                                <span onClick={() => this.changeStep(1)}>Previous</span>
                                <button type="submit">COMPLETE</button>
                            </div>
                        </form>
                    </div> : null}
                    {step === 3 ? <div>
                        <div>
                            <div className={cn(styles.title, "big-size-font")} >ACH Details</div>
                            <div className={cn(styles.content)}>
                            <p>I authorize (your company) to electronically debit my account and, if necessary, electronically credit my account to correct erroneous debits.</p>
                            <Input onChange={() => this.showButton()} type="checkbox" value="authorized" label=" "/>                                                            
                                <div class={this.state.showButton ?  styles.plaidButton : styles.plaidButtonDisabled}>
                                    <PlaidLink clientName="Aila Developer Portal"
                                        env={PLAID_ENV}
                                        product={["auth", "transactions"]}
                                        publicKey={PLAID_KEY}
                                        onExit={this.handleOnExit}
                                        onSuccess={this.handleOnSuccess}>
                                            Open Link
                                    </PlaidLink>                                 
                                    <p>*Authorization Required</p>         
                                </div>                                                       
                            </div>
                        </div>

                    </div> : null}
                    {step === 4 ? <div>
                        <div className={cn(styles.title, "big-size-font")}>Confirmation</div>
                        <div className={styles.body}>
                            <span>
                                {selectedPlan.id !== 'trial' ?        
                                    <p className={styles.confirmationMessage}>
                                        The key will be generated after payment confirmation. <br/>                                                                                
                                        You may find the key displayed in the dashboard, it could take some time for updates.    
                                    </p>                                                                                               
                                :
                                    <p className={styles.confirmationMessage}>
                                        You may find the key displayed in the dashboard.
                                        <br/>
                                        <br/>
                                    </p>                                   
                                }                                    
                            </span>
                            </div>
                            <div className={cn(styles.footer, styles.flexCenter)}>
                                <Link to="/dashboard">
                                    <button onClick={onClose}>Go to Dashboard</button>
                                </Link>
                            </div>
                        </div> : null}
                        { step === 5 ?
                            <div>
                                <div className={cn(styles.title, "big-size-font")}>Payment Review</div>
                                <PaymentReview
                                    method={selectedMethod}
                                    plan={selectedPlan}
                                    activationsNumber={activationsNumber}
                                    coupon={calculatedCoupon}
                                    total={selectedPlan.price * activationsNumber}
                                    changeStep={this.changeStep}
                                    handleExistingMethodSubmit={this.handleExistingMethodSubmit}>
                                </PaymentReview>
                            </div>                            
                        : null}
                        {step === 99 ? <div>
                            <div className={cn(styles.title, "big-size-font")}>{chargeErrorTitle}</div>
                            <div className={styles.body}>
                                <span>                                                                             
                                    <p className={styles.confirmationMessage}>
                                        {chargeErrorDescription}
                                        <br/>
                                        <br/>
                                    </p>                                                                                                         
                                </span>
                            </div>
                            <div className={cn(styles.footer, styles.flexCenter)}>                                
                                <button onClick={onClose}>Close Window</button>                                
                            </div>
                        </div> : null}
                    </div>
                </ModalWindow>
                {isLoading ?
                    <Spinner>
                        Processing Payment
                    </Spinner>
                    : null
                }
           </div>
        );
    }
}

const mapStateToProps = (state) => {
    const assignedKeycodes = state.dashboard.assignedKeycodes;
    const {paymentMethods, coupon } = state.billing;
    const isPartner = !!state.authentification.fullUserInfo.roles.find(role => role === "Partner");
    return {
        assignedKeycodes,
        paymentMethods,
        isPartner,
        calculatedCoupon: coupon
        
    };
};

const mapDispatchToProps = (dispatch) => ({
    getPaymentMethods: (cb) => dispatch(getPaymentMethods(cb)),
    calculateCoupon: (purchase, cb) => dispatch(calculateCoupon(purchase, cb)),
    clearCalculateCoupon: () => dispatch(clearCalculateCoupon())
});

export default connect(mapStateToProps, mapDispatchToProps)(injectStripe(BillingModal));