import React, { PureComponent } from 'react';
import styles from '../BillingModal.module.scss';
import { Icon, Loader } from 'semantic-ui-react';
import cn from 'classnames';

class PaymentReview extends PureComponent {
    render() {
        const { method, plan, activationsNumber, coupon, total, changeStep, handleExistingMethodSubmit } = this.props;
        return (
            <div>
                <div className={styles.reviewContainer}>
                    {method.object == 'card' ?
                        <div>
                            <h3>Method Info</h3>
                            <div>
                                <strong>Issuer:</strong>
                                <p>{method.brand}</p>
                            </div>
                            <div>
                                <strong>Ends With:</strong>
                                <p>***{method.last4}</p>
                            </div>
                            <div>
                                <strong>Expiry:</strong>
                                <p>{method.exp_month}/{method.exp_year}</p>
                            </div>
                        </div>
                    :                      
                        <div>
                            <h3>Method Info</h3>
                            <div>
                                <strong>Bank Name:</strong>
                                <p>{method.bank_name[0] + method.bank_name.substring(1).toLowerCase()}</p>
                            </div>                            
                            <div>
                                <strong>Routing Number:</strong>
                                <p>{method.routing_number}</p>
                            </div>
                            <div>
                                <strong>Account Ends With:</strong>
                                <p>***{method.last4}</p>
                            </div>
                        </div>
                        }
                    <hr className={'d-none d-md-block'} />
                    <div>
                        <h3>Order Details</h3>
                        <div>
                            <strong>Selected Plan:</strong>
                            <p>{plan.title}</p>
                        </div>
                        <div>
                            <strong>Activations:</strong>
                            <p>{activationsNumber}</p>
                        </div>
                        {coupon.calculated && (
                            <div>
                                <strong>Coupon:</strong>
                                <p>-{coupon.calculated.couponDiscount}</p>
                            </div>)
                        }
                        <div>
                            <strong>Total:</strong>
                            <p>${coupon.calculated ?  (<span><del>{total}</del> {" $" + coupon.calculated.total}</span>) : total}</p>
                        </div>
                    </div>                    
                </div>
                <div className={cn(styles.footer, styles.flexCenter)}>
                    <span onClick={() => changeStep(1)}>Previous</span>
                    <button onClick={() => handleExistingMethodSubmit()}>Place Order</button>
                </div>
            </div>


        );
    }
}

export default PaymentReview;