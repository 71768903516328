import React, { Component } from 'react';
import styles from './KeycodeItem.module.scss';
import EditKeycode from '../EditKeycode/EditKeycode'
import { DELETE_KEYCODE } from '../../store/constants/keycodesManage';
import { formatDate } from '../../utils/helpers';

class KeycodeItem extends Component {

    constructor(props) {
        super(props);
        this.state = {
            openDevicesModal: false
        }
    }

    deleteKeycode = () => {
        const { keycodeData: { keycodeId, customer: { email } }, showModalWindow } = this.props;
        showModalWindow({
            openModal: true,
            modalTitle: 'Delete Keycode',
            modalDescription: `Are you sure you would like to delete keycode from user ${email} ?`,
            action: DELETE_KEYCODE
        });
        this.props.deleteKeycode(keycodeId);
    }

    editKeycode = () => {
        this.props.editKeycodeById(this.props.keycodeData.keycodeId);

    }

    saveEditKeycode = (data) => {
        this.props.saveEditKeycode(this.props.keycodeData.keycodeId, data);
    }
    
    handleOnClickDevices = () => {
        const keycode = this.props.keycodeData;
        if (keycode && keycode.keycodeId) {
            this.props.onClickDevices(keycode.keycodeId);
        }
    }
    
    render() {
        const { editableKeycodeId, keycodeData } = this.props;
        const { customer: { email }, keycodeId, code, startDate, expirationDate, description } = keycodeData;
                    
        return (
            <tr className={styles.keycodeItem}>
                <td>{email}</td>
                <td>{code}</td>
                <td>
                <div className={styles.manageDevice} onClick={this.handleOnClickDevices}>
                    Manage                   
                </div> 
                </td>
                <td>{formatDate(startDate)}</td>
                <td>{formatDate(expirationDate)}</td>
                <td>{description}</td>
                <td className={styles.actions}>           
                    <div className={styles.edit} onClick={this.editKeycode}>
                        Edit                       
                    </div>      
                    <div className={styles.modalContainer}>                    
                    { editableKeycodeId === keycodeId ? 
                        <EditKeycode
                            saveBtn={this.saveEditKeycode}
                            keycodeData={keycodeData}
                            {...this.props}
                        />     
                    : 
                    null           
                    }                                
                    </div>       
                </td>                
            </tr>            
        );
  }
}

export default KeycodeItem;