import React, { Component } from 'react';
import styles from './DeviceItem.module.scss';
import { RELEASE_DEVICE } from '../../store/constants/devicesManage';

class DeviceItem extends Component {
    releaseDevice = () => {
        const { deviceData: { deviceId, keycodeId }, showModalWindow } = this.props;
        showModalWindow({
            openModal: true,
            modalTitle: 'Release device',
            modalDescription: `Are you sure you would like to release the device?`,
            action: RELEASE_DEVICE
        });
        this.props.setReleaseDeviceParams(deviceId, keycodeId);
    }
    
    render() {
        const { deviceData, slot, canRelease} = this.props;
        const { deviceId, attached} = deviceData;
        const releaseButtonStyle = styles.release + (attached ? '' : ' ' + styles.disabled);

        return (
            <tr className={styles.DeviceItem}>
                <td>{slot}</td>
                <td>{deviceId}</td>
                <td>{attached ? 'Attached' : 'Released'}</td>
                { canRelease &&  (
                <td className={styles.actions}>
                    <div className={releaseButtonStyle} onClick={attached ? this.releaseDevice : () => {}}>
                        Release                        
                    </div>
                </td>)            
                }   
            </tr>            
        );
  }
}

export default DeviceItem;