
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { dashboardGetAssignedKeycodes } from '../../store/AC/dashboard';
import { getCurrentPlans, cleanPaymentMethods, cancelSubscription, clearCalculateCoupon } from '../../store/AC/billing';
import ActivationTile from '../../blocks/ActivationTile/ActivationTile';
import TileLayout from '../../common/TileLayout/TileLayout';
import GetStartedTile from '../../blocks/GetStartedTile/GetStartedTile';
import PageLayout from '../../common/PageLayout/PageLayout';
import BillingModal from '../../blocks/BillingModal';
import { Elements, StripeProvider } from 'react-stripe-elements';

class Dashboard extends Component {
   
    constructor(props) {
        super(props);        
        this.state = {
            stripeKey: process.env.REACT_APP_STRIPE_KEY,
            showLeftBlock: true,
            open: false,
            selectedLicense: null,
            selectedPlan: null            
        }
        this.props.getKeycodes();        
        this.props.getCurrentPlans();
    }
    
    getPlan(id) {
        if (this.props.plans && id) {
            return this.props.plans.find((plan) => plan.id === id);
        }
        return null;
    }
    
    openModal = (license) => {                             
        let plan = null;        
        if (license.description.toLowerCase() !== 'trial')      
           plan = this.getPlan(license.description.toLowerCase());
        else 
           plan = this.getPlan('quarterly');
        
        this.props.clearCalculateCoupon();
        this.setState({ open: true, selectedLicense: license, selectedPlan: plan });
    }

    closeModal = () => {
        this.props.getKeycodes();  
        this.setState({ open: false });
        this.props.cleanPaymentMethods();
    }

    onCancelSubscription = () => {
        this.props.getKeycodes();        
    }

    switchVisibleRightBlock = () => {
        this.setState({
            showLeftBlock: !this.state.showLeftBlock
        })
    }

  render() {
    const { showLeftBlock, stripeKey, open, selectedPlan, selectedLicense } = this.state;
    const { assignedKeycodes, plans, canRelease, cancelSubscription } = this.props;             
    
    return (
        <StripeProvider apiKey={stripeKey}>
            <Elements>
                <PageLayout title="My Dashboard">                
                    {showLeftBlock ? 
                        <TileLayout>
                            <GetStartedTile switchVisibleRightBlock={this.switchVisibleRightBlock} />
                        </TileLayout>
                    :null}

                    {assignedKeycodes ? assignedKeycodes.map((keycode, i) => (
                        <TileLayout key={i}>
                            <ActivationTile 
                                canRelease={canRelease} 
                                keycode={keycode} 
                                action={this.openModal} 
                                cancelSubscription={cancelSubscription}
                                onCancelSubscription={this.onCancelSubscription}
                            />
                        </TileLayout>
                    )) : null} 

                    {open ?                                          
                        <BillingModal
                            open = {open}
                            plans = {plans}
                            plan={selectedPlan}   
                            license={selectedLicense}                     
                            onClose={this.closeModal}                                
                        />                                            
                    : null}               
                </PageLayout>
            </Elements>
        </StripeProvider>
    );
  }
}

const mapStateToProps = (state) => {
    const { assignedKeycodes } = state.dashboard;
    const { plans } = state.billing;    
    const { fullUserInfo } = state.authentification;
    const canRelease = fullUserInfo.user.admin ||
        fullUserInfo.user.partner ||
        fullUserInfo.roles.includes('Customer');
    return {
        assignedKeycodes,
        plans,
        canRelease
    };
};
  
const mapDispatchToProps = dispatch => ({
    getKeycodes: () => dispatch(dashboardGetAssignedKeycodes()),
    getCurrentPlans: () => dispatch(getCurrentPlans()),
    cleanPaymentMethods: () => dispatch(cleanPaymentMethods()),
    cancelSubscription: (id, cb) => dispatch(cancelSubscription(id, cb)),
    clearCalculateCoupon: () => dispatch(clearCalculateCoupon())
});

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);