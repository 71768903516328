import {useQuery} from "@tanstack/react-query";
import api from './../../utils/api'

export const useGetRegistryLink = ({pkg, version}) => {
    return useQuery(['package', pkg, version], () => api({
        'method': 'GET',
        'url': `/api/Registry/getLink/${pkg}/${version}`
    }).then((res) => res.data), {
        enabled: false
    })
}
