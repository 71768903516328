import { ADMIN } from "../store/constants/main";

export const hideDelete = (roles) => {
    if(roles) {
        const role = roles.filter(x => x === ADMIN || x.name === ADMIN)[0];
        if(role) {
            return true;
        }
    }

    return false;
}

export const formatDate = (date) => {
    const fDate = new Date(date);
    let day = fDate.getDate();
    if(day<10) day = '0'+day;

    return `${fDate.getMonth()+1}/${day}/${fDate.getFullYear()}`
} 

export const isUndefined = (val) => {
    return typeof val === 'undefined';
}