import React, { Component } from 'react';
import styles from '../Documentation.module.scss';
import img1 from '../../../img/documentation/InstallingDiag_1.png'
import img2 from '../../../img/documentation/InstallingDiag_2.jpg'
import img3 from '../../../img/documentation/InstallingDiag_3.png'
import img4 from '../../../img/documentation/InstallingDiag_4.jpg'
import img5 from '../../../img/documentation/InstallingDiag_5.png'
import img6 from '../../../img/documentation/InstallingDiag_6.png'
import img7 from '../../../img/documentation/InstallingDiag_7.png'

class InstallingDiag extends Component {
    componentDidMount() {
        this.props.activeIndex('InstallingDiag');
    }

    render() {
        return (
            <div>
                <h1 className={styles.docTitle}>Installing Diagnostics Application</h1>
                <p>
                    In the event our support team needs more information about your Mobile Imager or Interactive Kiosk,
                    we’ve created a simple diagnostic app that will gather some device and supporting hardware data for
                    troubleshooting purposes.
                </p>
                <p>
                    <b>
                        If you haven’t already done so, contact support to receive a download link for the Aila Diagnostics
                        App and follow the steps below.
                    </b>
                </p>
                <h2>Instructions</h2>
                <h3>1st Step</h3>
                <figure>
                    <img src={img1}></img>
                    <figcaption>
                        Download the Aila Diagnostics app IPA to a computer with iTunes installed.
                    </figcaption>
                </figure>
                <h3>2nd Step</h3>
                <figure>
                    <img src={img2}></img>
                    <figcaption>
                        Connect your device and, if alerted to, choose “Trust this Computer.”
                    </figcaption>
                </figure>
                <h3>3rd Step</h3>
                <figure>
                    <img src={img3}></img>
                    <figcaption>
                        Open iTunes and connect your device. Once your device has been recognized within iTunes, locate the IPA 
                        file in your file manager and then drag to the ‘On My Device’ Section in iTunes. The app should begin 
                        copying to your device.
                        <p>
                            <br></br>
                            <b>
                                Note: you may need to update your device to the latest version of iOS for the diagnostic app to 
                                install.
                            </b>
                        </p>
                    </figcaption>
                </figure>
                <h3>4th Step</h3>
                <figure>
                    <img src={img4}></img>
                    <figcaption>
                        When you try to open the newly installed demo app on your device, you will be prompted to trust the 
                        developer of the app.
                    </figcaption>
                </figure>
                <h3>5th Step</h3>
                <figure>
                    <img src={img5}></img>
                    <figcaption>
                        On your device, navigate to Settings → General → Device Management → Padloc Inc. → and click ‘Trust Padloc Inc.’
                    </figcaption>
                </figure>
                <h3>6th Step</h3>
                <figure>
                    <img src={img6}></img>
                    <figcaption>
                        Navigate back to the Aila Diagnostic app, allow access to your camera and microphone when prompted.
                    </figcaption>
                </figure>
                <h3>7th Step</h3>
                <figure>
                    <img src={img7}></img>
                    <figcaption>
                        With the app open, press Submit to Aila and use the reference number(s) the Aila Support team has provided you.
                    </figcaption>
                </figure>
                <h2>Questions?</h2>
                <p>
                    Contact us at <a href="malito:support@ailatech.com">support@ailatech.com</a>
                </p>
            </div>
        );
    }
}

export default InstallingDiag