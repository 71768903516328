import React from 'react';
import styles from './ToolTile.module.scss';
import cn from "classnames";

const ToolTile = ({ icon, link, title, description }) => {
    return (
        <div className={cn('flex')}>
            <div className={styles.container}>
                <div>
                    <img src={icon} />
                </div>
                <a target="_blank" rel="noopener noreferrer" href={link}>{title}</a>
                <p>{description}</p>
            </div>
        </div>
    );
}

export default ToolTile;
