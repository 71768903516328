import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import styles from '../Documentation.module.scss';
import Snippet from '../../Snippet/Snippet.js'
import img1 from '../../../img/documentation/SDKPart2_1.png'
import img2 from '../../../img/documentation/SDKPart2_2.gif'
import img3 from '../../../img/documentation/SDKPart2_3.gif'
import img4 from '../../../img/documentation/SDKPart2_4.gif'


class SDKPart2 extends Component {
    componentDidMount() {
        this.props.activeIndex('SDK');
    }

    render() {
        return (
            <div>
                <h1 className={styles.docTitle}>Part 2: Scanning Barcodes</h1>
                <p>
                    In <Link to="/documentation"> Part 1</Link> of this tutorial we learned how to integrate Aila’s SDK to a swift Xcode project from
                    scratch. Once completed, we were able to scan several barcodes successfully but not much else.
                    Part 2 covers simple things that will enhance the scanning experience, like showing the camera’s
                    feed to help users aim better, display the barcode’s held information and its type or reproduce
                    a sound when a barcode is recognized.
                </p>
                <p>
                    Part 2 builds upon what we did on <Link to="/documentation"> Part 1</Link> so feel free to use the same Xcode project, or download it from here.
                </p>
                <p>
                    Also available is the Xcode project that results from doing the Tutorial Part 2 here.
                </p>
                <h2>
                    Show the camera’s feed
                </h2>
                <p>
                    Now it’s time to make it easier for people using our app to see what the phone is pointing to,
                    by showing what the camera captures on the screen.
                </p>
                <p>
                    Luckily for us, the SDK let us interact with the camera’s input stream by giving us an instance of
                    <em>AVCaptureSession</em> that we can use to display in a <em>AVCaptureVideoPreviewLayer</em>. The documentation warns
                    us about meddling with the given <em>AVCaptureSession</em>; we should only use it to add additional outputs
                    (which is what we intend to do anyway).
                </p>
                <p>
                    Go ahead and add the following function to your project:
                </p>
                <figure>
                    <Snippet>
                    {`
    private func previewLayerSetup() {
        guard let captureSession = Aila_GetAVCaptureSession() else {
            return
        }
        
        let videoPreviewLayer = AVCaptureVideoPreviewLayer(session: captureSession)
        
        let size = view.layer.frame.size
        videoPreviewLayer.videoGravity = .resizeAspectFill
        videoPreviewLayer.frame = CGRect(x: 0, y: 0, width: size.width, height: size.height)
        view.layer.addSublayer(videoPreviewLayer)
    }`
                    }
                </Snippet>
                </figure>
                <p>
                    Add a call to <em>previewLayerSetup()</em> in the <em>viewdidLoad()</em> method right after <em>PadlocScan_Start()</em> and run the project on your phone:
                    you’ll see the camera feed popping up.
                </p>
                <h2>
                    Reading barcodes
                </h2>
                <p>
                    Now that we can see what the phone sees, we can aim better at our barcodes. Let’s see how we can
                    retrieve their stored information through the SDK. In <Link to="/documentation">Part 1 </Link>
                    of the tutorial we learned how to print out on the console, the text held on any barcode scanned. Now we’re going
                    to print out its type too.
                </p>
                <p>
                    Start by looking in the project for the place where we set the scanCallback and replace it with the following:
                </p>
                <figure>
                <Snippet>
                    {`
    config.scanCallback = { [weak self] (results: Array<AilaScanObject>?) in
            DispatchQueue.main.async {
                if let results = results {
                    for scanObject in results {
                        let data : String = scanObject.data
                        print("Scanned: \\(data)")
                        let type : String = scanObject.typeDescription()
                        print("Type: \\(type)")
                    }
                }
            }
        }`
                    }
                </Snippet>
                </figure>

                <p>
                    Printing out all this information on the console is not useful for end users, let’s see how we can show a result
                    view with it in a more friendly fashion.
                </p>
                <p>
                    Next sections are not strictly tied to understanding the SDK and so they are labeled as <b>EXTRA</b>.
                </p>
                <h2>
                    [EXTRA] Add an overlaying results view
                </h2>
                <div className={styles.parallel + ' col-md-4 col-lg-4'}>
                     <p>
                        A nice way of showing the result of a barcode when scanned could be this overlaying view.
                     </p>
                     <p>
                        By also keeping track of the last barcode scanned, we can help users when scanning multiple times.
                     </p>
                     <p>
                        As long as the results view is visible we will prevent new scans from happening, and inform the
                        user to tap on the screen to dismiss it.
                     </p>
                     <p>
                        Let’s keep things simple, first of all, create a new class to model the barcodes scanned and call
                        it <em>ResultModel</em>.
                     </p>
                </div>
                <div className={styles.parallel + ' col-md-5 col-lg-5'}>
                    <figure>
                        <img src={img1}></img>
                        <figcaption>
                            This is a possible UI for a result’s view.
                        </figcaption>
                    </figure>
                </div>
                <figure>
                    <Snippet>
                        {`
    class ResultModel {
       var barcodeType = ""
       var barcodeText = ""
       init(barcodeType: String, barcodeText: String) {
           self.barcodeType = barcodeType
           self.barcodeText = barcodeText
       }
   }`
                        }
                    </Snippet>
                </figure>
                <p>
                    Next, create the UI to show the results after a successful scan. You can imitate the one we made or
                    craft one of your own, get creative!
                </p>
                <figure>
                    <img src={img2}></img>
                    <figcaption>Add an instance of UIView that will host the result’s labels.</figcaption>
                </figure>
                <figure>
                    <img src={img3}></img>
                    <figcaption>Add labels to show the barcodes’ text/type</figcaption>
                </figure>
                <figure>
                    <img src={img4}></img>
                    <figcaption>
                        Add a label on top so users know they will be able to tap anywhere to dismiss the result’s view. Add
                        outlets for every label on the view.
                    </figcaption>
                </figure>
                <p>
                    Now let’s write the code to show the results view when we need to:
                </p>
                <figure>
                    <Snippet>
                        {`
    private func showResultViewWithBarcode(_ barcodeScanned: ResultModel, andPastBarcode pastBarcodeScanned: ResultModel?) {
        resultView.isHidden = false
        
        barcodeTypeLabel.text = "Type: " + barcodeScanned.barcodeType
        barcodeTextLabel.text = "Text: " + barcodeScanned.barcodeText
        
        guard let unwrappedPastBarcodeScanned = pastBarcodeScanned else {
            pastScanLabel.isHidden = true
            pastScanTypeLabel.isHidden = true
            pastScanTextLabel.isHidden = true
            return
        }
        
        pastScanLabel.isHidden = false
        pastScanTypeLabel.isHidden = false
        pastScanTextLabel.isHidden = false
        
        pastScanTypeLabel.text = "Type: " + unwrappedPastBarcodeScanned.barcodeType
        pastScanTextLabel.text = "Text: " + unwrappedPastBarcodeScanned.barcodeText
    }`
                        }
                    </Snippet>
                    <figcaption>
                        Notice how pastBarcodeScanned is an optional, since it won’t be set for the first scan we make.
                    </figcaption>
                </figure>
                <p>
                    Finally, there’s a few small things to do to wrap up the feature:
                </p>
                <br/>
                <ul className={styles.list}>
                    <li>
                        <p>
                            Add <em>resultView.isHidden = true</em> at the beginning of <em>viewDidLoad()</em>.<br/>Right below add
                            <em>resultView.layer.zPosition = 1</em> which will display the results view over the
                            <em>AVCaptureVideoPreviewLayer</em>.
                        </p>
                    </li>
                    <li>
                        <p>
                            Still inside <em>viewDidLoad()</em>, add an instance of <em>UITapGestureRecognizer</em> that will help us dismiss
                            the results view:
                            <figure>
                                <Snippet>
                                    {`
    let tapInResultsView = UITapGestureRecognizer(target: self, action: #selector(self.handleTapInResultsView(sender:)))
    addGestureRecognizer(tapInResultsView)`
                                    }

                                </Snippet>
                            </figure>
                        </p>
                    </li>
                    <li>
                        <p>
                            Create the <em>handleTapInResultsView</em> method like this:
                        </p>
                        <figure>
                            <Snippet>
                                {`
    @objc fileprivate func handleTapInResultsView(sender: UITapGestureRecognizer? = nil) {
        resultView.isHidden = true
        Aila_Start()
     }`
                                }
                            </Snippet>
                        </figure>
                    </li>
                    <li>
                        <p>Create a var to store the latest barcode scanned: </p>
                        <figure>
                            <Snippet>
                                {`
    var lastCodeScanned: ResultModel? = nil`
                                }

                            </Snippet>
                        </figure>
                    </li>
                    <li>
                        <p>Update the <em>scanCallback</em> like this</p>
                        <figure>
                            <Snippet>
                                {`
    // 3 - Set the callback, code to execute when a barcode is scanned.
        config.scanCallback = { [weak self] (results: Array<AilaScanObject>?) in
            DispatchQueue.main.async {
                guard let weakSelf = self else {
                    return
                }
                
                if let results = results {
                    for scanObject in results {
                        let data : String = scanObject.data
                        print("Scanned: \\(data)")
                        let type : String = scanObject.typeDescription()
                        print("Type: \\(type)")
                        
                        let barcodeScanned = ResultModel(barcodeType: type, barcodeText: data)
                        weakSelf.showResultViewWithBarcode(barcodeScanned, andPastBarcode: weakSelf.lastCodeScanned)
                        weakSelf.lastCodeScanned = barcodeScanned
                        
                        weakSelf.vibratePhone()
                        weakSelf.audioPlayer?.play()
                        
                        Aila_Stop()
                    }
                }
            }
        }`
                                }
                            </Snippet>
                            <figcaption>
                                Notice how `Aila_Start()` is no longer called at the end of the block, and `Aila_Stop()`
                                is called instead: we are resuming scans when the results view is hidden.
                            </figcaption>
                        </figure>
                    </li>
                </ul>
                <p>
                    Go ahead and re run the app. If everything went well, we’re now showing a nice looking results view over the camera’s feed.
                </p>
                <h2>[EXTRA] Vibration and Sound after a successful scan</h2>
                <p>
                    Another nice little touch we can add to the app is more feedback after a barcode is scanned. Let’s play a short sound and make
                    the phone vibrate to let users ‘feel’ the successful scan.
                </p>
                <p>
                    Start by adding a short clip to the project that we will reproduce after scanning a barcode. Ours was called “spkrsnd.wav”.
                </p>
                <p>
                    With that around, let’s add a new var that will hold an instance of <em>AVAudioPlayer</em> like this: <br/><em>private var audioPlayer:
                    AVAudioPlayer? = nil</em>
                </p>
                <p>
                    We now need a function that sets up our audio player. After you copy it into your project, add a call to it inside <em>viewDidLoad()</em>.
                </p>
                <figure>
                    <Snippet>
                        {`
    private func audioPlayerSetup() {
        guard let url = Bundle.main.url(forResource: "spkrsnd", withExtension: "wav") else {
            return
        }
        do {
            audioPlayer = try AVAudioPlayer(contentsOf: url, fileTypeHint: nil)
        }
        catch let error as NSError {
            print(error.debugDescription)
        }
    }`
                        }
                    </Snippet>
                </figure>
                <p>
                    Calling <em>weakSelf.audioPlayer?.play()</em> inside the <em>scanCallback</em> will now reproduce a sound. Go ahead and add that to your project,
                    feel free to run the app and scan a barcode to test it.
                </p>
                <p>
                    Here’s the code snippet to make the phone vibrate. Notice the calls to <em>Aila_EnableSpeaker()</em> and <em>Aila_DisableSpeaker()</em>.
                    If you’re curious about them, read the SDK documentation for them and also read Apple’s info about <em>AudioServicesPlaySystemSoundWithCompletion</em>.
                </p>
                <figure>
                    <Snippet>
                        {`
    private func vibratePhone() {
        Aila_EnableSpeaker()
        AudioServicesPlaySystemSoundWithCompletion(kSystemSoundID_Vibrate) {
            Aila_DisableSpeaker()
        }
    }                        
                        `}
                    </Snippet>
                    <figcaption>
                        Make sure to call vibratePhone() before weakSelf.audioPlayer?.play(). Timing is important due to the interaction with the SDK.
                        Try out a different placing and see what happens.
                    </figcaption>
                </figure>
                <h2>What now?</h2>
                <p>
                    <Link to="/documentation/sdk-documentation3">Part 3 </Link> (last entry on this tutorial) it’s all about things to build around the app we’ve been making, that would expand the scanning
                    experience. Hope it inspires you to make ideas of your own!
                </p>
            </div>

        );
    }
}

export default SDKPart2;
