import { DASHBOARD_GET_KEYCODES, DASHBOARD_GET_ASSIGNED_KEYCODES } from "../constants/dashboard";
import { SUCCESS } from "../constants/main";


let initialState = {
    assignedKeycodes: [],
    keycodesActivation: 0
};

export default function dashboard(state = initialState, action) {
    const { payload } = action;  
    switch (action.type) {
        case DASHBOARD_GET_KEYCODES + SUCCESS:
            return {
                ...state,
                keycodesActivation: payload.length
            }
        case DASHBOARD_GET_ASSIGNED_KEYCODES + SUCCESS:
            return {
                ...state,
                assignedKeycodes: payload
            }
        default: 
            break;
 	} 
    return {
        ...state, 
    }
}
