import React, { Component } from 'react';
import styles from './InfoModal.module.scss';
import close from '../../img/close.png';

class InfoModal extends Component {

    closeModal = () => {
        this.props.showInformModal({
            openModal: false, 
            modalTitle: '', 
            modalDescription: '',
        })
    }
    
    render() {    
        const { modalData: { openModal, modalTitle, modalDescription }} = this.props;
        if (!openModal) return null;

        return (
            <div className={styles.logInWrapper + ' row' }>

            <div 
                className={styles.close} >
                <img src={close} alt='close' 
                onClick={this.closeModal} />
            </div>
            
            <div className='col-lg-8 offset-lg-2'>            
                <h1>{modalDescription}</h1>
            </div>
             
            <div className={styles.buttons+ ' col-lg-8 offset-lg-2'}> 
                <div> 
                    <div 
                        className={styles.button + ' btn btn-success'}
                        onClick={this.closeModal} 
                        >ok</div>
                </div>
            
            </div>
            
            </div>
        );
    }
}

export default InfoModal;
