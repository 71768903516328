import React, { Component } from 'react';
import styles from './Documentation.module.scss';
import { BrowserRouter as Router, Route, NavLink } from "react-router-dom";
import { Accordion, Icon } from "semantic-ui-react";
import Welcome from './Documents/Welcome';
import SampleApps from './Documents/SampleApps';
import SDKPart1 from './Documents/SDKPart1';
import SDKPart2 from './Documents/SDKPart2';
import SDKPart3 from './Documents/SDKPart3';
import MobileImg from './Documents/MobileImg';
import InstallingDemo from './Documents/InstallingDemo';
import InteractiveKiosk from './Documents/InteractiveKiosk';
import ProductSheets from './Documents/ProductSheets';
import InstallingDiag from './Documents/InstallingDiag';
import AssemblyGuide from './Documents/AssemblyGuide';

class Documentation extends Component {

  state = {
    showMenu: true,
    activeIndex: -1
  }

  showSubMenu = () => {
    if (window.innerWidth < 768) {
      this.setState({
        showMenu: !this.state.showMenu,
      });
    }
  }
  componentWillMount() {
    const self = this;
    if (window.innerWidth < 768) {
      this.setState({ showMenu: false })
    }
    window.addEventListener('resize', function () {
      if (window.innerWidth < 767) {
        self.setState({
          showMenu: false,
          isMobile: true,
        })
      } else {
        self.setState({
          showMenu: true,
        })
      }
    })
  }

  handleClick = (e, titleProps) => {
    const { index } = titleProps
    const { activeIndex } = this.state
    const newIndex = activeIndex === index ? -1 : index
    this.handleActiveIndex(newIndex);
  }

  handleActiveIndex = (index) => {
    this.setState({ activeIndex: index });
  }

  render() {
    const linkAct = styles.activeLink;
    const { showMenu } = this.state;
    const { activeIndex } = this.state
    const styleMenuBtn = showMenu ? { 'left': '160px' } : { 'left': '-40px' };
    return (
        <div className={styles.docContainer}>
          <div className='row no-gutters'>
            <div className={styles.subMenu} style={styleMenuBtn} onClick={this.showSubMenu}>{showMenu ? 'Hide' : 'Show'} sub menu</div>
            {showMenu ?
              <menu className='col-lg-2 col-md-3'>
                <Accordion>
                  <Accordion.Title active={activeIndex === 'SDK'} index={'SDK'} onClick={this.handleClick}>
                    <div>Aila SDK for SoftScan Integration</div>
                  </Accordion.Title>
                  <Accordion.Content active={activeIndex === 'SDK'}>
                    <NavLink to='/documentation' exact activeClassName={linkAct}>
                      <div onClick={this.showSubMenu}>Integrating SDK</div>
                    </NavLink>
                    <NavLink to='/documentation/sdk-documentation2' activeClassName={linkAct}>
                      <div onClick={this.showSubMenu}>Scanning Barcodes</div>
                    </NavLink>
                    <NavLink to='/documentation/sdk-documentation3' activeClassName={linkAct}>
                      <div onClick={this.showSubMenu}>Enhancing Experience</div>
                    </NavLink>
                  </Accordion.Content>
                </Accordion>
              </menu>
              : null}
            <div className={styles.dockBlock + ' col-lg-8 offset-lg-3 col-md-8 offset-md-3 col-xs-10 offset-xs-1'}>
              <div className={styles.oneDocItem}>
                <Route exact path='/documentation' render={() => <SDKPart1 activeIndex={this.handleActiveIndex} />} />
                <Route path='/documentation/sdk-documentation2' render={() => <SDKPart2 activeIndex={this.handleActiveIndex} />} />
                <Route path='/documentation/sdk-documentation3' render={() => <SDKPart3 activeIndex={this.handleActiveIndex} />} />
                <Route path='/documentation/mobile-imager' render={() => <MobileImg activeIndex={this.handleActiveIndex} />} />
                <Route path='/documentation/installing-demo' render={() => <InstallingDemo activeIndex={this.handleActiveIndex} />} />
                <Route path='/documentation/installing-diag' render={() => <InstallingDiag activeIndex={this.handleActiveIndex} />} />
                <Route path='/documentation/interactive-kiosk' render={() => <InteractiveKiosk activeIndex={this.handleActiveIndex} />} />
                <Route path='/documentation/sample-apps' render={() => <SampleApps activeIndex={this.handleActiveIndex} />} />
                <Route path='/documentation/assembly-guide' render={() => <AssemblyGuide activeIndex={this.handleActiveIndex} />} />
              </div>
            </div>
          </div>
        </div>
    );
  }
}

export default Documentation;
