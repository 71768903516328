import React, { Component } from 'react';
import Header from './blocks/Header/Header';
import Footer from './blocks/Footer/Footer';
import Dashboard from '../src/containers/Dashboard/Dashboard';
import Downloads from '../src/containers/Downloads/Downloads';
import Documentation from '../src/containers/Documentation/Documentation';
import Tools from '../src/containers/Tools/Tools';
import Billing from '../src/containers/Billing/Billing';
import ManageUsers from '../src/containers/ManageUsers/ManageUsers';
import ManageKeycodes from '../src/containers/ManageKeycodes/ManageKeycodes';
import ResetPassword from '../src/containers/ResetPassword/ResetPassword';
import { showInformModal } from './store/AC/manageModalWindow';
import { connect } from 'react-redux'
import { Router, Route, Switch } from "react-router-dom";
import ConfirmModalWindow from './containers/ConfirmModalWindow/ConfirmModalWindow';
import InfoModal from './blocks/InfoModal/InfoModal';
import history from './utils/history';
import LogIn from './containers/LogIn/LogIn';
import SignUp from './containers/SignUp/SignUp';
import AccountSettings from './containers/AccountSettings/AccountSettings';
import {Toaster} from "react-hot-toast";

class App extends Component {

  render() {
		const {  isLogin, userID, modalData, showInformModal, shouldChangePassword, } = this.props;
		let auth = false;
		let pwdChange = false;
		if(shouldChangePassword){
			pwdChange = true;
		}
		if(isLogin && userID!=='') {
			auth = true;
		}

		history.listen((location, action) => {
  		document.querySelector('#root').scrollTop = 0
		});

		return (
			<Router history={history}>
		  <div>
				{auth ? <Header /> : null}
			  <Toaster/>
		    <div className='mainContainer'>

					{!auth ? <div>
										 <Switch>
										 		<Route exact path="/register" component={SignUp}/>
												<Route path={'/auth/reset'} component={ResetPassword} />
												<Route path="/" component={LogIn} />
										 </Switch>
									 </div> :
						<div>

							<Route exact path="/" component={Dashboard} />
							<Route path="/account-settings" component={AccountSettings} />
							<Route path="/dashboard" component={Dashboard} />
							<Route path="/downloads" component={Downloads} />
							<Route path="/documentation" component={Documentation} />
							<Route path="/tools" component={Tools} />
							<Route path="/billing" component={Billing} />
							<Route path="/manage-users" component={ManageUsers} />
							<Route path="/manage-keycodes" component={ManageKeycodes} />
						</div>
					}

		      <ConfirmModalWindow />
					<InfoModal  modalData={modalData} showInformModal={showInformModal} />
		      <Footer />
		    </div>
		  </div>
      </Router>
    );
  }
}

const mapStateToProps = (state) => {
		const { isLogin, userID, newUserCreated, shouldChangePassword } = state.authentification;
		const { openModal, modalTitle, modalDescription, } = state.manageInfoModal
		const modalData = { openModal, modalTitle, modalDescription };
    return {
        isLogin, userID, newUserCreated, modalData, shouldChangePassword
    };
};

const mapDispatchToProps = dispatch => ({
  showInformModal: modalData => dispatch(showInformModal(modalData)),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
