import { isValidPhoneNumber } from 'react-phone-number-input'



export const emailValidator = (value) => {
    const reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
    return reg.test(value)
 };

export const phoneValidator = (value) => {
    const validate = value.replace(/[()-\s]+/g,'');
    return true//isValidPhoneNumber(validate);    ;
}

export const fieldNotEmpty = (value) => {
    return value.length>0;
}
