import React, { Component } from 'react';
import prettify from './prettify.js'
import './prettify.css'

class Snippet extends Component {
    
    render () {    
        const PR = window.PR;
        const code = PR.prettyPrintOne(this.props.children, undefined, true);
        return (         
            <pre className="prettyprint linenums" 
                dangerouslySetInnerHTML={{__html: code}}>
            </pre>
            
        );
    }  
}

export default Snippet;