import React, { PureComponent } from 'react';
import { Icon, Loader } from 'semantic-ui-react';
import ModalWindow from '../../common/ModalWindow/ModalWindow';
import ServiceTerms from '../../containers/Terms/ServiceTerms';
import styles from './DownloadSDKModal.module.scss';
import cn from "classnames";

class DownloadSDKModal extends PureComponent {
  constructor(props) {
    super(props);        

    this.state = {
        download: ''
    };        
  }
  
  render() {
    const {loading, open, onClose, onClickDownload, error} = this.props;
    return (
        <div>
            <ModalWindow  closeOnOverlayClick={false} closeOnEsc={false} open={open} onClose={onClose} center>                      
              <ServiceTerms></ServiceTerms>                        
              <div className={cn(styles.footer, styles.flexCenter)}>
                  <button onClick={onClickDownload}>{loading ? <Loader inverted size='small' active inline /> : 'Download' }</button>  
              </div>
              {error ? 
                <div className={'message error ' +  styles.flexCenter}>
                    <Icon name="warning circle"></Icon>
                    <p>Error downloading file. Please try again.</p>
                </div> : null
              } 
            </ModalWindow>
        </div>
    );
  }
}

export default DownloadSDKModal;