import {
    GET_ALL_KEYCODES,
    EDIT_KEYCODE_BY_ID,
    SAVE_EDIT_KEYCODE,
    SAVE_KEYCODE,
    DELETE_KEYCODE,
    DELETE_KEYCODE_ID,
    // RESET_KEYCODE_PASSWORD,
    RELEASE_KEYCODE
} from '../constants/keycodesManage';

export const editKeycodeById = (id) => ({ type: EDIT_KEYCODE_BY_ID, payload: id });
export const setDeleteKeycodeId = (id) => ({ type: DELETE_KEYCODE_ID, payload: id });

export const getAllKeycodes= data => ({ 
    type: GET_ALL_KEYCODES,
    payload: Object.assign( {}, data, { requestMethod:'GET', requestPath: 'api/Keycodes' })
});

export const addNewKeycode = data => ({ 
    type: SAVE_KEYCODE,
    payload: Object.assign( {}, data, { requestMethod:'POST', requestPath: 'api/Keycodes' })
});

export const saveEditKeycode = (id, data) => ({ 
    type: SAVE_EDIT_KEYCODE,
    payload: Object.assign( {}, data, { requestMethod:'PUT', requestPath: `api/Keycodes/${id}` })
});

export const deleteKeycodeById = id => ({ 
    type: DELETE_KEYCODE,
    payload: Object.assign({}, { requestMethod:'DELETE', requestPath: `api/Keycodes/${id}` })
});

export const releaseKeycode = data => ({
    type: RELEASE_KEYCODE,
    payload: Object.assign({}, data, {requestMethod:'POST', requestPath: 'api/Keycodes/release'})
});

