import {
  GET_PAYMENT_HISTORY, SEND_INVITE, GET_INVITED_USERS, DELETE_INVITE
} from '../constants/accountSettings';
import { SUCCESS, FAIL } from '../constants/main';

let initialState = {
  payments: [],
  page: 1,
  size: 0,
  totalPages: 1,
  limit: 10,
  errorMessage: null,
  invitedUsers: null
};

export default function accountSettings(state = initialState, action) {
  switch (action.type) {
      case GET_PAYMENT_HISTORY + SUCCESS:
        return{
              ...state,
              payments: action.payload.data,
              page: action.payload.page,
              size: action.payload.size,
              totalPages: action.payload.totalPages,
              limit: action.payload.limit,
              errorMessage: null
          }
      case GET_PAYMENT_HISTORY + FAIL:
        return{
              ...state,
              getPaymentsCompleted: true,
              errorMessage: action.payload.message
          }
      case SEND_INVITE + SUCCESS:        
        state.invitedUsers.unshift(action.payload)
        return {
          ...state                 
        }
      case SEND_INVITE + FAIL:
        return {
          ...state
        }
      case GET_INVITED_USERS + SUCCESS:        
        return {
          ...state,
          invitedUsers: action.payload.data
        }        
      case GET_INVITED_USERS + FAIL:
        return {
          ...state,
          invitedUsers: null
        }  
      case DELETE_INVITE + SUCCESS:        
        return {
           ...state,
           invitedUsers: state.invitedUsers.filter(user => { return user.id != action.payload })
        }
      case DELETE_INVITE + FAIL:
        return {
          ...state
        }
      default: 
          break;
 } 
  return {
      ...state, 
  }
}
