import React, { Component } from 'react';
import { connect } from 'react-redux';
import styles from './SharedAccounts.module.scss';
import { Input } from 'react-materialize';
import SharedAccountsItem from '../../blocks/SharedAccountsItem/SharedAccountsItem';
import { emailValidator } from '../../utils/validators';
import { sendInvite, getInvitedUsers, deleteInvite } from '../../store/AC/accountSettings';
import SharedAccountsDeleteModal from './SharedAccountsDeleteModal';
import ModalWindow from '../../common/ModalWindow/ModalWindow';

class SharedAccounts extends Component {
    constructor(props) {
        super(props);
        this.state = {    
            invite: '',
            errorInvite: false,
            inviteResponse: null,
            openDeleteModal: false,
            deleteUser: null                                        
        }
        this.props.getInvitedUsers();
    }
          
    componentDidMount() {
      this.props.activeIndex('SharedAccounts');    
    }                  
    
    renderSharedAccounts = () => {
        const { invitedUsers } = this.props;
        if (invitedUsers) {
            return invitedUsers.map(user => {
                return (
                    <SharedAccountsItem 
                        user={user} 
                        onOpenDeleteModal={this.onOpenDeleteModal}
                    />
                );
            });
        } else {
            return null;
        }
        
      }

    handleChangeInvite = (e) => {
        this.setState({ 
            invite: e.target.value,
            errorInvite: !emailValidator(e.target.value),
            inviteResponse: null
        });
    }

    handleSendInvite = (data) => {
        const  { response } = data;              
        if (response && response.status == 400) {
            this.setState({
                errorInvite: true,
                inviteResponse: response.data.error.message
            });
        } else {
            this.setState({
                errorInvite: false,
                inviteResponse: 'Account invited successfully'
            })  
        }    
    }
    
    sendInvite = () => {
        const { errorInvite, invite } = this.state;        
        if(!errorInvite && invite.length > 0) {
            this.props.sendInvite(invite, this.handleSendInvite);
        } else {
            this.setState({
                errorInvite: !emailValidator(invite)
            });
        }
    }

    onCloseDeleteModal = () => {
        this.setState({
            openDeleteModal: false
        })        
    }

    onOpenDeleteModal = (user) => {
        this.setState({
            openDeleteModal: true,
            deleteUser: user
        })
    }

    render() {        
        const { invite, errorInvite, inviteResponse, openDeleteModal, deleteUser } = this.state;         
        const { deleteInvite, invitedUsers } = this.props;       
        return (            
            <div className={styles.sharedAccountsPage}>               
                <div className={styles.sharedAccountsContainer}>
                    <p className='big-size-font'>Shared Accounts</p>                           
                    <div className={styles.inviteContainer}>
                        <span className={errorInvite ? styles.errorField : ''} >
                            <Input onChange={this.handleChangeInvite} value={invite} label="Invite Email" />
                        </span>
                        <div className={styles.blueBtn} onClick={() => this.sendInvite()}>
                            Send Invite
                        </div>
                    </div>
                    <div className={ errorInvite ? styles.errorContainer : styles.successContainer}>
                        {inviteResponse ? 
                            <div>
                                {inviteResponse}
                            </div>
                        : null
                        }   
                    </div>
                    <table className={styles.sharedAccountsTable + ' table table-hover'}>
                        <tbody>
                            <tr className=''>
                                <th>Email</th>
                                <th>Date</th>
                                <th>Actions</th>
                            </tr>
                            {this.renderSharedAccounts()}
                        </tbody>
                    </table>
                    {invitedUsers && invitedUsers.length == 0 ? 
                        <div className={styles.noItems}>
                            No Items Found
                        </div>
                    : 
                        null
                    }
                    
                </div>
                <ModalWindow open={openDeleteModal} onClose={this.onCloseDeleteModal} center>
                    <SharedAccountsDeleteModal
                        onCancel={this.onCloseCancelModal}
                        deleteUser={deleteUser}                        
                        onConfirm={deleteInvite}
                        onCancel={this.onCloseDeleteModal}/>
                </ModalWindow>  
            </div>
        );
    }
}

const mapStateToProps = (state) => {    
    return {
        ...state.accountSettings        
    };
};

const mapDispatchToProps = dispatch => ({ 
    sendInvite: (invite, cb) => dispatch(sendInvite(invite, cb)),
    getInvitedUsers: (cb) => dispatch(getInvitedUsers(cb)),
    deleteInvite: (id, cb) => dispatch(deleteInvite(id,cb))
});

export default connect(mapStateToProps, mapDispatchToProps)(SharedAccounts);