import React from 'react';
import styles from './GetStartedTile.module.scss';
import icon_1 from '../../img/icon_t.png';
import icon_2 from '../../img/icon_l.png';
import icon_3 from '../../img/icon_f.png';
import close_icon from '../../img/close.png';
import { NavLink } from 'react-router-dom';
import { Icon } from 'semantic-ui-react';
import cn from "classnames";

const GetStartedTile = ({ switchVisibleRightBlock }) => {
    return (
        <div className={styles.getStartedTile}>
            <div className={styles.headerBox}>
                <div className={styles.title}>
                    <p className='middle-size-font'>Getting Started</p>
                </div>
                <div className={styles.closeMsg}>
                    <Icon link name="close" onClick={switchVisibleRightBlock}></Icon>
                </div>
            </div>
            <div className={styles.startedInfoContainer}>
                <div className={styles.startedInfoItem}>
                    <div className={cn(styles.imgBox, 'd-none d-md-block')}>
                        <NavLink to="account">
                            <img src={icon_1} alt='alt_text' />
                        </NavLink>
                    </div>
                    <div>
                        <span>
                            <NavLink to="/account-settings">
                                Manage your account
                        </NavLink>
                        </span>
                        <p>Manage the details of your Aila account</p>
                    </div>
                </div>

                <div className={styles.startedInfoItem}>
                    <div className={cn(styles.imgBox, 'd-none d-md-block')}>
                        <NavLink to='/downloads'>
                            <img src={icon_2} alt='alt_text' />
                        </NavLink>
                    </div>
                    <div>
                        <span>
                            <NavLink to='/downloads'>
                                Downloads
                        </NavLink>
                        </span>
                        <p>Download our latest SDK for your project</p>
                    </div>
                </div>

                <div className={styles.startedInfoItem}>
                    <div className={cn(styles.imgBox, 'd-none d-md-block')}>
                        <NavLink to='/documentation'>
                            <img src={icon_3} alt='alt_text' />
                        </NavLink>
                    </div>
                    <div>
                        <span>
                            <NavLink to='/documentation'>
                                Get Started
                        </NavLink>
                        </span>
                        <p>View our documentation to assist with integration</p>
                    </div>
                </div>

            </div>
        </div>
    );
}

export default GetStartedTile;