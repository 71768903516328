import React from 'react';
import styles from './Phone.module.scss';
import ReactPhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

const Phone = ({ value, onChange, placeholder, phoneCountry }) => {

    return (
        <div className={styles.container}>
              <p className={value ? styles.active : ''}>* Phone</p>
               <ReactPhoneInput
                    defaultCountry={phoneCountry}
                    excludeCountries={['cu', 'ir', 'lb', 'sy', 'kp', 'ly', 'sd', 'so']}
                    placeholder={placeholder}
                    value={value}
                    onChange={onChange}
                    disableAreaCodes={true}
                />
        </div>
    );
}

export default Phone;
