import React, { Component } from 'react';
import styles from '../Documentation.module.scss';
import stylesAssembly from './AssemblyGuide.module.scss';
import img1 from '../../../img/documentation/AssemblyGuide_1.png'


class AssemblyGuide extends Component {

    componentDidMount() {
        this.props.activeIndex('AssemblyGuide');
    }

    render() {
        return(
            <div className={stylesAssembly.assemblyGuide}>
                <h1 className={styles.docTitle}>Watch the quick-start assembly video for the Interactive Kiosk for iPad Pro</h1>
                <br/>
                <figure>
                    <iframe src="https://www.youtube.com/embed/eQBm6zI17pI" frameborder="0" 
                            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen>
                    </iframe>
                </figure>
                <br/>
                <h2>
                    Download the Assembly Guide PDF <a href="https://storage.googleapis.com/aila-downloads/docs/Aila-Assembly-Guide-IK-12.9-2.0.pdf">HERE</a>
                </h2>
                <br/>
                <img src={img1}/>
            </div>
        );
    }
}

export default AssemblyGuide;