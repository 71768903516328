import {
  GET_DEVICES,
  RELEASE_DEVICE_ID
} from '../constants/devicesManage';
import { SUCCESS, FAIL } from '../constants/main';

let initialState = {
  devices: [],
  releaseDeviceId: null,
  page: 1,
  size: 0,
  totalPages: 1,
  limit: 10
};

export default function devicesManage(state = initialState, action) {
  switch (action.type) {
      case RELEASE_DEVICE_ID:
        return{
            ...state,
            releaseDeviceId: action.payload.deviceId,
            releaseKeycodeId: action.payload.keycodeId,
        }
      case GET_DEVICES + SUCCESS:
        return{
              ...state,
              devices: action.payload.data,
              page: action.payload.page,
              size: action.payload.size,
              totalPages: action.payload.totalPages,
              limit: action.payload.limit,
              errorMessage: null
          }
      case GET_DEVICES + FAIL:
        return{
              ...state,
              errorMessage: action.payload.response.data.error.message
          }
      default: 
          break;
 } 
  return {
      ...state, 
  }
}
