import {
  GET_PAYMENT_HISTORY, SEND_INVITE, GET_INVITED_USERS, DELETE_INVITE
} from '../constants/accountSettings';

export const getPaymentHistory = (data, cb) => ({
  type: GET_PAYMENT_HISTORY,
  payload: Object.assign({}, data, { requestMethod:'GET', requestPath: 'api/Payments/history' }, { callback: cb })
});

export const sendInvite = (invite, cb) => ({
  type: SEND_INVITE,
  payload: Object.assign({}, { email: invite }, { requestMethod:'POST', requestPath:'api/Customers/invites' }, { callback: cb })
});

export const getInvitedUsers = (cb) => ({
  type: GET_INVITED_USERS,
  payload: Object.assign({}, { requestMethod:'GET', requestPath:'api/Customers/invites' }, { callback: cb })
});

export const deleteInvite = (id, cb) => ({
  type: DELETE_INVITE,
  payload: Object.assign({}, { requestMethod:'DELETE', requestPath:'api/Customers/invites/' + id }, { callback: cb })
});