import { 
    CHANGE_PASSWORD,
    UPDATE_USER
} from '../constants/authentification'; 
import {
    SHOW_INFO_MODAL,
} from '../constants/manageModalWindow';
import { SUCCESS } from '../constants/main';

let initialState = {
    openModal: false,
    modalTitle:'',
    modalDescription: '',
};

export default function manageInfoModal(state = initialState, action) {
    const { payload, type, } = action;  
    switch (type) {
        case CHANGE_PASSWORD + SUCCESS:

        return { 
            ...state,            
        }
        case UPDATE_USER + SUCCESS:
            return { ...state }
        case SHOW_INFO_MODAL:
            return payload;
        default: 
            break;
 	} 
    return {
        ...state, 
    }
}
