import { DASHBOARD_GET_KEYCODES, DASHBOARD_GET_ASSIGNED_KEYCODES } from "../constants/dashboard";


export const dashboardGetAssignedKeycodes = () => ({ 
    type: DASHBOARD_GET_ASSIGNED_KEYCODES,
    payload: Object.assign( {}, 
        { requestMethod:'GET', requestPath: `api/Keycodes/assigned` }
    )
});

export const dashboardGetKeycodes = () => async (dispatch, getState) => {
    const { authentification: { fullUserInfo } } = getState();
    const customerId = fullUserInfo.userId;
    dispatch({ 
        type: DASHBOARD_GET_KEYCODES,
        payload: Object.assign( {}, customerId, 
            { requestMethod:'GET', requestPath: `api/Keycodes?filter[where][customerId]=${customerId}` }
        )
    });
};