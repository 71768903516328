import React, { Component } from 'react';
import ToolTile from '../../common/ToolTile/ToolTile';
import PageLayout from '../../common/PageLayout/PageLayout';
import demoApp from '../../img/tools/Demo App.png';
import demoBarcodes from '../../img/tools/Demo Barcodes.png';
import productApp from '../../img/tools/Aila Product App.png';
import ailaDiagnostic from '../../img/tools/Aila Diagnostics.png';
import styles from './Tools.module.scss'
import cn from "classnames";

const tools = [
  {
    icon: demoApp,
    title: "Aila Scanner",
    link: "https://itunes.apple.com/WebObjects/MZStore.woa/wa/viewSoftware?id=6444497139",
    description: "Aila Demo is a barcode scanning application that is bundled with the AilaSDK. This is the compiled version of the app. The source code can be found with the framework bundle.",
  },
  {
    icon: demoBarcodes,
    title: "Aila SoftScan",
    link: "https://apps.apple.com/us/app/aila-softscan/id1446909395",
    description: "Aila’s SoftScan lets users take advantage of Aila’s powerful scanning SDK without the kiosk. Perfect for mPOS and other associate-facing applications. Download the app to try our performance today!",
  },
  {
    icon: ailaDiagnostic,
    title: "Aila Diagnostics",
    link: "https://itunes.apple.com/WebObjects/MZStore.woa/wa/viewSoftware?id=1670659720",
    description: "Aila Diagnostics is used to test the functionality of your Interactive Kiosk™. The tool can be used to independently test barcode scanning, lighting, audio, and manually upgrade the firmware on your kiosk. This app should be used when prescribed by an Aila Technologies Customer Support or Customer Success team member."
  }
];

class Tools extends Component {
  render() {
    return (
        <div className={'flex flex-1 flex-col px-12 mt-4'}>
          <h1 className={cn(styles.title, 'big-size-font')}>Aila Tools</h1>
          <div className={'grid grid-rows-3 md:grid-cols-3 flex-1'}>
            {tools.map(item => <ToolTile key={item.title} {...item} />)}
          </div>
        </div>
    );
  }
}

export default Tools;
