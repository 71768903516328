import React, { Component } from 'react';
import { connect } from 'react-redux';
import { dashboardGetAssignedKeycodes } from '../../store/AC/dashboard';
import { getCurrentPlans, cleanPaymentMethods } from '../../store/AC/billing';
import PageLayout from '../../common/PageLayout';
import PlanPanel from '../../blocks/PlanPanel';
import styles from './Billing.module.scss';
import BillingModal from '../../blocks/BillingModal';
import { Elements, StripeProvider } from 'react-stripe-elements';

const stripeKey = process.env.REACT_APP_STRIPE_KEY;

class Billing extends Component {
    constructor(props) {
        super(props);

        this.state = {
            open: false,
            plan: null,
            license: null
        }

        props.getCurrentPlans();
        props.getKeycodes();        
    }

    openModal = (plan) => {
        const { assignedKeycodes, user } = this.props;
        const keycode = assignedKeycodes.length > 0 && !user.partner ? assignedKeycodes[0] : null;        
        this.setState({ open: true, plan: plan, license: keycode });
    }

    closeModal = () => {
        this.setState({ open: false });
        this.props.cleanPaymentMethods();
    }

    render() {
        const { plan, open, license } = this.state;
        const { charge, charged, user, plans } = this.props;
        return (
            <StripeProvider apiKey={stripeKey}>
                <PageLayout title="Softscan Plans" rowClassName="justify-content-center">
                    {open ?
                        <Elements>
                            <BillingModal
                                charged={charged}
                                charge={charge}
                                plans = {plans}
                                plan={plan}
                                open={open}
                                onClose={this.closeModal}
                                user={user}
                                license={license}
                            />
                        </Elements>
                        : null}
                    {plans ? plans.map(plan => <PlanPanel action={this.openModal} key={plan.title} plan={plan} />) : null}
                    <div className={styles.inquires}>
                        For inquires, please contact <a href="mailto:sales@ailatech.com">sales@ailatech.com</a>
                    </div>
                </PageLayout>
            </StripeProvider>
        );
    }
}

const mapStateToProps = (state) => {
    const { fullUserInfo: { user } } = state.authentification;
    const { plans } = state.billing;
    const { assignedKeycodes } = state.dashboard;
    return {
        user,
        plans,
        assignedKeycodes
    };
};

const mapDispatchToProps = dispatch => ({
    // charge: (data) => dispatch(charge(data))
    getKeycodes: () => dispatch(dashboardGetAssignedKeycodes()),
    getCurrentPlans: () => dispatch(getCurrentPlans()),
    cleanPaymentMethods: () => dispatch(cleanPaymentMethods())    
});

export default connect(mapStateToProps, mapDispatchToProps)(Billing);