import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import styles from '../Documentation.module.scss';
import img1 from '../../../img/documentation/MobileImg_1.png'
import img2 from '../../../img/documentation/MobileImg_2.png'
import img3 from '../../../img/documentation/MobileImg_3.png'

class MobileImg extends Component {

    componentDidMount() {
        this.props.activeIndex('MobileImg');
    }

    render() {
        return (
            <div>
                <h1 className={styles.docTitle}>Getting Started with Your Mobile Imager</h1>
                <p>
                    Welcome to the set-up guide for Aila’s Mobile Imager. On this page, you’ll find resources and instructions 
                    to help you get started.
                </p>
                <ul className={styles.list}>
                    <li>Assembling the Mobile Imager</li>
                    <li>Setting up your iOS device</li>
                    <li>Downloading the Aila demo app</li>
                    <li>Calibrating the scanner</li>
                </ul>
                <h2>Assembling the Mobile Imager</h2>
                <h3>1st Step</h3>
                <figure>
                    <img src={img1}></img>
                    <figcaption>Remove the top cover from the Mobile Imager using the provided L-shaped hex key. </figcaption>
                </figure>
                <h3>2nd Step</h3>
                <figure>
                    <img src={img2}></img>
                    <figcaption>
                        Fully insert your device into the case, making sure to align the headphone connector 
                        to the headphone jack on the iPod
                    </figcaption>
                </figure>
                <h3>3rd Step</h3>
                <figure>
                    <img src={img3}></img>
                    <figcaption>Replace the end cover and lightly tighten the cap screw.</figcaption>
                </figure>
                <hr/>
                <h2>Set up your Apple Device</h2>
                <p>
                    To set up your Apple device for the first time, refer to the Apple Device Setup Guide. 
                    Be sure to fully charge your device before use. 
                </p>
                <h2>Download the Aila demo app</h2>
                <p>
                    To install Aila’s Demo app onto your device, click <Link to="/downloads">here</Link>.
                </p>
                <h3>(For SDK 1.7.0 and Prior Only)</h3>
                <h2>Pair the Mobile Imager with an Apple Device and Calibrate the Scanner </h2>
                <p>
                    To optimize your Mobile Imager’s TrueScan functions, we’ve designed a simple calibration exercise. 
                    This will pair your Mobile Imager with its Apple device, and improve scanning speed at a variety of scanning distances. To access the Mobile Imager calibration sheet and instructions, click here.
                </p>
                <h2>Questions?</h2> 
                <p>Contact us at <a href="malito:support@ailatech.com">support@ailatech.com</a></p>
            </div>
        );
    }

}

export default MobileImg;