export const REQUEST_RESET_PASSWORD = 'REQUEST_RESET_PASSWORD';
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const LOGIN = 'LOGIN';

export const GET_SELF = 'GET_SELF';
export const LOGOUT = 'LOGOUT'
export const CREATE_NEW_USER = 'CREATE_NEW_USER';
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
export const SET_TEMPORARY_TOKEN = 'SET_TEMPORARY_TOKEN';
export const UPDATE_USER = 'UPDATE_USER';
export const OPEN_LOGIN = 'OPEN_LOGIN';
