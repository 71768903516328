import axios from 'axios';

const api = axios.create({
    baseURL: process.env.REACT_APP_SERVER_URL,
});

api.interceptors.request.use(
    (config) => {
    const newConfig = config;

    const localToken = localStorage.getItem('id');
    if(localToken) {
        newConfig.headers.authorization = localToken;
        return newConfig;
    }
    return config;
},
    (error) => {        
        Promise.reject(error);
    },
);

api.interceptors.response.use((response) => {
    return response;
}, (error) => {
    return error;
});


api.CancelToken = axios.CancelToken;

export default api;