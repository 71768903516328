import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import styles from '../Documentation.module.scss';
import Snippet from '../../Snippet/Snippet.js'

class SDKPart3 extends Component {
    componentDidMount() {
        this.props.activeIndex('SDK');
    }

    render() {
        return (
            <div>
                <h1 className={styles.docTitle}>Part 3: Enhancing the Scanning Experience</h1>
                <p>
                    Throughout <Link to='/documentation/sdk-documentation2'>Part 2</Link> of this tutorial series
                    we learned how to gain access to the camera’s feed for users to see what they are aiming to.
                    We were also able to retrieve the barcode’s stored info and its type after a successful scan.
                    In the end we went through a few things that would enhance the user’s experience, like displaying
                    the scanning results in a nice overlaying view, and adding some feedback to the scanning
                    experience in the form of sound and vibration. Now it’s time to allow users to decide which barcode
                    types to recognize.
                </p>
                <p>
                    Remember this tutorial sits on top of the previous one, so if you’re not following them one
                    by one, you may want to download the Xcode project that results from
                    <Link to='/documentation/sdk-documentation2'> Part 2</Link>. The Xcode project won’t have the SDK embedded,
                    tutorial <Link to='/documentation/sdk-documentation'>Part 1</Link> explains how to add it once downloaded.
                </p>
                <h2>Settings view</h2>
                <p>
                    Go ahead and add a new viewcontroller to the storyboard of the project, and hook it up to our
                    current viewcontroller through a ‘show’ segue. Give the segue an identifier (could be <i>settingsSegue
                    for example</i>). Create a new swift file to host the viewcontroller (<i>SettingsViewController</i> maybe?)
                    and assign that class to the viewcontroller in the storyboard. Finally, drop a table on the storyboard,
                    create an outlet for it and don’t forget to add a button to close the modal.
                </p>
                <p>
                    We will need a cell to host the different settings so let’s go ahead and create that first. Let’s name its class
                    <i>
                        &nbsp;SettingsTableViewCell and create a xib for it. Add a label close to the left margin and an UISwitch to the right.
                    </i>
                </p>
                <p>
                    Now that we have our cell, let’s setup the tableview to use them. We’ll create all the UI elements first and then
                    the logic to put them to use.
                </p>
                <figure>
                    <Snippet>
                        {`
    /**
    Sets up the setting's table view.
    */
    private func setupTableView() {
        tableView.delegate = self
        tableView.dataSource = self
        tableView.tableFooterView = UIView()
        tableView.register(UINib(nibName: String(describing: SettingsTableViewCell.self), bundle: nil), 
            forCellReuseIdentifier: SettingsTableViewCell.cellIdentifier)
    }`
                        }
                    </Snippet>
                </figure>
                <p>
                    As you can see, we’re asking the cell’s class to provide an identifier for it. Let’s add that identifier
                    to <i>SettingsTableViewCell.</i>
                </p>
                <p>
                    <em>
                        static let cellIdentifier = “settingsTableViewCell”
                    </em>
                </p>
                <p>
                    Project won’t compile as we’re setting the table’s delegate and datasource but we are not providing at least a
                    basic implementation. Let’s add a dummy implementation at the end of <i>SettingsViewController</i>. The file should
                    look like this now:
                </p>
                <figure>
                    <Snippet>
                        {`
    import UIKit

    class SettingsViewController: UIViewController {

        @IBOutlet weak var settingsTableView: UITableView!
        @IBOutlet weak var closeButton: UIButton!

        override func viewDidLoad() {
            super.viewDidLoad()
            setupTableView()
        }

        /**
        Sets up the setting's table view.
        */
    
        private func setupTableView() {
            settingsTableView.delegate = self
            settingsTableView.dataSource = self
            settingsTableView.tableFooterView = UIView()
            settingsTableView.register(UINib(nibName: String(describing: SettingsTableViewCell.self), bundle: nil), forCellReuseIdentifier: SettingsTableViewCell.cellIdentifier)
        }

        @IBAction func closePressed(_ sender: UIButton) {
            dismiss(animated: true, completion: nil)
        }
    }

    extension SettingsViewController: UITableViewDelegate, UITableViewDataSource {
        func tableView(_ tableView: UITableView, numberOfRowsInSection section: Int) -> Int {
            return 0
        }
    
        func tableView(_ tableView: UITableView, cellForRowAt indexPath: IndexPath) -> UITableViewCell {
            guard let cell = tableView.dequeueReusableCell(withIdentifier: SettingsTableViewCell.cellIdentifier) as? SettingsTableViewCell else {
                return UITableViewCell()
            }
            return cell
        }
    }`
                        }
                    </Snippet>
                </figure>
                <p>
                    Once the project compiles, we are ready to add a way for users to open the settings view. Let’s simply
                    add a button on the top-right of the scanning view that calls the segue we created. Like we did with the
                    results view, we need to make sure the button’s z position is higher than the camera feed’s layer, so add the
                    following to the <i>viewDidLoad():</i>
                </p>
                <p>
                    <em>
                        settingsButton.layer.zPosition = 1
                    </em>
                </p>
                <h2>Barcode Filters</h2>
                <p>
                    So far we have an empty settings view. Let’s start populating it with the different options we are going to show our users.
                    We will start by creating an array with the names of the different sections our setting’s tableview will have.
                </p>
                <figure>
                    <Snippet>
                        {`
    /// Settings tableview's sections.
    private lazy var sectionNamesArray: [String] = {
        return [
            "Symbologies"
        ]
    }()`
                        }
                    </Snippet>
                </figure>
                <p>
                    Simbologies stands for the different barcode types, which we will add to a dictionary that will host the different entries
                    for the different sections of the tableview.
                </p>
                <figure>
                    <Snippet>
                        {`
    /// For each of the tableview's sections, their corresponding setting/row.
    private lazy var sectionsDictionary: [Int : [String]] = {
        return [
            0 : [
                "UPC/EAN",
                "QR",
                "PDF417 (ID/License)",
                "Code 128",
                "Code 39",
                "2 of 5",
                "GS1 DataBar",
                "DataMatrix"
                ]
        ]
    }()`
                        }
                    </Snippet>
                </figure>
                <p>
                    Next, let’s give a real implementation to <i>numberOfRowsInSection</i> and <i>cellForRowAtindexPath</i>:
                </p>
                <figure>
                    <Snippet>
                        {`
    extension SettingsViewController: UITableViewDelegate, UITableViewDataSource {
        
        func tableView(_ tableView: UITableView, numberOfRowsInSection section: Int) -> Int {
            guard let rowsForSection = sectionsDictionary[section] else {
                return 0
            }
            return rowsForSection.count
        }
    
        func tableView(_ tableView: UITableView, cellForRowAt indexPath: IndexPath) -> UITableViewCell {
            guard let cell = tableView.dequeueReusableCell(withIdentifier: SettingsTableViewCell.cellIdentifier) as? SettingsTableViewCell else {
                return UITableViewCell()
            }

            cell.selectionStyle = .none

            let sectionNumber = indexPath.section
            let settingName = sectionsDictionary[sectionNumber]?[indexPath.row] ?? ""

            cell.restart()
            cell.setupWith(settingName)

            return cell
        }

        func tableView(_ tableView: UITableView, titleForHeaderInSection section: Int) -> String? {
            return sectionNamesArray[section]
        }
    }`
                        }
                    </Snippet>
                </figure>
                <p>
                    Finally, we’re calling two methods we haven’t implemented yet, which setups each of the setting’s cells,
                    hiding/showing the different controls as needed. Go to <i> SettingsTableViewCell</i> and the following code:
                </p>
                <figure>
                    <Snippet>
                        {`
    /**
    Sets up the cell for the given setting.
    - Parameter settingName: Used by the cell to know how to adapt it's UI for the given setting.
    */
 
    func setupWith(_ settingName: String) {
        settingLabel.text = settingName
    }
 
    /**
    Restarts the cell to its basics, so that it can be freely reused.
    */
 
    func restart() {
        settingLabel.text = ""
        settingSwitch.isHidden = false
    }`
                        }
                    </Snippet>
                </figure>
                <p>
                    If you run the project now, though it’s still ugly and merely informative, you should see the section
                    for the barcode types and it’s list. We should now add the logic that would turn them on/off and keep
                    track of those decisions, informing the SDK of changes when needed.
                </p>
                <h2>SettingModel</h2>
                <p>
                    We need a way of exchanging information between our setting’s viewcontroller and the main viewcontroller
                    where scanning happens and where we communicate back and forth with the SDK. One possible way of doing so
                    would be to create and keep an instance of a class that models the different settings selected (and their
                    default value for the initial setup). We’re going to keep things simple to start off and build up from there:
                </p>
                <figure>

                </figure>
                <Snippet>
                    {`
    class SettingModel: NSObject {
        var barcodeTypes : [AilaCodeType : Bool]
        override init() {
            let barcodeTypes = [
                AilaCodeType.typeQR : true,
                AilaCodeType.type39 : true,
                AilaCodeType.type128 : true,
                AilaCodeType.typeUPC : true,
                AilaCodeType.typeEAN : true,
                AilaCodeType.typePDF417 : true,
                AilaCodeType.type2of5 : true,
                AilaCodeType.typeDataBar : true
            ]
            self.barcodeTypes = barcodeTypes
        }
    }`
                    }
                </Snippet>
                <p>
                    If you go back to ViewController and look for where we initialize the instance of <i>AilaConfiguration</i> that we
                    use to setup the SDK, you’ll see how to set its enabledCodes property. We want to change that so our model fills
                    that information. We override the default initializer of our model by telling it all the barcode types available
                    are accepted.
                </p>
                <p>
                    Let’s add a function that effectively returns that information the way the SDK is expecting it, and make that
                    change in our ViewController:
                </p>
                <figure>
                    <Snippet>
                        {`
    var settingsModel = SettingModel() {
           didSet {
               for codeAndType in settingsModel.barcodeTypes {
                   ailaConfiguration.setCode(codeAndType.0, enabled: codeAndType.1)
               }
               Aila_SetConfiguration(ailaConfiguration)
           }
       }
`
                        }
                    </Snippet>
                    <figcaption>
                        This how the SettingModel class looks like so far.
                    </figcaption>
                </figure>
                <p>
                    Let’s now go to our ViewController, add an instance of the model we just created and ask it to set our <i>enabledCodes</i>.
                </p>
                <figure>
                    <Snippet>
                        {`
    var settingsModel = SettingModel() {
           didSet {
               for codeAndType in settingsModel.barcodeTypes {
                   ailaConfiguration.setCode(codeAndType.0, enabled: codeAndType.1)
               }
               Aila_SetConfiguration(ailaConfiguration)
           }
       }`
                        }
                    </Snippet>
                    <figcaption>
                        By adding a didSet action to it, we are automatically updating the SDK when needed.
                    </figcaption>
                </figure>
                <h2>Wrapping up the feature</h2>
                <p>
                    There’s a lot of code involved on keeping our SettingModel’s instance updated with the changes the user introduces while
                    he/she turns on/off the different barcode types: we need to capture that action, communicate it back from the cell to the
                    setting’s viewcontroller and when dismissed, back to the main viewcontroller who will be in charge of updating the SDK.
                </p>
                <p>
                    Refer to the final version of the project to see how the classes end up looking like, here’s an overview of the things
                    we’re going to need:
                </p>
                <p>
                    Let’s start by adding to the SettingModel’s class a struct with the names of the different barcode types the SDK supports:
                </p>
                <figure>
                    <Snippet>
                        {`
    struct BarcodeTypeStrings {
        static let QR = "QR"
        static let PDF417 = "PDF417 (ID/License)"
        static let UPCEAN = "UPC/EAN"
        static let Code39 = "Code 39"
        static let Code128 = "Code 128"
        static let TwoOfFive = "2 of 5"
        static let GS1DataBar = "GS1 DataBar"
        static let DataMatrix = "DataMatrix"
    }`
                        }
                    </Snippet>
                </figure>
                <p>
                    Next, we need to override the <i>prepareForSegue()</i> method like this
                </p>
                <figure>
                    <Snippet>
                        {`
    override func prepare(for segue: UIStoryboardSegue, sender: Any?) {
        if let settingsViewController = segue.destination as? SettingsViewController {
            settingsViewController.settingsModel = settingsModel
            settingsViewController.delegate = self
        }
    }`
                        }
                    </Snippet>
                    <figcaption>
                        Before showing the settings view, we pass our settingsModel to it and set our main viewcontroller as the SettingsViewController’s delegate.
                    </figcaption>
                </figure>
                <p>
                    Add an extension with the delegate method we are soon to create on the SettingsViewController:
                </p>
                <figure>
                    <Snippet>
                        {`
    extension ViewController: SettingsViewControllerDelegate {
        func updateSdkWithSettingsModel(_ settingsModel: SettingModel?) {
            if let settingsModel = settingsModel {
                self.settingsModel = settingsModel
            }
        }
    }`
                        }
                    </Snippet>
                </figure>
                <p>
                    Now on the SettingsViewController, start by creating the protocol that defines <i>updateSdkWithSettingsModel()</i>
                </p>
                <figure>
                    <Snippet>
                        {`
    protocol SettingsViewControllerDelegate: NSObjectProtocol {
        func updateSdkWithSettingsModel(_ settingsModel: SettingModel?)
    }
                        `}
                    </Snippet>
                </figure>
                <p>
                    Also add the var to hold the delegate and the setting’s model:
                </p>
                <figure>
                    <Snippet>
                        {`
    var delegate: SettingsViewControllerDelegate? = nil
    var settingsModel: SettingModel? = nil`
                        }
                    </Snippet>
                </figure>
                <p>
                    Our table’s cells will show the name of the barcode type they represent. We need a way of telling the
                    barcode’s identifier with the given name we display.
                </p>
                <figure>
                    <Snippet>
                        {`
    private func barcodeTypeFromString(_ string: String) -> AilaCodeType {
        let barcodeDictionary = [
            BarcodeTypeStrings.QR : AilaCodeType.typeQR,
            BarcodeTypeStrings.Code39 : AilaCodeType.type39,
            BarcodeTypeStrings.Code128 : AilaCodeType.type128,
            BarcodeTypeStrings.UPC : AilaCodeType.typeUPC,
            BarcodeTypeStrings.EAN : AilaCodeType.typeEAN,
            BarcodeTypeStrings.PDF417 : AilaCodeType.typePDF417,
            BarcodeTypeStrings.TwoOfFive : AilaCodeType.type2of5,
            BarcodeTypeStrings.GS1DataBar : AilaCodeType.typeDataBar
        ]
        return barcodeDictionary[string]!
    }`
                        }
                    </Snippet>
                </figure>
                <p>
                    Since the viewcontroller will be the delegate of each cell, we need to create an extension with its method too:
                </p>
                <figure>
                    <Snippet>
                        {`
    extension SettingsViewController: SettingsTableViewCellDelegate {
       func settingValueChanged(_ barcodeTypeIdentifier: AilaCodeType) {
        if let isBarcodeOn = settingsModel?.barcodeTypes[barcodeTypeIdentifier] {
            settingsModel?.barcodeTypes[barcodeTypeIdentifier] = !isBarcodeOn
        }
    }
}`
                        }
                    </Snippet>
                </figure>
                <p>
                    Also important is to do <em>cell.delegate = self</em> inside <i>cellForRowAt(), to set the viewcontroller as the cell’s delegate.</i>
                </p>
                <p>
                    Finally, this is how the cell’s code will look like:
                </p>
                <figure>
                    <Snippet>
                        {`
    import UIKit

    protocol SettingsTableViewCellDelegate: NSObjectProtocol {
       func settingValueChanged(_ barcodeTypeIdentifier: AilaCodeType)
    }

    class SettingsTableViewCell: UITableViewCell {


       @IBOutlet weak var settingLabel: UILabel!
       @IBOutlet weak var settingSwitch: UISwitch!


       static let cellIdentifier = "SettingsTableViewCell"


       var delegate: SettingsTableViewCellDelegate? = nil
       var barcodeType: AilaCodeType? = nil


       override func awakeFromNib() {
           super.awakeFromNib()
           // Initialization code
       }


       /**
       Restarts the cell to it's basics, so that it can be freely reused.
       */
       
       func restart() {
           settingLabel.text = ""
           settingSwitch.isHidden = false
       }


       /**
       Sets up the cell for the given setting.
    
       - Parameter settingName: Used by the cell to know how to adapt its UI for the given setting.
       */


       func setupWith(_ settingName: String, codeType: AilaCodeType, isOn: Bool) {
           settingLabel.text = settingName
           barcodeType = codeType
           settingSwitch.setOn(isOn, animated: true)
       }
   
       @IBAction func settingValueChanged(_ sender: UISwitch) {
           if let specificBarcodeType = barcodeType  {
               delegate?.settingValueChanged(specificBarcodeType)
           }
           
       }
   }`
                        }
                    </Snippet>
                </figure>
                <p>
                    Now you should be able to run the project, go to the setting’s view and turn on/off each barcode type.
                    When you close that view, you should see how the SDK started/stopped recognizing the barcodes you just changed.
                </p>
                <h2>Conclusion</h2>
                <p>
                    We created our app and it’s setting’s view in a way that makes it easy to extend what it can achieve. For example,
                    we can add settings that control whether or not to reproduce a sound and vibration after a successful scan, we can
                    implement a duplicate’s filter or even a historic record of scans performed. If desired, we can even store the chosen
                    settings and the scan results to be available after the app is closed. From now on, it’s all about being creative.
                </p>
            </div>
        );
    }
}

export default SDKPart3;
