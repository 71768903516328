import React, { Component } from 'react';
import styles from './Terms.module.scss';

class ServiceTerms extends Component {
    render() {
        return (
            <div className={styles.terms}>
                <h2>SOFTWARE DEVELOPMENT KIT SUBSCRIPTION LICENSE AGREEMENT </h2>                
                <h2>AILA TECHNOLOGIES, INC.</h2>
                <p>
                    This Subscription License Agreement (this “Agreement”) is a legal agreement between 
                    Aila Technologies, Inc. (“Aila” or “us”) and you (“Licensee” or “you”). It sets forth the 
                    terms and conditions under which Licensee may access and use (a) the Aila Software 
                    Development Kits specified in Exhibit A to this Agreement, including any related software 
                    code, APIs, specifications, and documentation and (b) any updates, upgrades, revisions and 
                    other changes to the foregoing (unless subject to a different Aila license agreement, in 
                    which case such different agreement shall govern rather than this Agreement), in each 
                    of cases a and b that are provided or made available by Aila to you under this Agreement 
                    (hereinafter, collectively “SDKs”).  IF YOU SIGNIFY YOUR ACCEPTANCE HEREOF (INCLUDING BY 
                    CLICKING AN “AGREE” BUTTON), OR IF YOU DOWNLOAD, INSTALL, OR OTHERWISE USE THE SDKs, YOU 
                    AGREE TO BECOME BOUND BY THE TERMS AND CONDITIONS OF THIS AGREEMENT, AND THIS WILL BE A 
                    LEGALLY BINDING AGREEMENT BETWEEN YOU AND AILA.  PLEASE READ THIS AGREEMENT CAREFULLY BEFORE 
                    ACCEPTING IT OR DOWNLOADING, INSTALLING OR USING THE SDKs. IF YOU DO NOT AGREE WITH THE 
                    TERMS AND CONDITIONS OF THIS AGREEMENT, YOU MAY NOT DOWNLOAD, INSTALL OR USE THE SDKs.  
                    If you are downloading, installing or using the SDKs on behalf of an organization, you 
                    represent that you have the right to bind such organization to this Agreement, and the 
                    terms “Licensee” and “you” herein will include both you, the individual user, and such 
                    organization.  Each of Aila and Licensee may also be referred to herein as a “party” or 
                    together as the “parties.”
                </p>
                <p>
                    To the extent you are agreeing to these terms and conditions as part of registering for 
                    a free trial for the SDK offered by Aila, then you will also be considered a “Trial Licensee” 
                    herein (as well as a “Licensee”).
                </p>
                <ol>
                    <li>
                        <h3>LICENSE TO USE SDKs.</h3>
                        <p>
                            Subject to the terms and conditions herein and Licensee’s compliance with this Agreement, 
                            Aila grants Licensee a non-exclusive, non-transferable, non-sublicensable copyright license 
                            to download and use the SDKs solely for the purpose of (a) internally developing and testing 
                            programmatic or command line calls to runtime libraries and system utilities in Licensee’s 
                            software application (hereinafter “Licensee’s Product”, which for clarity may include software 
                            owned by Licensee and/or in-licensed from a third party by Licensee), and solely as necessary 
                            for permitting Licensee’s Product to implement bar code scanning functionality provided by the 
                            SDKs, and (b) internally using Licensee’s Product solely for Licensee’s own internal business 
                            purposes, in each of cases a and b during the term of this Agreement.  Under the above license, 
                            Licensee is only granted the right to use the standard documented APIs of the SDKs, which may 
                            consist of (1) routines, protocols, related libraries and instructions; (2) any code provided 
                            with the SDKs as header files and samples or examples of code for calls which correctly invoke 
                            the runtime libraries and system utilities (“Sample Code”);  and (3) any other code provided with 
                            the SDKs. The above license is subject to the following additional restrictions:
                        </p>
                        <ol>
                            <li>
                                <p>
                                    Licensee may reproduce the SDKs solely as reasonably necessary to exercise its rights under 
                                    the above license;
                                </p>                                
                            </li>
                            <li>
                                <p>
                                    Licensee may modify the Sample Code only as necessary to implement calls which invoke the 
                                    runtime libraries and system utilities in Licensee’s Product, and may not modify any other 
                                    code in the SDKs;
                                </p>                                
                            </li>
                            <li>
                                <p>
                                    Licensee may not use any part of SDKs in Licensee’s Product if Licensee’s software does 
                                    not provide the primary and majority of functionality of Licensee’ Product;
                                </p>                                
                            </li>
                            <li>
                                <p>
                                    Licensee may not use the SDKs to develop, implement, execute, offer, or advertise 
                                    functionality that (i) migrates customers or customer data off of any Aila or Aila affiliate 
                                    product or service, or (ii) enables any third party to migrate customers or customer data 
                                    off of any Aila or Aila affiliate product or service; and
                                </p>                                
                            </li>
                            <li>
                                <p>
                                    Licensee shall not use the SDKs to develop any product or service other than Licensee’s 
                                    Product
                                </p>                                
                            </li>                            
                        </ol>
                        <p>
                            For clarity and notwithstanding anything else herein, Aila does not grant to Licensee, and Licensee does 
                            not have, under this Agreement any right to resell or otherwise distribute the SDKs, in whole or part, to 
                            any third party (regardless of whether on a standalone basis, as part of Licensee’s Product, or otherwise). 
                            Any such resale or other distribution of the SDKs in any manner requires a separate written reseller and/or 
                            distribution agreement, which separate written agreement Aila may grant, condition or deny to Licensee in Aila’s 
                            sole discretion
                        </p>                    
                    </li>
                    <li>
                        <h3>ADDITIONAL LICENSEE RESTRICTIONS.</h3>
                        <ol>
                            <li>                                
                                <p>
                                    General. In further consideration for the license to use the SDKs granted in Section 1 above,
                                    Licensee agrees that Licensee shall not: (i) distribute, resell, rent, loan, disclose,
                                    make available (including in a service bureau), otherwise transfer, license, or sublicense
                                    the SDKs, or any functional equivalents, to third parties for any reason or in any manner;
                                    (ii) distribute, resell, rent, loan, disclose, make available (including in a service bureau),
                                    otherwise transfer, license, or sublicense any product incorporating any code in the SDKs,
                                    or any functional equivalents thereof; (iii) create derivative works of, modify (except
                                    as expressly permitted in Section 1.b above), alter, debug, adapt, translate, reverse engineer,
                                    decompile or disassemble, or permit use by or for any third party of, the SDKs; (iv)
                                    perform services using the SDKs, or any functional equivalents, as a system integrator or
                                    other service provider, including without limitation, any outsourced engineering, product
                                    development or consulting engagement with any third party; (v) use the SDKs to develop or
                                    market any software, service, product or other offering that is functionally similar to,
                                    derivative of, or competes with any software, service, product or other offering of Aila or any
                                    functionality thereof; (vi) remove any proprietary notices on or in the SDKs; or (vii) use the
                                    SDKs in an illegal or fraudulent manner, or in any other manner not expressly permitted by this
                                    Agreement.  All acts and omissions of Licensee’s employees and other personnel will be deemed
                                    to be those of Licensee, and Licensee shall be responsible therefor.  Except for the license
                                    expressly granted in Section 1 above, Aila or its licensors or other providers retain all right,
                                    title and interest in and to the SDKs, and Licensee will have no other right, title or interest
                                    therein or related thereto, whether express or implied.  During the term of this Agreement,
                                    Licensee agrees not to assert any claim against Aila or its affiliates alleging that the SDKs
                                    or Aila products infringe Licensee or Licensee’s affiliates’ intellectual property rights.
                                    </p>
                            </li>
                            <li>                                
                                <p>
                                    Additional Trial License Terms. For Trial Licensees, in addition to the other applicable terms and conditions set forth herein:
                                    (i) Licensee’s use of the SDKs shall be further limited to internal, non-production trial use on
                                    no more than 20 devices, solely to evaluate and consider whether it is interested in a non-trial
                                    version; (ii) Aila may terminate this Agreement, including for clarity the license granted to Licensee,
                                    at any time and for any or no reason, and this Agreement will automatically terminate upon Licensee’s
                                    failure to comply with its license or confidentiality obligations herein; (iii) Licensee acknowledges
                                    that the trial version of the SDKs may differ from the non-trial version in several respects; (iv)
                                    Licensee may register for only one trial related to the SDKs (and may not register for a trial
                                    of any subsequent versions thereof); (v) Licensee’s trial shall also be subject to any additional
                                    trial-related terms and conditions present on the Aila website where it initially registers for
                                    such trial (which shall be hereby incorporated herein); and (vi) Aila shall be under no obligation
                                    to provide Licensee any support or maintenance of any kind, including as described in Section 7
                                    below (provided that, if it does provide any such support or maintenance, it may differ from that
                                    provided to non-trial Licensees and may be suspended, modified or terminated at any time in Aila’s
                                    sole discretion).
                                </p>
                            </li>                                                                                                                                        
                        </ol>                        
                    </li>
                    <li>
                        <h3>FEEDBACK.</h3>
                        <p>
                            Licensee acknowledges and agrees that any feedback or suggestions Licensee provides to Aila
                            (“Feedback”) regarding the SDKs or any Aila product will be the sole and exclusive property of Aila.
                            Licensee hereby assigns to Aila and agrees to assign to Aila all of Licensee’s right, title, and
                            interest in and to all such Feedback, including all intellectual property rights therein.  At
                            Aila’s request and expense, Licensee will execute documents and take such further acts as Aila may
                            reasonably request to assist Aila to acquire, perfect and maintain its intellectual property rights
                            and other legal protections for the Feedback.  If any such rights in Feedback are not assignable to
                            Aila for any reason, Licensee hereby grants to Aila and agrees to grant to Aila a non-exclusive,
                            worldwide, royalty-free, fully paid, perpetual and irrevocable license, with the right to sublicense
                            through multiple tiers, under Licensee’s intellectual property rights, to implement the Feedback in
                            Aila products and services and to otherwise exploit in any manner the Feedback.
                        </p>
                    </li>
                    <li>
                        <h3>CONFIDENTIALITY.</h3>
                        <p>
                            As used herein, “Confidential Information” means: (a) any Feedback that Licensee provides to Aila,
                            and (b) any Aila business or technical information that is disclosed to Licensee in connection with this
                            Agreement, including, but not limited to, any information relating to Aila’s plans, business opportunities
                            or research and development.  Confidential Information excludes any information that: (i) is or becomes
                            generally known to the public other than as a result of Licensee’s breach of this Agreement; (ii) is
                            rightfully known to Licensee at the time of disclosure without restrictions on use or disclosure; (iii) is
                            independently developed by Licensee, without access to or use of any Confidential Information; or (iv) is
                            rightfully obtained by Licensee from a third party, who has the right to disclose it and who discloses it
                            without restrictions on use or disclosure.  During and after the term of this Agreement: (A) Licensee will
                            maintain all Confidential Information in strict confidence and will not disclose Confidential Information
                            to any third party, and (B) Licensee will not use Confidential Information, except as reasonably necessary
                            to use the SDKs in accordance with the license granted in this Agreement during the term of this Agreement.
                        </p>
                    </li>
                    <li>
                        <h3>TERM AND TERMINATION.</h3>
                        <ol>
                            <li>
                                <p>
                                    Term. For Licensees other than Trial Licensees, the term of this Agreement will commence on the date
                                    of Licensee’s first acceptance of these terms and conditions, or download, installation or use of the
                                    SDKs, and, unless earlier terminated as set forth below, will continue for a period of one (1) year
                                    thereafter (the “Initial Term”); provided that, the term will automatically extend thereafter for
                                    subsequent periods of one (1) year each (each, a “Renewal Term”) unless either party has provided the
                                    other party with written notice of its desire not to extend at least thirty (30) days before the end of
                                    the then-current term.  For Trial Licensees, the term of this Agreement will instead commence on the date
                                    of Licensee’s first acceptance of these terms and conditions, or download, installation or use of the SDKs,
                                    as part of a trial for which it has registered with Aila, and, unless earlier terminated as set forth herein,
                                    will continue for a period of thirty (30) days thereafter (the “Trial Term”); provided that, if Licensee orders
                                    [and pays] for a non-trial subscription for the SDKs from Aila during its Trial Term, then it will be deemed
                                    to no longer be a Trial Licensee hereunder (but still a Licensee) for which the term of this Agreement will
                                    then continue for an additional one (1) year after such non-trial order (together with the “Trial Term,” the
                                    “Initial Term”), and the term will automatically extend thereafter for subsequent periods of one (1) year each
                                    (each, a “Renewal Term”) unless either party has provided the other party with written notice of its desire not
                                    to extend at least thirty (30) days before the end of the then-current Initial Term or Renewal Term
                                </p>
                            </li>
                            <li>
                                <p>
                                    Termination. Either party may terminate this Agreement by written notice to the other party if the other party
                                    has materially breached this Agreement and not cured such breach within thirty (30) days after written notice
                                    thereof (provided that, such notice period shall be shortened to five (5) days for Licensee’s breach of any
                                    license or confidentiality obligations or restrictions herein).
                                </p>
                            </li>
                            <li>
                                <p>
                                    Effect of Termination.  Upon any termination or expiration of this Agreement for any reason: (a) Licensee shall
                                    immediately cease all use of the SDKs and Confidential Information, and return to Aila or destroy the foregoing;
                                    and (b) any obligations that have previously accrued (including Licensee’s obligation to pay any Fees due hereunder),
                                    together with any provisions herein that should reasonably survive (including those listed in Section 9.e below), shall
                                    survive.
                                </p>
                            </li>
                        </ol>
                    </li>
                    <li>
                        <h3>REGISTRATION, PAYMENT, FEES, AND SUSPENSION.</h3>
                        <ol>
                            <li>
                                <p>
                                    Registration.  In order to download the SDKs, you may be required to provide us with your name, title, organization and
                                    contact information, and register with Aila.  To the extent you acting on behalf of an organization, you may need to also
                                    provide us with information in order to confirm, or permit us to confirm, any relationship between you and such organization.
                                    We may also request additional information from you.  You represent and warrant to us that you will provide us with accurate,
                                    current and complete registration information.  You are responsible for your registration, and for all use of the SDKs via any
                                    credentials or passwords issued to you or chosen by you.  You will keep all such credentials and passwords confidential.
                                </p>
                            </li>
                            <li>
                                <p>
                                    Fees and Payment. In consideration of the license granted hereunder, Licensee shall pay Aila an annual subscription fee,
                                    due at the start of the Initial Term and each Renewal Term.  Such subscription fee for the Initial Term shall be as set forth on
                                    the Aila website at the time of Licensee’s order.  Aila may increase such subscription fee for any Renewal Term by providing Licensee
                                    at least forty-five (45) days notice prior to the start of such Renewal Term. The Licensee will provide Aila (or its designated
                                    third-party payment provider) with accurate and valid credit card or other payment information and update its credit card or other
                                    payment information in the event any information provided becomes invalid or incomplete.  If any charge is rejected by Aila’s bank or
                                    payment providers, Licensee is still liable to pay the fees.  Aila retains the right to charge interest on any overdue balance at the
                                    rate of 1.5% per month, or the maximum amount permitted by law (if lower).  Licensee will also be responsible for Aila’s reasonable
                                    costs of collection, including attorney’s fees, if Aila deems it necessary to take any legal or administrative action to collect unpaid
                                    fees.  Aila reserves the right to accept, refuse or cancel any orders placed through its website for the SDKs, without liability or
                                    justification. Aila will refund you in case your order was cancelled by Aila after your credit card or other payment method has been
                                    charged.  All payments to Aila are non-refundable unless otherwise agreed upon in writing by Aila and Licensee. All amounts due hereunder
                                    are exclusive of all sales, use, excise, value added, or other taxes, duties and charges of any kind (whether foreign, federal, state,
                                    local or other) associated with this Agreement, the SDKs, or Licensee’s use thereof.  Licensee shall be solely responsible for all such
                                    taxes, duties and charges (except for taxes imposed on Aila’s income), which may be charged by Aila to Licensee’s credit card or other
                                    payment method used for subscription fees hereunder, or invoiced by Aila, from time-to-time. Licensee shall make all payments hereunder
                                    in US Dollars, and without deduction of any charges, taxes or other amounts.
                                </p>
                            </li>
                            <li>
                                <p>
                                    Suspension.  In the event Licensee fails to timely make any payment due hereunder, without limiting its other rights or remedies associated
                                    therewith, Aila reserves the right to suspend the account if payment is not made when due.
                                </p>
                            </li>
                            <li>
                                <p>
                                    Use Limits, Recordkeeping and Audit.  Licensee acknowledges that its subscription to use the SDKs may be limited in volume, including as to
                                    number, model, and generation of devices.  To the extent Licensee exceeds its permitted usage or expects to do so, it shall promptly notify
                                    Aila and agrees to pay Aila additional subscription fees covering such excess use and reasonably anticipated use for the remainder of the
                                    then-current term (and if requested by Aila, shall enter into an order covering such excess use).  Upon Aila’s request from time to time,
                                    Licensee shall confirm in writing its usage volume by device model and generation.  During the term of this Agreement and for two (2) years
                                    thereafter, (i) Licensee shall maintain appropriate records of its activities hereunder (including the volume and other scope of its use of the SDKs)
                                    in order to reasonably demonstrate and evidence its compliance with this Agreement, including use limits, and (b) Aila may in its discretion from
                                    time-to-time, review such records using its internal personnel or external advisors, in order to confirm Licensee’s compliance with this Agreement
                                    (and in the event of non-compliance, without limiting Aila’s other rights or remedies therefor, Licensee shall promptly reimburse Aila for its costs
                                    and expenses associated with such review).
                                </p>
                            </li>
                        </ol>
                    </li>
                    <li>
                        <h3>TECHNICAL SUPPORT.</h3>
                        <p>
                            During the term of this Agreement, (a) Aila will make available technical documentation to adequately familiarize Licensee with the
                            SDKs to aid in application integration and development as expressly permitted herein, and (b) general support can be reached by
                            contacting support@ailatech.com for clarifying questions, integration questions, or general product inquiries, in each of cases a
                            and b in accordance with Aila’s then-current standard base support offering. Additional support or service plans may be quoted by
                            Aila to Licensee upon request.
                        </p>
                    </li>
                    <li>
                        <h3>WARRANTY, DISCLAIMER, INDEMNITY, AND LIMITATION ON LIABILITY</h3>
                        <ol>
                            <li>
                                <p>
                                    Warranty Related to Forking.  Licensee warrants that Licensee has not and will not engage in forking 
                                    or otherwise attempt to modify or reverse engineer the SDKs.  Further, Licensee warrants that Licensee 
                                    has not and will not distribute a set of APIs or a development kit that is based upon Licensee’s access 
                                    to the SDKs or is otherwise a modification of the SDKs.
                                </p>                                
                            </li>
                            <li>
                                <p>
                                    Disclaimer of Warranty.  The SDKs are provided “AS IS”, without warranty of any kind.  Aila DISCLAIMS 
                                    ALL WARRANTIES, EXPRESS OR IMPLIED, INCLUDING ANY WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR 
                                    PURPOSE AND NON-INFRINGEMENT.  Aila makes no representations or warranties regarding the suitability of 
                                    the SDKs for Licensee’s intended requirements or purposes, including for use with Licensee’s Product.  
                                    Further, Aila makes no representations or warranties regarding the integrity of data that Licensee transfers, 
                                    stores, obtains or receives through use of the SDKs.  Aila is not obligated to maintain or support the SDKs, 
                                    or to provide updates, fixes, or services related thereto.  Licensee assumes all risk arising from Licensee’s 
                                    use of the SDKs, including, without limitation, the risk of damage to computers, Licensee’s Product, or the 
                                    corruption or loss of data.
                                </p>                                
                            </li>
                            <li>
                                <p>
                                    Indemnity.  Licensee agrees to indemnify, and hold Aila, its affiliates, and their respective officers, directors, 
                                    employees, agents, licensors and other providers harmless from and against any claims, liabilities, damages, losses, 
                                    and expenses, including, without limitation, reasonable attorney’s fees, arising out of or related to Licensee’s access 
                                    to or use of the SDKs or this Agreement (except to the extent caused by the gross negligence or willful misconduct of 
                                    Aila).  Licensee will cooperate with reasonable requests of Aila related to any third party claims covered by the above 
                                    indemnity.
                                </p>                                
                            </li>
                            <li>
                                <p>
                                    Limitation of Liability.  IN NO EVENT WILL AILA OR ITS LICENSORS OR OTHER PROVIDERS BE LIABLE TO LICENSEE FOR ANY SPECIAL, 
                                    INCIDENTAL, EXEMPLARY, PUNITIVE, INDIRECT OR CONSEQUENTIAL DAMAGES (INCLUDING LOSS OF USE, DATA, BUSINESS OR PROFITS) OR 
                                    FOR THE COST OF PROCURING SUBSTITUTE SOFTWARE ARISING OUT OF OR RELATED TO THIS AGREEMENT OR THE USE OF THE SDKS, WHETHER 
                                    SUCH DAMAGES ARISE FROM ANY CLAIM BASED UPON CONTRACT, WARRANTY, TORT (INCLUDING NEGLIGENCE), STRICT LIABILITY OR OTHERWISE, 
                                    AND WHETHER OR NOT AILA HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.  IN ANY CASE, AILA’S AGGREGATE LIABILITY TO 
                                    LICENSEE ARISING OUT OF OR RELATED TO THE SDKS OR THIS AGREEMENT WILL NOT EXCEED THE TOTAL FEES PAID BY LICENSEE TO AILA 
                                    HEREUNDER IN THE PRIOR SIX MONTHS (OR FOR TRIAL LICENSEES, $10).  THE FOREGOING LIMITATIONS WILL SURVIVE AND APPLY EVEN IF 
                                    ANY LIMITED REMEDY SPECIFIED IN THIS AGREEMENT IS FOUND TO HAVE FAILED OF ITS ESSENTIAL PURPOSE.  SOME JURISDICTIONS DO NOT 
                                    ALLOW THE LIMITATION OR EXCLUSION OF LIABILITY FOR INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO THE ABOVE LIMITATION OR EXCLUSION 
                                    MAY NOT APPLY TO LICENSEE.
                                </p>
                            </li>
                        </ol>
                    </li>
                    <li>
                        <h3>MISCELLANEOUS</h3>
                        <ol>
                            <li>
                                <p>
                                    Injunctive Relief. Licensee acknowledges that its violation of this Agreement may cause irreparable 
                                    harm to Aila not adequately compensable by monetary damages.  In addition to other relief, it is 
                                    agreed that temporary and permanent injunctive relief may be an appropriate remedy to prevent any 
                                    actual or threatened violation of this Agreement or to enforce this Agreement, and Aila may seek such 
                                    an injunction or other equitable relief in any court of competent jurisdiction, without any requirement 
                                    to post bond.  
                                </p>
                            </li>
                            <li>
                                <p>
                                    Support.  Unless otherwise agreed to in writing, Aila shall not be responsible for providing any service 
                                    (or level of service), support or maintenance of any kind to Licensee for the SDKs.  
                                </p>
                            </li>
                            <li>
                                <p>
                                    Attributions. Licensee shall maintain any attributions (e.g., “© [2019] Aila Technologies, Inc.”) denoting 
                                    Aila or others as the author of the SDKs.  Failure to maintain such attributions shall be a material breach 
                                    of this Agreement.
                                </p>
                            </li>
                            <li>
                                <p>
                                    Export Laws.  Licensee agrees to comply fully with all U.S. export laws and regulations to ensure that neither 
                                    the Aila SDKs nor any technical data related thereto nor any direct product thereof are exported or re-exported 
                                    directly or indirectly in violation of, or used for any purposes prohibited by, such laws and regulations.
                                </p>
                            </li>
                            <li>
                                <p>
                                    Survival Upon Termination. Sections 2-6, and 8-9 hereof will survive expiration or termination of this Agreement.
                                </p>
                            </li>
                            <li>
                                <p>
                                    Government Users.  The SDKs and related documentation are “commercial items,” as that term is defined at FAR 2.101, 
                                    consisting of “commercial computer software” and “commercial computer software documentation,” as such terms are used 
                                    in FAR 12.212 and DFARS 227.7202.  Consistent with FAR 12.212 or DFARS 227.7202, as applicable, the SDKs and documentation 
                                    are made available to U.S. Government end users only as a commercial item, with only those rights as are granted to all 
                                    other end users pursuant to the terms and conditions of this Agreement.
                                </p>
                            </li>
                            <li>
                                <p>
                                    General.  This Agreement will be governed by and construed in accordance with the laws of the Commonwealth of Massachusetts, 
                                    without regard to or application of conflicts of law rules or principles that would apply another law.  The United Nations 
                                    Convention on Contracts for the International Sale of Goods will not apply.  Licensee may not assign or transfer this 
                                    Agreement or any rights granted hereunder, or delegate any obligations hereunder, by operation of law or otherwise, without 
                                    Aila’s prior written consent in its sole discretion, and any attempt by Licensee to do so, without such consent, will be void 
                                    and of no effect.  Aila may assign or delegate this Agreement in whole or part from time-to-time in its sole discretion.  
                                    Except as expressly set forth in this Agreement, the exercise by either party of any of its remedies under this Agreement will 
                                    be without prejudice to its other remedies under this Agreement or otherwise.  All notices or approvals required or permitted 
                                    under this Agreement will be in writing and for notices to Licensee, deemed received when sent to its email address provided 
                                    to Aila at registration. The failure by either party to enforce any provision of this Agreement will not constitute a waiver of 
                                    future enforcement of that or any other provision.  Any waiver, modification or amendment of any provision of this Agreement will 
                                    be effective only if in writing and signed by authorized representatives of both parties.  The sections titles or headings herein 
                                    are for convenience only and will not be used to interpret the substance of this Agreement.
                                </p>
                            </li>
                            <li>
                                <p>
                                    The Parties. The parties to this Agreement are Licensee and Aila.  Other than Aila’s licensors or other providers where referenced, 
                                    there are no third party beneficiaries to this Agreement.
                                </p>
                            </li>
                            <li>
                                <p>
                                    Integration; Order of Precedence.  This Agreement, together with any exhibits (if applicable), constitutes the entire agreement, 
                                    and supersedes any and all prior agreements, between the parties with regard to the subject matter hereof.  The terms of this 
                                    Agreement shall supersede and control over any conflicting or additional terms and conditions of any order, acknowledgement or 
                                    confirmation or other document issued by Licensee.
                                </p>
                            </li>
                            <li>
                                <p>
                                    Severability.  In the event that any provision of this Agreement, or the application thereof, becomes or is declared by a court 
                                    of competent jurisdiction to be illegal, void or unenforceable, the remainder of this Agreement will continue in full force and 
                                    effect and the application of such provision will be interpreted so as reasonably to effect the intent of the parties. The parties 
                                    will promptly replace such void or unenforceable provision with a valid and enforceable provision that will achieve, to the extent 
                                    possible, the economic, business and other purposes of such void or unenforceable provision.
                                </p>
                            </li>
                            <li>
                                <p>
                                    Aila’s Right of Modification. At Aila’s discretion and upon thirty (30) days written notice to Licensee, Aila may modify any 
                                    non-material terms of this Agreement.  Licensee’s continued use of the SDKs serves as consideration for, and Licensee’s agreement 
                                    to, any Aila modification.  Otherwise, this Agreement may not be amended unless subject to a written amendment signed by Aila.
                                </p>
                            </li>
                            <li>
                                <p>
                                    Ownership. The SDKs and accompanying materials are licensed, not sold, to Licensee. Aila does not grant any implied licenses 
                                    under this Agreement or otherwise, and Aila reserves all rights not expressly granted to Licensee under this Agreement. 
                                    Aila retains all right, title and interest in and to the SDK, including any derivative works based upon the SDKs, and all 
                                    materials, concepts, inventions, works of authorship, ideas and information related to the SDKs.
                                </p>
                            </li>
                            <li>
                                <p>
                                    Force Majeure. Aila shall not be liable or responsible to Licensee, nor be considered to have defaulted or breached 
                                    this Agreement, for any failure or delay in fulfilling or performing any provision of this Agreement to the extent such 
                                    failure or delay is caused by or results from any act, circumstance or other cause beyond the reasonable control of Aila, 
                                    including acts of God, flood, fire, earthquake, explosion, governmental actions, war, invasion or hostilities (whether war 
                                    is declared or not), terrorist threats or acts, riot, or other civil unrest, national emergency, revolution, insurrection, 
                                    epidemic, lockouts, strikes or other labor disputes (whether or not relating to either party's workforce), or restraints or 
                                    delays affecting carriers or inability or delay in obtaining supplies of adequate or suitable technology or components, 
                                    telecommunication breakdown, or power outage
                                </p>
                            </li>
                            <li>
                                <p>
                                    Publicity. Aila shall have the right to use Licensee’s name and logo on client lists published on Aila’s website and in 
                                    marketing materials.  Aila may announce the relationship hereunder in a press release provided that Aila obtains Licensee’s 
                                    prior approval of the wording of the release (not unreasonably withheld).
                                </p>
                            </li>
                        </ol>
                    </li>
                </ol>
            </div>
        );
    };
}

export default ServiceTerms;