import React from 'react';
import styles from './PageLayout.module.scss';
import cn from "classnames";

const PageLayout = ({title, children, className, rowClassName}) => {
    return (
        <div className={cn(styles.container, 'container-fluid', className)}>            
            <h1 className={cn(styles.title, 'big-size-font')}>{title}</h1>
            <div className={cn("row", rowClassName)}>
                {children}
            </div>
        </div>
    );
}

export default PageLayout;