import {
    GET_ALL_USERS,
    EDIT_USER_BY_ID,
    SAVE_EDIT_USER,
    SAVE_USER,
    DELETE_USER,
    DELETE_USER_ID,
    GET_USER_BY_EMAIL,
    INACTIVE_USER,
    DELETE_USER_SELF,
    SET_AGREEMENT_DATE
    // RESET_USER_PASSWORD,
} from '../constants/usersManage';

export const editUserById = (id) => ({ type: EDIT_USER_BY_ID, payload: id });
export const setDeleteUserId = (id) => ({ type: DELETE_USER_ID, payload: id });

export const getUserByEmail = email => ({ 
    type: GET_USER_BY_EMAIL,
    payload: Object.assign( {}, { requestMethod:'GET', requestPath: `api/Customers?filter[where][email]=${email}` })
}); 

export const getAllUsers = data => ({ 
    type: GET_ALL_USERS,
    payload: Object.assign( {}, data, { requestMethod:'GET', requestPath: 'api/Customers?filter[include]=roles&filter[where][neq]=false' })
});

export const addNewUser = data => ({ 
    type: SAVE_USER,
    payload: Object.assign( {}, data, { requestMethod:'POST', requestPath: 'api/Customers' })
});

export const saveEditUser = (id, data) => ({ 
    type: SAVE_EDIT_USER,
    payload: Object.assign( {}, data, { requestMethod:'PUT', requestPath: `api/Customers/${id}` })
});

export const deleteUserSelf = () => ({ 
    type: DELETE_USER_SELF,
    payload: Object.assign({}, { requestMethod:'DELETE', requestPath: `api/Customers/delete-self` })
});

export const deleteUserById = id => ({ 
    type: DELETE_USER,
    payload: Object.assign({}, { requestMethod:'DELETE', requestPath: `api/Customers/${id}` })
});

export const makeInactiveUserById = id => ({ 
    type: INACTIVE_USER,
    payload: Object.assign({}, {
        active: false,
    } , { requestMethod:'PUT', requestPath: `api/Customers/${id}` })
});

export const setAgreementDate = () => ({ 
    type: SET_AGREEMENT_DATE,
    payload: Object.assign({}, { requestMethod:'POST', requestPath: `api/Customers/agreement-date` })
});
