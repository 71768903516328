import React from 'react';
import styles from './TileLayout.module.scss';

const TileLayout = ({children}) => {
    return (
        <div className={'col-xl-4 col-lg-6 col-md-6 col-sm-12 col-xs-12'}>
            <div className={styles.activationBlock}>
                {children}
            </div>
        </div>
    );
}

export default TileLayout;