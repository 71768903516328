export const GET_ALL_USERS = 'GET_ALL_USERS';
export const EDIT_USER_BY_ID = 'EDIT_USER_BY_ID';
export const SAVE_EDIT_USER = 'SAVE_EDIT_USER';
export const DELETE_USER = 'DELETE_USER';
export const RESET_USER_PASSWORD = 'RESET_USER_PASSWORD';
export const SAVE_USER = 'SAVE_USER';
export const DELETE_USER_ID = 'DELETE_USER_ID';
export const GET_USER_BY_EMAIL = 'GET_USER_BY_EMAIL';
export const INACTIVE_USER = 'INACTIVE_USER';
export const DELETE_USER_SELF = 'DELETE_USER_SELF';
export const SET_AGREEMENT_DATE = 'SET_AGREEMENT_DATE';