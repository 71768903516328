import React, { Component } from 'react';
import { connect, } from 'react-redux';
import { Loader } from 'semantic-ui-react'
import Pagination from '../../blocks/Pagination/Pagination';
import styles from './ManageDevices.module.scss';
import DeviceItem from '../../blocks/DeviceItem/DeviceItem';
import { getDevices, setReleaseDeviceId } from '../../store/AC/devicesManage' ;
import { showModalWindow, releaseDeviceInit } from '../../store/AC/manageModalWindow';
import { dashboardGetAssignedKeycodes } from '../../store/AC/dashboard';

class ManageDevices extends Component {
    constructor(props) {
        super(props);
        this.state = {
          page: 1, 
          limit: 10,
          keycodeId: this.props.keycodeId,
          releaseCompleted: false,
          loading: true
        }

        this.props.getDevices({ 
          limit: this.state.limit,
          page: this.state.page,
          keycodeId: this.state.keycodeId
        }, () => {
          this.setState({loading: false});
        });
    }
    
    componentDidUpdate(prevProps, prevState) {
      if (this.props.releaseCompleted === true) {
        this.refresh();
      }
      if (this.props.getDevicesCompleted !== prevProps.getDevicesCompleted) {
        this.setState({ getDevicesCompleted: this.props.getDevicesCompleted });
      }
    }
        
    getDevices = () => {
        return this.props.devices.map((device, i) => 
            <DeviceItem 
                deviceData={device} 
                slot={(i + (this.state.limit * (this.state.page - 1)))}
                key={device.id} 
                {...this.props}
                keycodeId={this.props.keycodeId}
                canRelease={this.props.canRelease}
                setReleaseDeviceParams={this.setReleaseDeviceParams}
            />
        )
    }
    
    setReleaseDeviceParams = (deviceId, keycodeId) => {
      this.props.releaseDeviceById(deviceId, keycodeId);
    }
    
    refresh = () => {
      this.props.getDevices({ 
        limit: this.state.limit,
        page: this.state.page,
        keycodeId: this.state.keycodeId
      }, () => {
        this.setState({loading: false});
      });
      this.props.releaseDeviceInit();
      this.props.getKeycodes();
    }
    
    handlePagination = (page) => {
      this.setState({ page }, () => {
        this.props.getDevices({ 
          limit: this.state.limit,
          page: this.state.page,
          keycodeId: this.state.keycodeId
        }, () => {
          this.setState({loading: false});
        });
      });
    }
    
    render() {
        const {loading} = this.state;
        return (
            <div className={styles.devicesPage + ' row'}>     
                <div className={styles.devicesContainer}>
                    <p className="big-size-font">{ this.props.canRelease ? 'Manage Devices' : 'View Devices'} </p>
                    <table className={styles.devicesTable + ' table table-hover'}>
                        <tbody>
                            <tr className={styles.titles}>
                                <th>Slot</th>
                                <th>Device</th>
                                <th>Status</th>
                                { this.props.canRelease && <th>Actions</th> } 
                            </tr>
                            {!loading && this.props.devices && this.getDevices()}
                        </tbody>
                    </table>
                    {loading && <Loader active inline />}
                    {
                      !loading && this.props.devices && this.props.devices.length === 0 ?
                      'No devices found' :
                      <Pagination 
                      className={styles.devicesPagination}
                      activePage={this.props.page}
                      items={this.props.limit}
                      maxButtons={this.props.totalPages}
                      onSelect={this.handlePagination}
                      />
                  }
                </div>                                
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const { releaseCompleted } = state.manageModalWindow;
    const { fullUserInfo } = state.authentification;
    const canRelease = fullUserInfo.user.admin ||
        fullUserInfo.user.partner ||
        fullUserInfo.roles.includes('Customer');
    return {
        ...state.devicesManage,
        releaseCompleted,
        canRelease
    };
};
  
const mapDispatchToProps = dispatch => ({
    getKeycodes: () => dispatch(dashboardGetAssignedKeycodes()),
    showModalWindow: modalData => dispatch(showModalWindow(modalData)),
    releaseDeviceById: (deviceId, keycodeId) => dispatch(setReleaseDeviceId(deviceId, keycodeId)),
    releaseDeviceInit: () => dispatch(releaseDeviceInit()),
    getDevices: (query, cb) => dispatch(getDevices(query, cb)),
});
  
export default connect(mapStateToProps, mapDispatchToProps)(ManageDevices);