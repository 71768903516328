import React, { Component } from 'react';
import { Tab, Icon } from 'semantic-ui-react';
import 'semantic-ui-css/semantic.min.css';
import { Input } from 'react-materialize';
import styles from './Account.module.scss';
import { changePassword, updateUserInfo } from '../../store/AC/authentification';
import { connect } from 'react-redux';
import { showModalWindow } from '../../store/AC/manageModalWindow';
import { DELETE_USER_SELF } from '../../store/constants/usersManage';
import { hideDelete } from '../../utils/helpers';
import { phoneValidator, fieldNotEmpty } from '../../utils/validators';


class Account extends Component {
   
    constructor(props) {
        super(props);
        this.state = {
            oldPassword: '',
            oldPasswordError: false,        
            newPassword: '',
            newPasswordError: false,
            firstName: '',
            firstNameError: false,        
            lastName: '',
            lastNameError: false,
            companyName: '',
            companyNameError: false,
            phone: '',
            phoneError: false,
            canEdit: false,
            showSuccessUser: false,
            showSuccessPassword: false,
            showErrorPassword: false
        };

        this.handleNewPwd = this.handleNewPwd.bind(this);
        this.handleOldPwd = this.handleOldPwd.bind(this);
        this.handleFirstName = this.handleFirstName.bind(this);
        this.handleLastName = this.handleLastName.bind(this);
        this.handleCompany = this.handleCompany.bind(this);
        this.handlePhone = this.handlePhone.bind(this);     
    }
    
    componentDidMount() {
        if (this.props.activeIndex) {   
            this.props.activeIndex('Account');    
        }
    }

    handleNewPwd(e) {
        this.setState({
            newPassword: e.target.value,
            newPasswordError: !fieldNotEmpty(e.target.value)
        })
    }
    handleOldPwd(e) {
        this.setState({
            oldPassword: e.target.value,
            oldPasswordError: !fieldNotEmpty(e.target.value)
        })
    }
    changePwd = () => {
        const { newPassword, oldPassword, oldPasswordError, newPasswordError } = this.state;                
        
        this.handleNewPwd({ target: { value: newPassword }});
        this.handleOldPwd({ target: { value: oldPassword }});
    
        if (!oldPasswordError && !newPasswordError && newPassword.length * oldPassword.length > 0) {
            this.setState({
                showErrorPassword: false,
                showSuccessPassword: false
            });                              
            this.props.changePassword({ newPassword, oldPassword }, this.handleUpdatePassword);
        } 
        
    }
    handleFirstName = (e) => {        
        this.setState({
            firstName: e.target.value,
            firstNameError: !fieldNotEmpty(e.target.value)
        })
    }
    handleLastName = (e) => {
        this.setState({
            lastName: e.target.value,
            lastNameError: !fieldNotEmpty(e.target.value)            
        })
    }
    handleCompany = (e) => {
        this.setState({
            companyName: e.target.value,
            companyNameError: !fieldNotEmpty(e.target.value)
        })
    }
    handlePhone = (e) => {
        this.setState({
            phone: e.target.value,
            phoneError: !phoneValidator(e.target.value)
        })
    }    
    showEditForm = () => {
        this.setState({
            canEdit: !this.state.canEdit,
        })
    }
    updateUser = () => {
        const { fullUserInfo, fullUserInfo: { user: { id } }, updateUserInfo } = this.props;
        const { firstName, lastName, companyName, phone, 
                firstNameError, lastNameError, companyNameError, phoneError } = this.state;
        
        if (!firstNameError && !lastNameError && !companyNameError && !phoneError) {        
            let editedUser = Object.assign({},
                fullUserInfo.user,
                { firstName },
                { lastName },
                { companyName },
                { phone: phone.toString() });        
            updateUserInfo(editedUser, id, this.handleUpdateUser);
            this.setState({
                canEdit: false
            })
        }        
    }

    handleUpdateUser = response => {                
        if (response.status === 200) {
            this.setState({showSuccessUser: true});            
        } else {
            this.setState({showSuccessUser: false});            
        }
    }

    handleUpdatePassword = response => {        
        if (response.status === 204) {
            this.setState({showSuccessPassword: true});            
        } else {
            this.setState({showErrorPassword: true});
        }
    }

    componentWillMount() {
        if (this.props.fullUserInfo && this.props.fullUserInfo.user) {
            const { firstName, lastName, companyName, phone, } = this.props.fullUserInfo.user;
            this.setState({
                firstName, lastName, companyName, phone,
            });
        }

    }

    deleteUserSelf = () => {
        this.props.showModalWindow({
            openModal: true,
            modalTitle: 'Delete your data',
            modalDescription: `Are you sure you would like to delete your data?`,
            action: DELETE_USER_SELF
        })
    }

    renderUserInfo = () => {
        const { fullUserInfo: { user } } = this.props;       
        const { firstNameError, lastNameError, companyNameError, phoneError }  = this.state;

         return (
            <Tab.Pane>
                <div className={styles.userEditInfo}>         
                <div>
                {this.state.showSuccessUser ? 
                    <div className="message success">
                        <Icon name="check circle"></Icon>
                        <p>User Information Succesfully Updated</p>
                    </div> : null
                }
                <div>
                    <span>
                        Name: {user.firstName}
                    </span>
                    <div>
                        <Input s={6} defaultValue={user.firstName} 
                            label="First name" 
                            onChange={this.handleFirstName} 
                            className={firstNameError ? 'inputError' : null}/>
                    </div>
                </div>
                <div>
                    <span>
                        Last Name: {user.lastName}
                    </span>
                    <div>
                        <Input s={6} defaultValue={user.lastName} 
                            label="Last name" 
                            onChange={this.handleLastName} 
                            className={lastNameError ? 'inputError' : null}
                        />
                    </div>
                </div>
                <div>
                    <span>
                        Company: {user.companyName}
                    </span>
                    <div>
                        <Input s={6} defaultValue={user.companyName} 
                            label="Company" 
                            onChange={this.handleCompany} 
                            className={companyNameError ? 'inputError' : null}
                        />
                    </div>
                </div>
                <div>
                    <span>
                        Phone: {user.phone}
                    </span>
                    <div>
                        <Input s={6} defaultValue={user.phone} 
                            label="Phone" 
                            onChange={this.handlePhone} 
                            className={phoneError ? 'inputError' : null}
                            />
                    </div>
                </div>                
            </div>    
            </div>
            <div className={styles.button} onClick={this.updateUser}>Update</div>
        </Tab.Pane>
         );
    }

    renderResetPassword = () => {
        const { oldPasswordError, newPasswordError, showSuccessPassword, showErrorPassword }  = this.state;
        const { shouldChangePassword } = this.props;
        return (
            <Tab.Pane>
                <div className={styles.resetContainer}>
                    <div>
                        {showSuccessPassword ?
                            <div className="message success">
                                <Icon name="check circle"></Icon>
                                <p>Password Succesfully Updated</p>
                            </div> : null
                        }
                        {showErrorPassword ?
                            <div className="message error">
                                <Icon name="warning circle"></Icon>
                                <p>Old Password is incorrect, please try again</p>
                            </div> : null
                        }
                        {shouldChangePassword ?
                            <div className="message error">
                                <Icon name="warning circle"></Icon>
                                <p>For security reasons please change default password</p>
                            </div> : null
                        }
                        <Input s={12} label="Old password" 
                            type="password" 
                            onChange={this.handleOldPwd} 
                            className={oldPasswordError ? 'inputError' : null}
                        />
                        <Input s={12} label="New password" 
                            type="password" 
                            onChange={this.handleNewPwd} 
                            className={newPasswordError ? 'inputError' : null}
                        />
                    </div>
                </div>
                <div className={styles.button} onClick={this.changePwd}>Update</div>
            </Tab.Pane>
        );
    }

    renderDeleteAccount = () => {
        const { fullUserInfo: { roles } } = this.props;
        return (
            <Tab.Pane>
                <div>                    
                    {!hideDelete(roles) ?
                        <div className={styles.deleteContainer}>
                            <h2>Are you sure? <br/>Contact our support team</h2>
                            <p><a href="mailto:support@ailatech.com">support@ailatech.com</a></p>                            
                            <button className='btn btn-danger btn-sm' onClick={this.deleteUserSelf}>
                                Delete Account
                            </button>                            
                        </div> : null}
                </div>
            </Tab.Pane>
        );
    }

    handleTabChange = () => {
        const state = {
            showErrorPassword: false,
            showSuccessPassword: false,
            showSuccessUser: false
        }
        this.setState(state);                
    }

    render() {
        const { fullUserInfo: { roles }, shouldChangePassword } = this.props;
        const panes = [
            { menuItem: 'User Information', render: () => this.renderUserInfo() },
            { menuItem: 'Change My Password', render: () => this.renderResetPassword() }
          ];

        if (!hideDelete(roles)) {
            panes.push({ menuItem: 'Delete Account', render: () => this.renderDeleteAccount() });
        }
          
        return (            
            <div className={styles.accountPage + ' offset-md-2'}>               
                <div className={styles.content}>
                    <p className='big-size-font'>Account Settings</p>
                    <Tab className={ styles.tabsContainer } 
                        panes={ panes } 
                        onTabChange={ this.handleTabChange } 
                        defaultActiveIndex={ shouldChangePassword ? 1 : 0 } 
                    />                     
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const { fullUserInfo, fullUserInfo: { user }, shouldChangePassword } = state.authentification;
    return {
        fullUserInfo, user, shouldChangePassword
    };
};

const mapDispatchToProps = dispatch => ({
    changePassword: (passwords, cb) => dispatch(changePassword(passwords, cb)),
    updateUserInfo: (userData, id, cb) => dispatch(updateUserInfo(userData, id, cb)),
    showModalWindow: data => dispatch(showModalWindow(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(Account);