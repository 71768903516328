import { 
    SHOW_MODAL_WINDOW,
    CLOSE_MODAL_WINDOW,
    CONFIRM_MODAL_ACTION,

} from '../constants/manageModalWindow'; 
import { INACTIVE_USER, DELETE_USER_SELF } from '../constants/usersManage';
import { SUCCESS } from '../constants/main';
import { DELETE_KEYCODE } from '../constants/keycodesManage';
import { RELEASE_DEVICE, RELEASE_DEVICE_INIT } from '../constants/devicesManage';

let initialState = {
    openModal: false,
    modalTitle:'',
    modalDescription: '',
    action: '',
    releaseCompleted: false
};

export default function manageModalWindow(state = initialState, action) {
    const { payload, } = action;  
    switch (action.type) {
        case SHOW_MODAL_WINDOW:
            return { 
                ...state,
                openModal:payload.openModal,
                modalTitle: payload.modalTitle,
                modalDescription: payload.modalDescription,
                action: payload.action
             }
        case CLOSE_MODAL_WINDOW:
            return { ...state }
        case CONFIRM_MODAL_ACTION:
            return { ...state }
        case INACTIVE_USER + SUCCESS:
             return {
                 ...state,
                 openModal: false
             }
        case DELETE_KEYCODE + SUCCESS:
             return {
                 ...state,
                 openModal: false
             }
        case DELETE_USER_SELF + SUCCESS:
             return {
                 ...state,
                 openModal: false
             }
        case RELEASE_DEVICE + SUCCESS:
            return {
                ...state,
                openModal: false,
                releaseCompleted: true
            }
        case RELEASE_DEVICE_INIT:
            return {
                ...state,
                releaseCompleted: false
            }
        default: 
            break;
 	} 
    return {
        ...state, 
    }
}
