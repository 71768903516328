import React from 'react';
import Modal from 'react-responsive-modal';
import styles from './ModalWindow.module.scss';

const ModalWindow = ({ open, onClose, children, center, showCloseIcon, closeOnEsc, closeOnOverlayClick }) => {
  return (
    <div>
      <Modal
        open={open} onClose={onClose}
        center={center}
        classNames={{ modal: styles.modal }}
        showCloseIcon={showCloseIcon}
        closeOnEsc={closeOnEsc}
        closeOnOverlayClick={closeOnOverlayClick}
      >
        <div className={styles.body}>
          {children}
        </div>
      </Modal>
    </div>
  );
};

export default ModalWindow;
