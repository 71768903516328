import React, { Component } from 'react';
import styles from '../Documentation.module.scss';

class Welcome extends Component {
    
    componentDidMount() {
        this.props.activeIndex('Welcome');
    }

    render() {
        const fishText = 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse hendrerit, augue et elementum viverra, enim lorem imperdiet nisi, id vestibulum ante nulla eu ipsum. Duis dignissim, massa ut cursus finibus, purus mauris rhoncus eros, quis pulvinar nibh erat at velit. Donec eu luctus mi, at malesuada nunc. Donec pulvinar ipsum nec enim bibendum tincidunt. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Nam egestas rhoncus congue. Sed sodales mollis accumsan. Sed in nisi a enim egestas efficitur. Pellentesque efficitur, magna non vulputate pellentesque, tortor nisl luctus lectus, et dictum velit turpis et ex. Sed condimentum arcu egestas neque vehicula, non pellentesque ante interdum. Integer sed blandit quam, vel sodales magna. Vestibulum ut pretium turpis, quis auctor lectus. Sed dui augue, fringilla ut viverra sed, maximus id orci. Suspendisse eros urna, ultricies quis dui aliquam, vehicula cursus odio. Quisque consectetur lacinia libero vel commodo. Maecenas blandit laoreet elit, quis luctus odio euismod ac. Nam eget pharetra magna. Phasellus urna lorem, vulputate elementum aliquet in, tristique at lorem. Mauris suscipit ipsum lacus, nec faucibus magna suscipit id. Nulla volutpat placerat congue. Aliquam pretium dapibus est congue sollicitudin. Vivamus pellentesque ex et ante rhoncus, eu blandit nulla imperdiet. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Aenean vel lorem sit amet lectus tincidunt posuere placerat nec ex.';
        return (
            <div>
                <p className={styles.docTitle}>Welcome</p>
                <p>{fishText}</p>
                <span className={styles.tableName}>Lorem ipsum dolor</span>
                <table className="table table-hover">
                    <tbody>
                        <tr>
                            <td>Lorem ipsum dolor</td>
                            <td>Lorem ipsum dolor</td>
                            <td>Lorem ipsum dolor</td>
                        </tr>
                        <tr>
                            <td>Lorem ipsum dolor</td>
                            <td>Lorem ipsum dolor</td>
                            <td>Lorem ipsum dolor</td>
                        </tr>
                        <tr>
                            <td>Lorem ipsum dolor</td>
                            <td>Lorem ipsum dolor</td>
                            <td>Lorem ipsum dolor</td>
                        </tr>

                    </tbody>
                </table>

            </div>
        );
    }

}

export default Welcome;