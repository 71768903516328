import React, { Component } from "react";
import Modal from "react-responsive-modal";
import { showModalWindow, confirmActionModal, } from '../../store/AC/manageModalWindow';
import { connect } from 'react-redux';
import styles from './ConfirmModalWindow.module.scss';
import cn from 'classnames';

class ConfirmModalWindow extends Component {
  onCloseModal = () => {
    this.props.showModalWindow({
      openModal: false,
    });
  };

  render() {
    const { openModal, modalTitle, modalDescription, } = this.props;
    return (
      <div className={styles.modalWindow}>
        <Modal 
          open={openModal} 
          onClose={this.onCloseModal} 
          center
          >
          <div className={styles.modalBody}>
            <div className={cn(styles.title, "big-size-font")}>{modalTitle}</div>
            <p className={cn(styles.description)}>{modalDescription}</p>
            <div className={styles.modalActions}>
              <div className={cn(styles.button)} onClick={() => this.onCloseModal()}>Cancel</div>
              <div className={cn(styles.button, styles.buttonConfirm)} onClick={() => this.props.confirmModalWindow(this.props.action)}>Confirm</div>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { openModal, modalTitle, modalDescription, action } = state.manageModalWindow;
  return {
    openModal, modalTitle, modalDescription, action
  };
};

const mapDispatchToProps = dispatch => ({
  showModalWindow: modalData => dispatch(showModalWindow(modalData)),
  confirmModalWindow: action => dispatch(confirmActionModal(action))
});

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmModalWindow);