import React, { Component } from 'react';
import styles from './Header.module.scss';
import logo from '../../img/logo.png'
import { NavLink, Link, withRouter } from 'react-router-dom'
import { connect } from 'react-redux';
import {getSelf, logout} from '../../store/AC/authentification';
import _ from "lodash";

class Header extends Component {
    state = {
        showMoblieMenu: false,
        isMobile: false,
        showUserMenu: false,
    }
    showMenu = () => {
        this.setState({
            showMoblieMenu: !this.state.showMoblieMenu,
        })
    }
    showUserMenu = () => {
        this.setState({
            showUserMenu: !this.state.showUserMenu,
        })
    }
    hideMenu = () => {
       if(!this.state.isMobile) return;
       this.setState({
           showMoblieMenu: false,
       })
    }
    logout = () => {
        this.props.logout();
        this.showUserMenu();
    }


    componentWillMount(){
        const self = this;
        if(window.innerWidth>950){
            this.setState({
                showMoblieMenu: true,
            })
        } else {
            this.setState({
                isMobile: true,
            })
        }
        window.addEventListener('resize', function(){
            if(window.innerWidth<950){
                self.setState({
                    showMoblieMenu: false,
                    isMobile: true,
                })
            } else {
                self.setState({
                    showMoblieMenu: true,
                    isMobile: false,
                })
            }
        })
    }

    handleSelfCb = response => {
        const statusCode = _.get(response, 'response.status', 200);
        if (statusCode === 401) {
            this.props.logout();
        }
    }

    componentDidMount() {
        this.props.getSelf(this.handleSelfCb);
    }

    render() {
      const { showMoblieMenu, showUserMenu } = this.state;
      const show = { 'display' : 'block' };
      const hide = { 'display' : 'none' };
      let auth = false;
      let token = localStorage.getItem('id');
      let userInfo = null;
      if( token!==null) {
          auth = true;
          userInfo = this.props.fullUserInfo.user;
        }
        let userInitials = null,
        userFirstName = null,
        isAdmin = false;

        if(userInfo){
            let { firstName, lastName, } = userInfo;
            userInitials = firstName[0].toUpperCase()+lastName[0].toUpperCase();
            userFirstName = firstName;
            const { fullUserInfo, } = this.props;
            if(fullUserInfo.roles){
                isAdmin = this.props.fullUserInfo.roles.indexOf('Admin')>-1;
            }
        }

      return (
          <header>
              <ul className={`block items-center md:flex ${showMoblieMenu ? 'block' : 'hidden'}`}>
                  <li onClick={this.hideMenu}>
                      <Link to="/">
                          <img src={logo} alt='logo' />
                      </Link>
                  </li>
              {auth?
                  <span>
                      <li onClick={this.hideMenu}>
                          <NavLink to="/dashboard" activeClassName={styles.activeLink}>
                              Dashboard
                          </NavLink>
                      </li>

                      <li onClick={this.hideMenu}>
                          <NavLink to='/downloads' activeClassName={styles.activeLink}>
                              Downloads</NavLink>
                      </li>

                      <li onClick={this.hideMenu}>
                          <NavLink to='/documentation' activeClassName={styles.activeLink}>
                              Documentation
                          </NavLink>
                      </li>

                      <li onClick={this.hideMenu}>
                          <NavLink to='/tools' activeClassName={styles.activeLink}>
                              Tools
                          </NavLink>
                      </li>

                      <li onClick={this.hideMenu}>
                          <NavLink to='/billing' activeClassName={styles.activeLink}>
                              Billing
                          </NavLink>
                      </li>
                      <li onClick={this.hideMenu}>
                          <a target="_blank" rel="noopener noreferrer" href='https://ailatech.zendesk.com/'>
                              Support
                          </a>
                      </li>
                  </span>
                  :null}
              </ul>
              {auth ?
              <div>
                  <span onClick={this.showMenu} className={showMoblieMenu ? styles.hideMenu : styles.showMenu}></span>
                  <div className={styles.currentUserBlock}>
                      <span className={styles.userInitials}>{userInitials}</span>
                      <span className={styles.userFullname}>{userFirstName}</span>
                      <div className={styles.dropMenu} onClick={this.showUserMenu}></div>
                  </div>
                  {showUserMenu ?
                      <div className={styles.userMenu}>
                          <NavLink to='/account-settings'>
                              <div onClick={this.showUserMenu}>Account Settings</div>
                          </NavLink>

                          {isAdmin ?
                              <NavLink to='/manage-users'>
                                  <div onClick={this.showUserMenu}>Manage Users</div>
                              </NavLink>
                          : null}
                          {isAdmin ?
                              <NavLink to='/manage-keycodes'>
                                  <div onClick={this.showUserMenu}>Manage Keycodes</div>
                              </NavLink>

                          : null}

                          <NavLink to='/'>
                              <div onClick={this.logout}>Logout</div>
                          </NavLink>
                      </div>
                  :null}
              </div>
              :null}
          </header>
      )
    }
}


const mapStateToProps = (state) => {
    const { isLogin, userID, fullUserInfo, } = state.authentification;
    return {
        isLogin, userID, fullUserInfo,
    };
};

const mapDispatchToProps = dispatch => ({
    logout: () => dispatch(logout()),
    getSelf: (cb) => dispatch(getSelf(cb)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header));
