import React, { Component } from 'react';
import styles from './AccountSettings.module.scss';
import { BrowserRouter as Router, Route, NavLink } from "react-router-dom";
import { Accordion } from "semantic-ui-react";
import Account from '../Account/Account';
import PaymentHistory from '../PaymentHistory/PaymentHistory';
import SharedAccounts from '../SharedAccounts/SharedAccounts';

class AccountSettings extends Component {
  state = {
    activeIndex: -1
  }

  handleClick = (e, titleProps) => {
    const { index } = titleProps
    const { activeIndex } = this.state
    const newIndex = activeIndex === index ? -1 : index
    this.handleActiveIndex(newIndex);
  }

  handleActiveIndex = (index) => {
    this.setState({ activeIndex: index });
  }

  render() {
    const linkAct = styles.activeLink;
    const { activeIndex } = this.state
    return (
        <div className={styles.accountSettingsContainer}>
          <div className={styles.wrapper}>
            <menu>
              <Accordion>
                <Accordion.Title active={activeIndex === 'Account'} index={'Account'} onClick={this.handleClick}>
                  <NavLink to='/account-settings/account' activeClassName={linkAct}>
                    <div>Account Settings</div>
                  </NavLink>
                </Accordion.Title>
                <Accordion.Title active={activeIndex === 'PaymentHistory'} index={'PaymentHistory'} onClick={this.handleClick}>
                  <NavLink to='/account-settings/payment-history' activeClassName={linkAct}>
                    <div>Payment History</div>
                  </NavLink>
                </Accordion.Title>
                <Accordion.Title active={activeIndex === 'SharedAccounts'} index={'SharedAccounts'} onClick={this.handleClick}>
                  <NavLink to='/account-settings/shared-accounts' activeClassName={linkAct}>
                    <div>Shared Accounts</div>
                  </NavLink>
                </Accordion.Title>
              </Accordion>
            </menu>
            <div className={styles.dockBlock}>
              <div className={styles.oneDocItem}>
                <Route exact path='/account-settings' render={() => <Account activeIndex={this.handleActiveIndex} />} />
                <Route exact path='/account-settings/account' render={() => <Account activeIndex={this.handleActiveIndex} />} /> 
                <Route exact path='/account-settings/payment-history' render={() => <PaymentHistory activeIndex={this.handleActiveIndex} />} /> 
                <Route exact path='/account-settings/shared-accounts' render={() => <SharedAccounts activeIndex={this.handleActiveIndex} />} /> 
              </div>
            </div>
          </div>
        </div>
    );
  }
}

export default AccountSettings;