import {
    REQUEST_RESET_PASSWORD,
    RESET_PASSWORD,
    SET_TEMPORARY_TOKEN,
    CREATE_NEW_USER,
    LOGIN,
    LOGOUT,
    CHANGE_PASSWORD,
    UPDATE_USER, GET_SELF
} from '../constants/authentification';
import { SUCCESS, AUTHORIZATION_REQUIRED, FAIL } from '../constants/main';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { DELETE_USER_SELF } from '../constants/usersManage';
import history from '../../utils/history';


let initialState = {
    isLogin: false,
    userID: '',
    isTempID: false,
    shouldChangePassword: false,
    fullUserInfo: {},
    newUserCreated: false,
    resetError: false,
    errorMessage: null
};

 const user = function authentification(state = initialState, action) {
    switch (action.type) {
        case LOGIN + SUCCESS:
            const { shouldChangePassword } = action.payload;
            if (shouldChangePassword) history.push('/account-settings');
            return{
                ...state,
                isLogin: true,
                userID: action.payload.id,
                shouldChangePassword: shouldChangePassword,
                fullUserInfo: action.payload,
                resetSuccess: false,
                resetFail: false
            }
        case LOGOUT + SUCCESS:
            return {
                isLogin: false,
                userID: '',
            }
        case REQUEST_RESET_PASSWORD + SUCCESS:
            return {
                ...state,
                isTempID: false,
                resetSuccess: false,
                resetFail: false
            }
        case REQUEST_RESET_PASSWORD + FAIL:
            return {
                ...state,
                resetSuccess: false,
                resetFail: false
            }
        case RESET_PASSWORD + SUCCESS:
            return {
                ...state,
                isTempID: false,
                resetSuccess: true
            }
        case RESET_PASSWORD + FAIL:
            return {
                ...state,
                resetFail: true
            }
        case SET_TEMPORARY_TOKEN:
            return {
                ...state,
                isTempID: true,
            }
        case CHANGE_PASSWORD + SUCCESS:
            return {
                ...state,
                shouldChangePassword: false
            }
        case CREATE_NEW_USER:
            return { ...state };
        case UPDATE_USER + SUCCESS:
            let newFullUserInfo = state.fullUserInfo;
            newFullUserInfo.user = action.payload;
            return {
                ...state,
                fullUserInfo: {
                    ...state.fullUserInfo,
                    user: {
                        ...newFullUserInfo.user,
                    }
                }
            }
        case CREATE_NEW_USER + SUCCESS:
            history.push('/');
            return {
                ...state,
                newUserCreated: true,
                errorMessage: null,
            }
        case AUTHORIZATION_REQUIRED:
        case DELETE_USER_SELF + SUCCESS:
            return {
                isLogin: false,
                userID: '',
                isTempID: false,
                shouldChangePassword: false,
                fullUserInfo: {},
                newUserCreated: false
            }
        case CREATE_NEW_USER + FAIL:
            return {
                errorMessage: action.payload.response.data.error.message
            }
        default:
            break;
 	}
    return {
        ...state,
    }
}

const persistConfig = {
    key: 'user',
    storage: storage,
    blacklist: ['errorMessage']
  };

  export default persistReducer(persistConfig, user);
