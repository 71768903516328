export const generateKeycode = () => {
    let key = "";
    var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

    for (var i = 0; i < 41; i++) {
        key += possible.charAt(Math.floor(Math.random() * possible.length));

    }

    return key;
}