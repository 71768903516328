import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Loader } from 'semantic-ui-react'
import styles from './PaymentHistory.module.scss';
import PaymentHistoryItem from '../../blocks/PaymentHistoryItem/PaymentHistoryItem';
import Pagination from '../../blocks/Pagination/Pagination';
import { getPaymentHistory } from '../../store/AC/accountSettings';

class PaymentHistory extends Component {
    constructor(props) {
        super(props);
        this.state = {
            page: 1, 
            limit: 10,
            customerId: this.props.customerId,
            loading: true
        }
        this.props.getPaymentHistory({
            limit: this.state.limit,
            page: this.state.page,
            customerId: this.state.customerId
        }, this.handleGetPaymentsResponse);
    }
    
      
    componentDidMount() {
      this.props.activeIndex('PaymentHistory');    
    }
    
    handleDownloadPDF(invoiceUrl) {
      window.location = invoiceUrl;
    }
    
    getPaymentHistory = () => {
      return this.props.payments.map(payment => {
          return (<PaymentHistoryItem 
              paymentData={payment} 
              key={payment.id} 
              {...this.props}
              downloadPDF={this.handleDownloadPDF}
          />);
      });
    }
    
    refresh = () => {
        this.props.getPaymentHistory({ 
            limit: this.state.limit,
            page: this.state.page,
            customerId: this.state.customerId
        });
    }
      
    handlePagination = (page) => {
        if (page === this.props.page) return; 
        this.setState({ page, loading: true }, () => {
            this.props.getPaymentHistory({ 
            limit: this.state.limit,
            page: this.state.page,
            customerId: this.state.customerId
            }, this.handleGetPaymentsResponse);
        });
    }
    
    handleGetPaymentsResponse = () => {
        this.setState({ loading: false });
    }

    render() {
        const {loading} = this.state;
        return (            
            <div className={styles.paymentHistoryPage}>               
                <div className={styles.paymentHistoryContainer}>
                    <p className='big-size-font'>Payment History</p>       
                      <table className={styles.paymentHistoryTable + ' table table-hover'}>
                          <tbody>
                              <tr className=''>
                                  <th>Date</th> 
                                  <th>Method</th>
                                  <th>Total</th>
                                  <th>Status</th>
                                  <th>Actions</th>
                              </tr>
                              {this.props.payments && this.getPaymentHistory()}
                          </tbody>
                      </table>
                      {loading && <Loader active inline />}  
                      {
                      !loading && this.props.payments && this.props.payments.length === 0 ?
                      'No Payments found' :
                      <Pagination 
                      className={styles.paymentsPagination}
                      activePage={this.props.page}
                      items={this.props.limit}
                      maxButtons={this.props.totalPages}
                      onSelect={this.handlePagination}
                      />
                  }          
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        ...state.accountSettings
    };
};

const mapDispatchToProps = dispatch => ({ 
  getPaymentHistory: (query, cb) => dispatch(getPaymentHistory(query, cb))
});

export default connect(mapStateToProps, mapDispatchToProps)(PaymentHistory);