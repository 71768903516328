import React, { Component } from 'react';
import styles from './EditUser.module.scss';
import { Input } from 'react-materialize';
import InputMask from 'react-input-mask';
import { phoneValidator, emailValidator, fieldNotEmpty } from '../../utils/validators';
import Phone from '../../common/Phone';
import ModalWindow from '../../common/ModalWindow/ModalWindow';
import cn from 'classnames';

class EditUser extends Component {
    constructor(props) {
        super(props);                
        const { userData } = this.props;        
        const { firstName, lastName, phone, email, partner, active } = userData ? userData : {};        
        this.state = {
            open: true,
            form: {
                firstName: firstName ? firstName : '',
                firstNameError: false,
                lastName: lastName ? lastName : '',
                lastNameError: false,
                phoneCountry: phone ? '' : 'us',
                phone: phone ? phone : '',                
                phoneError: false,
                email: email ? email : '',
                emailError: false,
                partner: partner ? partner : false,
                active: active
            },
            errors: {}
        }
    }

    save = (e) => {
        e.preventDefault();

        const { firstName, firstNameError, lastName, 
            lastNameError, phoneCountry, phone, 
            phoneError, email, emailError, partner, active } = this.state.form;

        if(!firstNameError && !lastNameError && !phoneError && !emailError 
            && firstName.length * phone.length * email.length > 0 ) {                                
            const data = {
                firstName,
                lastName,
                phone,
                email,
                partner,
                active
            }
            this.props.saveBtn(data);
        } else {                        
            this.setState({
                ...this.state,
                form: {
                    ...this.state.form,
                    firstNameError: !fieldNotEmpty(firstName),
                    lastNameError: !fieldNotEmpty(lastName),
                    phoneError: !phoneValidator(phone),
                    emailError: !emailValidator(email)
                }                
            });
        }
    }

    handleChangeFirstName = (e) => {
        const { target } = e;
        const firstName = target.value;
        this.setState({
            ...this.state,
            form: {                                
                ...this.state.form,
                firstName: firstName,
                firstNameError: !fieldNotEmpty(firstName)
            }
        });
    }

    handleChangeLastName = (e) => {
        const { target } = e;
        const lastName = target.value;
        this.setState({
            ...this.state,
            form: {
                ...this.state.form,
                lastName: lastName,
                lastNameError: !fieldNotEmpty(lastName)
            }
        });
    }

    handleChangePhone = (value, country) => {
        this.setState({ 
            ...this.state,
            form: {
                ...this.state.form,
                phone: value,
                phoneCountry: country.countryCode,
                phoneError: !phoneValidator(value)
            }            
        });
    }

    handleChangeEmail = (e) => {
        const { target } = e;
        const email = target.value;
        this.setState({
            ...this.state,
            form: {
                ...this.state.form,
                email: email,
                emailError: !emailValidator(email)
            }                        
        });
    }

    handleChangePartner = (e) => {
        const { target } = e;
        const value = target.checked;        
        this.setState({
            ...this.state,
            form: {
                ...this.state.form,
                partner: value
            }            
        })
    }

    handleChangeActive = (e) => {
        const { target } = e;
        const value = target.checked;        
        this.setState({
            ...this.state,
            form: {
                ...this.state.form,
                active: value
            }            
        })
    }

    onClose = () => {
        this.props.editUserById(null);
        this.setState({
            open: false
        });
    }


    render() {
        const { firstName, firstNameError, lastName, 
                lastNameError, phoneCountry, phone, 
                phoneError, email, emailError, partner, 
                active } = this.state.form;

        const { userData, errorMessage } = this.props;
        const { open } = this.state;
        
        return (
            <ModalWindow open={open} onClose={this.onClose}>
                <div className={cn(styles.title, "big-size-font")}>
                    {!userData ?
                        'New User'
                    :
                        'Edit User'
                    }
                </div>
                <form onSubmit={this.save} className={styles.editUserBlock}>
                    <div className={styles.dataInput}>
                        <div className={firstNameError ? styles.dashed : null}>
                            <Input name="firstName" onChange={this.handleChangeFirstName} label="* First name" defaultValue={firstName} />
                        </div>
                        <div className={lastNameError ? styles.dashed : null}>
                            <Input name="lastName" onChange={this.handleChangeLastName} label="* Last name" defaultValue={lastName} />
                        </div>
                        <div className={emailError ? styles.dashed : null}>
                            <Input name="email" onChange={this.handleChangeEmail} label="* Email" defaultValue={email} />
                        </div>
                        <div className={styles.phoneContainer + ' ' + (phoneError ? styles.errorField : '')}>
                            <Phone value={phone} placeholder="* Phone" onChange={this.handleChangePhone} phoneCountry={phoneCountry} />   
                        </div>
                        <div className={styles.partner}>
                            <p>Is Partner?</p>
                            <Input name='partner' onChange={this.handleChangePartner} type='checkbox' label=' ' checked={partner} />                            
                        </div>
                        <div className={styles.partner}>
                            <p>Is Active?</p>
                            <Input name='active' onChange={this.handleChangeActive} type='checkbox' label=' ' checked={active} />                            
                        </div>
                    </div>
                    <div className={styles.error}>
                        {errorMessage}
                    </div>
                    <div className={styles.actionsContainer}>
                        <button type="submit" className={styles.saveButton}>Save</button>                    
                    </div>                    
                </form>
            </ModalWindow>
        );
    }
}

export default EditUser;
