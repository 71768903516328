import React from 'react';
import styles from './Spinner.module.scss';
import { Loader, Dimmer } from 'semantic-ui-react'

const Spinner = ({ className, children }) => {
    return (
        <div>
            <div className={styles.preloader + ' ' + className} />
            <Dimmer active page>
                <Loader>
                    <p className={styles.text}>{children}</p>
                </Loader>
            </Dimmer>            
        </div>        
    );
};

export default Spinner;