import React, { Component } from 'react';
import styles from './SharedAccountsItem.module.scss';
import { formatDate } from '../../utils/helpers';

class SharedAccountsItem extends Component {
    constructor(props) {
        super(props);
        this.state = {}                
    }

    render() {                
        const { user, onOpenDeleteModal } = this.props;                
        const { email, createdDate } = user;
        return (
            <tr className={styles.sharedAccountsItem}>
                <td>{email}</td>
                <td>{formatDate(createdDate)}</td>                         
                <td className={styles.actions}> 
                    <div className={styles.delete} onClick={() => onOpenDeleteModal(user)}>
                        Delete
                    </div>
                </td>
            </tr>                        
        );
  }
}

export default SharedAccountsItem;