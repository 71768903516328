import React, { Component } from 'react';
import styles from './SharedAccounts.module.scss';
import cn from 'classnames';

class SharedAccountsDeleteModal extends Component {
    
    render() {
        const {onCancel, onConfirm, deleteUser} = this.props;        
        return(
            <div className={styles.deleteModal}>
                <div className={cn(styles.title, "big-size-font")}>Delete Shared Account</div>
                <text>Are you sure you want to delete {deleteUser.email}?</text>   
                <div className={styles.buttons}>
                    <div className={styles.button} onClick={onCancel}>Cancel</div>
                    <div className={cn(styles.button, styles.confirm)} 
                        onClick={() => {onConfirm(deleteUser.id); onCancel();}}>
                            Confirm                    
                    </div>
                </div>                             
            </div>
        );
    }
}


export default SharedAccountsDeleteModal;