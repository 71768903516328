import React, { Component } from 'react';
import { connect, } from 'react-redux';
import styles from './ManageUsers.module.scss';
import UserItem from '../../blocks/UserItem/UserItem';
import EditUser from '../../blocks/EditUser/EditUser';
import { editUserById, getAllUsers, addNewUser, saveEditUser, setDeleteUserId, } from '../../store/AC/usersManage' 
import { showModalWindow, } from '../../store/AC/manageModalWindow';


class ManageUsers extends Component {

    componentDidMount() {
        this.props.getAllUsers();
    }

    showAddUserform = () => {
        this.props.editUserById('addNew')
    }

    addNewUser = (data) => {
        this.props.addNewUser(data);
    }

    saveEditUser = (data) => {
        this.props.saveEditUser(this.props.editableUserId, data);
    }
    
    deleteUser = (id) => {
        this.props.makeInactiveUserById(id);
    }

    getUsers = () => {
        const users = this.props.users;
        return users.map( user =>
            <UserItem deleteUserById={this.deleteUser} 
                save={this.saveEditUser}
                userData={user} 
                key={user.id} 
                {...this.props} 
        />);
    }
  render() {
    const { editableUserId } = this.props;
    
    return (
        <div className={styles.usersPage + ' row'}>
            <div className={styles.userContainer}>
                <p className='big-size-font'>Manage users</p>
                {editableUserId === 'addNew' ?
                    <EditUser {...this.props} saveBtn={this.addNewUser} />
                : null }
                <div className={styles.editButton} onClick={this.showAddUserform}>
                        NEW USER
                </div>
                <table className={styles.userTable + ' table table-hover'}>
                    <tbody>
                        <tr>
                            <th>First Name</th>
                            <th>Last Name</th>
                            <th>Email</th>
                            <th>Phone</th>
                            <th>Multiple Keys</th>
                            <th>Active</th>
                            <th>Actions</th>
                        </tr>
                        {this.getUsers()}
                    </tbody>
                </table>
            </div>
        </div>
    );
  }
}

const mapStateToProps = (state) => {
    const { editableUserId, users, errorMessage } = state.usersManage;
    return {
        editableUserId,
        users,
        errorMessage,
    };
};
  
const mapDispatchToProps = dispatch => ({
  editUserById: id => dispatch(editUserById(id)),
  showModalWindow: modalData => dispatch(showModalWindow(modalData)),
  getAllUsers: () => dispatch(getAllUsers()),
  addNewUser: (data) => dispatch(addNewUser(data)),
  saveEditUser: (id, data) => dispatch(saveEditUser(id, data)),
  makeInactiveUserById: (id) => dispatch(setDeleteUserId(id))
});
  
export default connect(mapStateToProps, mapDispatchToProps)(ManageUsers);