import React, { Component } from 'react';
import styles from './SampleApps.module.scss'

import oneIcon from '../../../img/icon_clc.png'
import twoIcon from '../../../img/icon-tShirt.png'

class SampleApps extends Component {
  componentDidMount() {
    this.props.activeIndex('SampleApps');
  }

  render() {
    return (
      <div className={styles.samplePage}>
        <p className={'big-size-font ' + styles.title}>Sample Apps</p>
        <p className=''>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec commodo metus et velit blandit dictum. Vivamus vel mi ex. Nullam non felis orci..</p>

        <div className={styles.one_app_item + ' col-md-3 col-sm-4'}>
          <div className={styles.one_app_item_logo}>
            <img src={oneIcon} alt='icon' />
          </div>
          <span>Diagnostics App</span>
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec commodo metus et velit blandit dictum ipsum.</p>
        </div>

        <div className={styles.one_app_item + ' col-md-3 col-sm-4'}>
          <div className={styles.one_app_item_logo}>
            <img src={twoIcon} alt='icon' />
          </div>
          <span>Example App</span>
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec commodo metus et velit blandit dictum ipsum.</p>
        </div>

      </div>
    );
  }
}

export default SampleApps;
