import React from 'react';
import './Footer.style.scss';

const Footer = () => {
    return (
            <footer>
                <span>© {(new Date()).getFullYear()} Aila Technologies, Inc. All rights reserved</span>
                <ul>
                    <li>
                        <a target="_blank" rel="noopener noreferrer" href="https://ailatech.com/wp-content/uploads/2018/09/PRIVACY_POLICY.pdf">Privacy Policy</a>
                    </li>
                </ul>
            </footer>
    )
}

export default Footer;

