import {
  GET_SDK_FILE
} from '../constants/download';
import { SUCCESS, FAIL } from '../constants/main';

let initialState = {
  error: false
};

export default function download(state = initialState, action) {
  switch (action.type) {
      case GET_SDK_FILE + SUCCESS:
        return {
          ...state
        }
      case GET_SDK_FILE + FAIL:
        return{
              ...state,
              error: true
          }
      default: 
          break;
 } 
  return {
      ...state, 
  }
}
