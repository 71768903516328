import React, { Component } from 'react';
import styles from '../Documentation.module.scss';
import img1 from '../../../img/documentation/InstallingDemo_1.png'
import img2 from '../../../img/documentation/InstallingDemo_2.png'
import img3 from '../../../img/documentation/InstallingDemo_3.png'
import img4 from '../../../img/documentation/InstallingDemo_4.png'
import img5 from '../../../img/documentation/InstallingDemo_5.png'
import img6 from '../../../img/documentation/InstallingDemo_6.png'

class InstallingDemo extends Component {
    componentDidMount() {
        this.props.activeIndex('InstallingDemo');
    }

    render() {
        return (
            <div>
                <h1 className={styles.docTitle}>Installing Demo Application</h1>
                <p>
                    We’ve created a simple demo application that lets you try out the scanning features of your Aila devices. 
                    Follow these instructions for downloading and installing the app to get up and running quickly.
                </p>
                <p>
                    These instructions apply to Aila’s Mobile Imager and Interactive Kiosk
                </p>
                <p>
                    Note: if you haven’t yet, please first visit our Developer Resources page to get started. 
                </p>
                <h2>Instructions</h2>
                <h3>1st Step</h3>
                <figure>
                    <img src={img1}></img>
                    <figcaption>
                        Download the demo app “IPA” to a computer that has iTunes installed. The download is 
                        password protected—enter the password you were given when ordering the demo kit.
                    </figcaption>
                </figure>
                <h3>2nd Step</h3>
                <figure>
                    <img src={img2}></img>
                    <figcaption>
                        Connect your device and, if alerted to, choose “Trust this Computer.”
                    </figcaption>
                </figure>
                <h3>3rd Step</h3>
                <figure>
                    <img src={img3}></img>
                    <figcaption>
                        Double-click the downloaded file on your computer to open it. You may need to edit your security
                        preferences to allow the app to open: System Preferences → Security & Privacy → “Open Anyway.”
                    </figcaption>
                </figure>
                <h3>4th Step</h3>
                <figure>
                    <img src={img4}></img>
                    <figcaption>
                        At this point you should be on your device summary page in iTunes on your computer. Locate the 
                        IPA file in your file manager and then drag to the ‘On My Device’ Section in iTunes. 
                        The app should begin copying to your device. 
                        <br/><br/><b>Note: you may need to update your device to the latest version of iOS for the demo app to install.</b>
                    </figcaption>
                </figure>
                <h3>5th Step</h3>
                <figure>
                    <img src={img5}></img>
                    <figcaption>
                        When you try to open the newly installed demo app, you will be prompted to trust the developer of the app.
                    </figcaption>
                </figure>
                <h3>6th Step</h3>
                <figure>
                    <img src={img6}></img>
                    <figcaption>
                        Navigate to Settings → General → Device Management → Padloc Inc. → and click ‘Trust Padloc Inc.’
                    </figcaption>
                </figure>
                <p>
                    Navigate back to the Aila Demo app, allow access to your camera and microphone when prompted, and you will now be 
                    ready to go!
                </p>
                <h2>Questions?</h2>
                <p>
                    Contact us at <a href="malito:support@ailatech.com">support@ailatech.com</a>
                </p>
            </div>
        );
    }
}

export default InstallingDemo;