import React, { PureComponent } from 'react';
import styles from '../BillingModal.module.scss';
import { Input } from "react-materialize";

class PaymentSchedule extends PureComponent {
    render() {
        const { plans, isPartner, assignedKeycodes, license, planId, 
                activationsNumber, activationsNumberError, coupon, selectedPlan, 
                selectPlan, handleActivationsNumber, handleCouponInput, calculatedCoupon } = this.props;
        const { error: couponError } = calculatedCoupon;

        const totalCost = !activationsNumberError ? ' $' + activationsNumber * selectedPlan.price : null;
        
        return (
            <div>
                <div>
                    <p className={styles.subtitle}>Choose your payment schedule</p>
                    <ul>
                        {plans ? plans.map(plan => {
                            if (plan.id === 'trial') {
                                if ((isPartner || assignedKeycodes.length === 0) && !license) {
                                    return <li onClick={() => selectPlan(plan.id)} className={planId === plan.id ? styles.active : ''}>{plan.title}</li>
                                }
                            } else {
                                return <li onClick={() => selectPlan(plan.id)} className={planId === plan.id ? styles.active : ''}>{plan.title + ' / $' + plan.price}</li>
                            }
                        }) : null}
                    </ul>
                </div>
                <div>
                    <Input s={12} label="Activations Number" maxLength="10"
                        onChange={handleActivationsNumber} name="activationsNumber"
                        value={activationsNumber}
                        className={activationsNumberError ? styles.dashed : null} />
                    
                    <div className={styles.couponBox} >
                        <Input s={6} label="Coupon" maxLength="20" name="couponCode" 
                            onChange={handleCouponInput} 
                            value={coupon} 
                            className={couponError ? styles.dashed : null} />
                    </div>
                    <p>
                        <strong>Total Cost: </strong>
                        {totalCost}
                    </p>
                </div>
            </div>
        );
    }
}

export default PaymentSchedule;