import {
  GET_DEVICES,
  RELEASE_DEVICE,
  RELEASE_DEVICE_ID
} from '../constants/devicesManage';

export const setReleaseDeviceId = (deviceId, keycodeId) => ({ type: RELEASE_DEVICE_ID, payload: {deviceId, keycodeId}});

export const getDevices = (data, cb) => ({ 
  type: GET_DEVICES,
  payload: Object.assign( {}, data, { requestMethod:'GET', requestPath: 'api/Activations/devices' }, {callback: cb})
});

export const releaseDevice = (deviceId, keycodeId) => ({ 
  type: RELEASE_DEVICE,
  payload: Object.assign( {}, {deviceId, keycodeId}, { requestMethod:'POST', requestPath: 'api/Activations/release' })
});
