import axios from 'axios';
import { AUTHORIZATION_REQUIRED } from '../constants/main';
import history from '../../utils/history';
import { SET_TEMPORARY_TOKEN } from '../constants/authentification';
import { DELETE_USER_SELF } from '../constants/usersManage';
const _ = require('lodash');

export default store => next => action => {
	const { payload, type, } = action;
	const SERVER_URL = process.env.REACT_APP_SERVER_URL;

	if(type === SET_TEMPORARY_TOKEN) {
		localStorage.setItem('id', payload );
	}

	if(payload && payload.hasOwnProperty('requestMethod')){
		const method = payload.requestMethod;
		const path = payload.requestPath;
		const callback = payload.callback;

		delete payload.requestMethod;
		delete payload.requestPath;
		delete payload.callback;

		let reqData = JSON.stringify(payload);
		let token = localStorage.getItem('id');
		let params;
		if (method === 'GET') {
			params = payload
		}

		const invitedToken = localStorage.getItem('invitedToken');
		if (invitedToken !== null && [
			'CHANGE_PASSWORD',
			'UPDATE_USER',
			'DELETE_USER_SELF'
		].includes(type)) {
			token = invitedToken;
		}

		const headers = {
			'Content-Type': 'application/json',
			"Authorization": token
		}
		let responseType = 'json';

		if (type === 'GET_SDK_FILE') {
			responseType = 'blob'
		}

		axios({
			method,
			url: `${SERVER_URL}${path}`,
			data: reqData,
			headers,
			params,
			responseType
		  }).then(res => {
				if (callback) {
					callback(res);
				}

			const data = res.data;

			if(type==='LOGIN'){
				localStorage.setItem('id', data.id );
				data.invitedToken && localStorage.setItem('invitedToken', data.invitedToken);
				localStorage.setItem('user', JSON.stringify(data.user));
			}

			if(type==='LOGOUT' || type==='RESET_PASSWORD'){
				localStorage.clear();
			}

			if(type === DELETE_USER_SELF) {
				localStorage.clear();
				history.push('/');
			}

			return next({
				type: type+'_SUCCESS',
				payload: data
			})
		}).catch(function (error) {
			if (callback)
				callback(error);

			console.log('ERROR', error, error.response);
			const code = _.get(error, 'response.data.error.code', null);
			//const code = error.response ? error.response.data.error.code : null;

			if(code && code === AUTHORIZATION_REQUIRED) {
				localStorage.clear();
				history.push('/');
				return next({
					type: AUTHORIZATION_REQUIRED
				});
			}

			return next({
				type: type+'_FAIL',
				payload: error
			})
		});

	} else {
		next(action)
	}
}
