import React, { Component } from 'react';
import styles from './ActivationTile.module.scss';
import ModalWindow from '../../common/ModalWindow/ModalWindow';
import ManageDevices from '../../containers/ManageDevices/ManageDevices';
import { formatDate } from '../../utils/helpers';
import { Statistic } from 'semantic-ui-react';
import { isUndefined } from '../../utils/helpers';
import CancelModal from './CancelModal';

class ActivationTile extends Component {
    constructor(props) {
        super(props);        
        
        this.state = {
            openDevicesModal: false,
            openCancelModal: false
        };
    }
    
    releaseKeycode = (keycode) => {
        keycode.devices = [];
        this.props.releaseKeycode({ keycodeId: keycode.keycodeId });
    }

    openCancelModal = () => {
        this.setState({
            openCancelModal: true
        });
    }

    onCloseCancelModal = () => {
        this.setState({
            openCancelModal: false
        })
    }
    
    openDeviceModal = () => {
        this.setState({
            openDevicesModal: true
        }); 
    }
    
    onCloseDevicesModal = () => {
        this.setState({
            openDevicesModal: false
        });
    }

    render() {
        const { openDevicesModal, openCancelModal } = this.state;
        const { keycode, action, canRelease, cancelSubscription, onCancelSubscription } = this.props;                
        const { activationsAmount, totalLicenseAmount, keycodeId } = keycode;
        const remaining = activationsAmount ? activationsAmount : 0;
        const licenseLimit = totalLicenseAmount ? totalLicenseAmount : 0;   
        const hasValidAmounts = !isUndefined(totalLicenseAmount) && !isUndefined(activationsAmount);
        const activations = hasValidAmounts ? totalLicenseAmount - activationsAmount : 0;
        const today = new Date();
        const expiration = Date.parse(keycode.expirationDate); 
        const subscription = keycode.subscriptions[0];        
        
        return (
            <div className={styles.activationTile}>
                <div className={styles.headerBox}>
                    <div className={styles.title}>
                        <p className={'middle-size-font'}>SoftScan License</p> 
                    </div>
                    <div className={styles.manageDevice}>
                        <p onClick={this.openDeviceModal}>{ canRelease ? 'Manage Devices' : 'View Devices'}</p>
                    </div>
                </div>
                <ModalWindow className={styles.devicesModal} open={openDevicesModal} onClose={this.onCloseDevicesModal} center>
                    <ManageDevices keycodeId={keycodeId} />
                </ModalWindow>

                <p className={styles.keycode}>
                    <b>Keycode</b><br />{keycode.code}
                </p>
               
                {subscription.status == 'pending' ?
                    <Statistic.Group size='small'>
                        <Statistic color='yellow' inverted>
                            <Statistic.Value>Pending</Statistic.Value>
                            <Statistic.Label>Current State</Statistic.Label>
                        </Statistic>
                    </Statistic.Group>
                    :
                    <div>
                        {subscription.status == 'cancelled' || subscription.status == 'failed'  ?
                            <Statistic.Group size='small'>
                                <Statistic color='red' inverted>
                                    {subscription.status == 'cancelled' ? 
                                        <Statistic.Value>Cancelled</Statistic.Value>
                                    :
                                        <Statistic.Value>Failed</Statistic.Value>
                                    }                                    
                                    <Statistic.Label>Current State</Statistic.Label>
                                </Statistic>
                            </Statistic.Group>
                            :
                            <div>
                                {expiration - today > 0 ?
                                    <Statistic.Group size='small'>
                                        <Statistic color='yellow' inverted>
                                            <Statistic.Value>{activations}</Statistic.Value>
                                            <Statistic.Label>Current Activations</Statistic.Label>
                                        </Statistic>
                                        <Statistic color='green' inverted>
                                            <Statistic.Value>{remaining > 0 ? remaining : 0}</Statistic.Value>
                                            <Statistic.Label>Remaining Activations</Statistic.Label>
                                        </Statistic>
                                    </Statistic.Group>
                                    :
                                    <Statistic.Group size='small'>
                                        <Statistic color='red' inverted>
                                            <Statistic.Value>Expired</Statistic.Value>
                                            <Statistic.Label>Current State</Statistic.Label>
                                        </Statistic>
                                    </Statistic.Group>
                                }
                            </div>
                        }
                    </div>
                }
                                
                <Statistic.Group size='mini'>
                    <Statistic color='blue' inverted>
                        <Statistic.Value>{keycode.description}</Statistic.Value>
                        <Statistic.Label>Current Plan</Statistic.Label>
                    </Statistic>
                    <Statistic color='blue' inverted>
                        <Statistic.Value>{formatDate(keycode.expirationDate)}</Statistic.Value>
                        <Statistic.Label>Expiration Date</Statistic.Label>
                    </Statistic>
                    <Statistic color='blue' inverted>
                        <Statistic.Value>{licenseLimit}</Statistic.Value>
                        <Statistic.Label>License Limit</Statistic.Label>
                    </Statistic>
                    <Statistic color='blue' inverted>
                        <Statistic.Value>iOS</Statistic.Value>
                        <Statistic.Label>Platform</Statistic.Label>
                    </Statistic>
                </Statistic.Group>

                <div className={styles.blueBtn} onClick={() => action(keycode)}>
                    Upgrade License
                </div>
                { subscription.status != 'cancelled' ?
                    <a onClick={() => this.openCancelModal()}>
                        Cancel Subscription
                    </a>
                : 
                    null
                }       
                <ModalWindow className={styles.devicesModal} open={openCancelModal} onClose={this.onCloseCancelModal} center>
                    <CancelModal
                        onCancel={this.onCloseCancelModal}
                        onConfirm={()=> cancelSubscription(subscription.id, onCancelSubscription)}
                    />
                </ModalWindow>                
            </div>
        );
    }

}

export default ActivationTile;