import {
    REQUEST_RESET_PASSWORD,
    RESET_PASSWORD,
    LOGIN,
    LOGOUT,
    CREATE_NEW_USER,
    CHANGE_PASSWORD,
    SET_TEMPORARY_TOKEN,
    UPDATE_USER,
    OPEN_LOGIN, GET_SELF,
} from '../constants/authentification';

export const login = (authData, cb) => ({
    type: LOGIN,
    payload: Object.assign( {}, authData, { requestMethod:'POST', requestPath: 'api/Customers/login?include=USER' }, {callback: cb})
});

export const getSelf = (cb) => ({
    type: GET_SELF,
    payload: Object.assign( {}, { requestMethod:'GET', requestPath: 'api/Customers/self' }, {callback: cb})
})

export const logout = () => ({
    type: LOGOUT,
    payload: { requestMethod:'POST', requestPath: 'api/Customers/logout' }
});


export const requestResetPwd = (email, cb) => console.log(email) || ({
    type: REQUEST_RESET_PASSWORD,
    payload: Object.assign( {}, email, { requestMethod:'POST', requestPath: 'api/Customers/reset' }, {callback: cb})
});

export const createUser = (userData, cb) => ({
    type: CREATE_NEW_USER,
    payload: Object.assign( {}, userData, { requestMethod:'POST', requestPath: 'api/Customers' }, {callback: cb})
});

export const changePassword = (passwords, cb) => ({
    type: CHANGE_PASSWORD,
    payload: Object.assign({}, passwords, {requestMethod: 'POST', requestPath: 'api/Customers/change-password'}, {callback: cb})
});

export const setTemporaryToken = token => ({
    type: SET_TEMPORARY_TOKEN,
    payload: token,
})

export const resetPassword = password => ({
    type: RESET_PASSWORD,
    payload: Object.assign({}, password, {requestMethod: 'POST', requestPath: 'api/Customers/reset-password' })
})

export const updateUserInfo = (userData, id, cb) => ({
    type: UPDATE_USER,
    payload: Object.assign({}, userData, {requestMethod: 'PUT', requestPath: 'api/Customers/'+id }, {callback:cb})
})

export const openLogin = () => ({
    type: OPEN_LOGIN
})
