import React, { Component } from 'react';
import cx from 'classnames';
import { PaginationButton, Icon } from 'react-materialize';

class Pagination extends Component {
  constructor(props) {
    super(props);
    const { activePage, items } = props;

    this.state = {
      activePage: activePage > 0 && activePage <= items ? activePage : 1
    };

    this.renderButtons = this.renderButtons.bind(this);
    this._onClick = this._onClick.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.activePage !== this.props.activePage) {
      this.setState({ activePage: nextProps.activePage });
    }
  }

  _onClick(i) {
    const { maxButtons, onSelect } = this.props;

    return () => {
      if (i > 0 && i <= maxButtons) {
        if (onSelect) {
          onSelect(i);
        }
        this.setState({ activePage: i });
      }
    };
  }

  renderButtons() {
    const { items, maxButtons } = this.props;
    const { activePage } = this.state;

    const buttonsCount = maxButtons;

    const maxPos = items - buttonsCount;
    const halfButtons = parseInt(buttonsCount / 2, 10);

    let hiddenPagesBefore = activePage - halfButtons;
    if (hiddenPagesBefore > maxPos) {
      hiddenPagesBefore = maxPos + 1;
    }

    let from = Math.max(hiddenPagesBefore, 1);
    let to = items === 1 ? from + maxButtons - 1 : Math.min(items, from + maxButtons - 1);

    const buttons = [
      <PaginationButton
        disabled={activePage === 1}
        key={'pagination-0'}
        onSelect={this._onClick(activePage - 1)}
      >
        <Icon>chevron_left</Icon>
      </PaginationButton>
    ];

    for (let i = from; i <= to; i++) {
      buttons.push(
        <PaginationButton
          active={i === activePage}
          key={`pagination-${i}`}
          onSelect={this._onClick(i)}
        >
          {i}
        </PaginationButton>
      );
    }

    buttons.push(
      <PaginationButton
        key={`pagination-${maxButtons + 1}`}
        disabled={activePage === maxButtons}
        onSelect={this._onClick(activePage + 1)}
      >
        <Icon>chevron_right</Icon>
      </PaginationButton>
    );

    return buttons;
  }

  render() {
    return (
      <ul className={cx('pagination', this.props.className)}>
        {this.renderButtons()}
      </ul>
    );
  }
}


Pagination.defaultProps = {
  activePage: 1,
  items: 10
};

export default Pagination;