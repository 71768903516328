import React, { Component } from 'react';
import { connect } from 'react-redux';
import styles from './PlanPanel.module.scss';
import { Icon } from 'react-materialize';

class PlanPanel extends Component {

    render() {
        const { assignedKeycodes, isPartner, isAdmin, action, plan } = this.props
        const { title, description, price, items, id } = this.props.plan
        const isDisabled = !isAdmin; // price === 0 && !isPartner && assignedKeycodes.length > 0;
        const isPrice = false;
        const isUpgrade = !isPartner && assignedKeycodes.length > 0;

        return (
            <div className={'col-lg-4 col-md-6 col-sm-12 ' + styles.flexbox}>
                <div className={styles.container}>
                    <div className={styles.header}>
                        <div className={styles.title}>{title}</div>
                        <div className={styles.description}>{description}</div>
                        <div>
                            {price !== 0 ?                                
                                <span className={styles.price}>
                                    {isPrice ? `${price}` : ''}                                    
                                </span> 
                            : 
                                <span className={styles.price}>FREE</span>
                            }
                        </div>
                        <button disabled={isDisabled}
                                onClick={() => action(plan)}>
                                {isDisabled ? 'CONTACT SALES' : isUpgrade ? 'UPGRADE PLAN' : 'SELECT PLAN' }
                        </button>
                    </div>
                    <hr />
                    <div className={styles.footer}>
                        {items.map(item => <div key={item}>
                            <Icon className={styles.icon}>check</Icon>
                            <span>{item}</span>
                        </div>)}
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const assignedKeycodes = state.dashboard.assignedKeycodes;
    const {partner: isPartner, admin: isAdmin} = state.authentification.fullUserInfo.user;
    console.log({isAdmin});
    return {
        assignedKeycodes,
        isPartner,
        isAdmin
    };
};

export default connect(mapStateToProps)(PlanPanel);

