import { combineReducers } from 'redux';
import usersManage from './usersManage';
import manageModalWindow from './manageModalWindow';
import keycodesManage from './keycodesManage';
import authentification from './authentification';
import manageInfoModal from './manageInfoModal';
import dashboard from './dashboard';
import billing from './billing';
import accountSettings from './accountSettings';
import devicesManage from './devicesManage';
import download from  './download';

export default combineReducers({
    accountSettings,
    authentification,
    billing,
    dashboard,
    devicesManage,
    download,
    keycodesManage,
    manageInfoModal,
    manageModalWindow,
    usersManage
})