import React, { Component } from "react";
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Icon } from 'semantic-ui-react'
import { setTemporaryToken, resetPassword } from '../../store/AC/authentification';
import { Input } from 'react-materialize';
import styles from './ResetPassword.module.scss';
import stylesLogIn from '../LogIn/LogIn.module.scss'
import logo from '../../img/logo.png'

class ResetPassword extends Component {

    constructor(props) {
      super(props);
      this.state = {
        newPassword: '',
        newPasswordError: false,
        pwdSecond: '',
        pwdSecondError: false,
        dontMatchError: false
      };
        
      this.handleChangePwd = this.handleChangePwd.bind(this);
      this.handleChangePwdSecond = this.handleChangePwdSecond.bind(this);
    }
    handleChangePwd(e) {
        this.setState({dontMatchError: false});
        if (e.target.value !== '') {
          this.setState({newPasswordError: false});
        } else {
          this.setState({newPasswordError: true})          
        }

        this.setState({
            newPassword: e.target.value
        })
    }
    handleChangePwdSecond(e) {
      this.setState({dontMatchError: false});
        if (e.target.value !== '') {
          this.setState({pwdSecondError: false});
        } else {
          this.setState({pwdSecondError: true});
        }
        this.setState({
            pwdSecond: e.target.value
        })
    }
    resetPwd = (e) => {
        e.preventDefault();
        const { newPassword, pwdSecond } = this.state;
        const { newPasswordError, pwdSecondError } = this.state;
        const { resetPassword, history } = this.props;
        if(newPassword !== pwdSecond) {
            this.setState({ 
              dontMatchError: true,
              newPasswordError: true,
              pwdSecondError: true
            });
            return;  
        } 
        if (!newPasswordError && !pwdSecondError && newPassword.length * pwdSecond.length !== 0) {
          resetPassword({newPassword});
          history.push('/');
        } else {
          this.setState({
            newPasswordError: true,
            pwdSecondError: true
          })
        }        
    }
    componentDidMount(){
        const { history:{ location }, setTemporaryToken, } = this.props;        
        let pathNameArray = location.pathname.split('/');
        let tempToken = pathNameArray[pathNameArray.length-1];
        setTemporaryToken(tempToken);
    }
  render() {
    const { newPasswordError, pwdSecondError, dontMatchError } = this.state;
    return (
      <div className={stylesLogIn.flex}>
        <div className={stylesLogIn.logInWrapper}>

          <div className={stylesLogIn.logo}>
            <img src={logo}></img>
          </div>

          <h1 className={stylesLogIn.center}>
              New Password
          </h1>
        <form onSubmit={this.handleSubmitForm}>
            <div className={stylesLogIn.center}>
              <div className={styles.centered}>
                {dontMatchError ? 
                  <div className="message error">
                    <Icon name="warning circle"></Icon>
                    <p>Passwords do not match</p>
                  </div> : null
                }
              </div>
              <div>
                <Input s={12} type="password" 
                  label="Password" 
                  onChange={this.handleChangePwd} 
                  className={newPasswordError ? 'inputError' : null}
                />
                
                <Input s={12} type="password" 
                  label="Repeat password" 
                  onChange={this.handleChangePwdSecond} 
                  className={pwdSecondError ? 'inputError' : null} 
                />              
              </div>              
            </div>

            <div className='col-lg-12'>
              <div>
                <button type="submit"
                  className={stylesLogIn.button + ' ' + styles.button + ' btn'}
                  onClick={this.resetPwd}>Change</button>
              </div>      
            </div>
          </form>
        </div>
      </div>

        
      );
    }
}

// const mapStateToProps = (state) => {
//   const { openModal, modalTitle, modalDescription, } = state.manageModalWindow;
//   return {
//     openModal, modalTitle, modalDescription,
//   };
// };

const mapDispatchToProps = dispatch => ({
  setTemporaryToken: token => dispatch(setTemporaryToken(token)),
  resetPassword: password => dispatch(resetPassword(password)),
});

export default connect(null, mapDispatchToProps)(ResetPassword);
// export default ResetPassword;