import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Input } from 'react-materialize';
import { Link } from 'react-router-dom';
import { Icon } from 'semantic-ui-react'
import { emailValidator } from '../../utils/validators';
import { login, requestResetPwd } from '../../store/AC/authentification'
import styles from './LogIn.module.scss'
import logo from '../../img/logo.png'

class LogIn extends Component {

    constructor(props) {
        super(props);
        this.state = {
            email: '',
            password: '',
            forgotPwd: false,
            showErrorLogin: false,
            showMsgErrorLogin: '',
            showErrorEmailFormat: false,
            showErrorPassword: false,
            requestResetSuccess: false,
            requestResetFail: false

        };

        this.handleChangeLogin = this.handleChangeLogin.bind(this);
        this.handleSubmitPassword = this.handleSubmitPassword.bind(this);
    }

    handleChangeLogin(e) {
        this.setState({
            email: e.target.value,
            showErrorEmailFormat: false
        });
    }

    handleSubmitPassword(e) {
        this.setState({
            password: e.target.value,
            showErrorPassword: false
        });
    }

    handleSubmitForm = e => {
        e.preventDefault();
        if (this.state.forgotPwd) {
            this.sendNewPwd();
        } else {
            this.login();
        }
    }

    login = () => {
        const state = {
            showErrorLogin: false,
            showMsgErrorLogin: ''
        };
        this.setState(state);
        const { email, password } = this.state;

        if (!emailValidator(email)) {
            this.setState({ showErrorEmailFormat: true })
        }

        if (!password || password === '') {
            this.setState({ showErrorPassword: true });
        }

        if (emailValidator(email) && password && password !== '') {
            const reqData = { email, password };
            this.props.login(reqData, this.handleLoginResponse);
        }

    }
    resetPwd = () => {
        this.setState({
            showErrorEmailFormat: false,
            showErrorPassword: false,
            requestResetSuccess: false,
            requestResetFail: false,
            forgotPwd: !this.state.forgotPwd
        })
    }

    sendNewPwd = () => {
        const { email } = this.state;
        this.setState({
            showErrorEmailFormat: false,
            requestResetSuccess: false,
            requestResetFail: false
        });

        if (!emailValidator(email)) {
            this.setState({ showErrorEmailFormat: true});
        } else {
            this.props.requestResetPwd({ email }, this.handleRequestResetPwdResponse)
        }

    }

    handleLoginResponse = error => {
        if (error && error.response) {
            if (error.response.status === 401) {
                this.setState({ showErrorLogin: true });
            } else if (error.response.status === 500) {
                const message = error.response.data.error.message;
                this.setState({ showMsgErrorLogin: message });
            }
        }
    }

    handleRequestResetPwdResponse = response => {
        if(response.status === 204) {
            this.setState({ requestResetSuccess: true });
        } else if (response.response.status === 404) {
            this.setState({ requestResetFail: true });
        }
    }

    render() {
        const { forgotPwd, showErrorLogin, showMsgErrorLogin, showErrorEmailFormat,
                showErrorPassword,requestResetSuccess, requestResetFail} = this.state;

        const { newUserCreated, resetSuccess, resetFail } = this.props;

        return (
        <div className={styles.flex}>
            <div className={styles.logInWrapper}>

                <div className={styles.logo}>
                    <img src={logo}></img>
                </div>

                <h1 className={styles.center}>
                    {forgotPwd ? 'Recover Password' : 'Sign In'}
                </h1>
                <form onSubmit={this.handleSubmitForm}>
                <div className={styles.center}>
                    <div>
                        {!forgotPwd && showErrorLogin ?
                            <div className="message error">
                                <Icon name="warning circle"></Icon>
                                <p>User or Password are incorrect, please try again</p>
                            </div> : null
                        }
                        {!forgotPwd && showMsgErrorLogin !== '' ?
                            <div className="message error">
                                <Icon name="warning circle"></Icon>
                                <p>{showMsgErrorLogin}</p>
                            </div> : null
                        }
                        {!forgotPwd && resetSuccess ?
                            <span className="message success">
                                <Icon name="check circle"></Icon>
                                <p>Your password has been updated. Please, log in using your new credentials.</p>
                            </span>
                            : null
                        }
                        {!forgotPwd && resetFail ?
                            <span className="message error">
                                <Icon name="check circle"></Icon>
                                <p>There was an error when updating your password. Please, try again.</p>
                            </span>
                            : null
                        }
                        {forgotPwd && requestResetFail ?
                            <span className="message error">
                                <Icon name="warning circle"></Icon>
                                <p>There is no account associated with this email.</p>
                            </span>
                            : null
                        }
                        {forgotPwd && requestResetSuccess ?
                            <span className="message success">
                                <Icon name="check circle"></Icon>
                                <p>Message with recovery instructions was sent to your email. Please, check and follow the instructions.</p>
                            </span>
                            : null
                        }
                        { newUserCreated ?
                            <span className="message success">
                                <Icon name="check circle"></Icon>
                                <p>Thank you for creating an account. Please check your email to retrieve your temporary password.</p>
                            </span>
                            :null
                        }
                        <div className={styles.loginInput}>
                            <Input s={12} label="Login" onChange={this.handleChangeLogin} className={showErrorEmailFormat ? ' inputError' : null}/>
                        </div>

                    </div>
                    {forgotPwd ? null :
                        <div>
                            <Input s={12} label="Password"
                                type="password"
                                onChange={this.handleSubmitPassword}
                                className={showErrorPassword ? 'inputError' : null}
                             />
                        </div>
                    }
                </div>

                <div className='col-lg-12'>
                    <div>
                        {forgotPwd ?
                            <button type="submit"
                                className={styles.button + ' btn'}
                                onClick={this.sendNewPwd}>Send</button>
                            :
                            <button type ="submit"
                                className={styles.button + ' btn'}
                                onClick={this.login}>Log in</button>
                        }
                    </div>
                    <span onClick={this.resetPwd} className={styles.forgot}>
                        {forgotPwd ? 'Sign In' : 'Forgot password?'}
                    </span>
                    <span className={styles.center}>
                        Need an Account? Please <Link to="/register">Register</Link>
                    </span>
                </div>
                </form>
            </div>
        </div>
        );
    }
}

const mapStateToProps = state => {
    const { newUserCreated, resetSuccess, resetFail } = state.authentification;
    return {
        newUserCreated,
        resetSuccess,
        resetFail
    }
}

const mapDispatchToProps = dispatch => ({
    login: (authData, cb) => dispatch(login(authData, cb)),
    requestResetPwd: (email, cb) => dispatch(requestResetPwd(email, cb))
});

export default connect(mapStateToProps, mapDispatchToProps)(LogIn);
