import React, { Component } from 'react';
import styles from '../Documentation.module.scss';
import img1 from '../../../img/documentation/InteractiveKiosk_1.png'
import img2 from '../../../img/documentation/InteractiveKiosk_2.png'
import img3 from '../../../img/documentation/InteractiveKiosk_3.png'
import img4 from '../../../img/documentation/InteractiveKiosk_4.png'
import img5 from '../../../img/documentation/InteractiveKiosk_5.png'
import img6 from '../../../img/documentation/InteractiveKiosk_6.png'
import img7 from '../../../img/documentation/InteractiveKiosk_7.png'
import img8 from '../../../img/documentation/InteractiveKiosk_8.png'
import img9 from '../../../img/documentation/InteractiveKiosk_9.png'
import img10 from '../../../img/documentation/InteractiveKiosk_10.png'
import img11 from '../../../img/documentation/InteractiveKiosk_11.png'
import img12 from '../../../img/documentation/InteractiveKiosk_12.png'
import img13 from '../../../img/documentation/InteractiveKiosk_13.png'

class InteractiveKiosk extends Component {
    componentDidMount() {
        this.props.activeIndex('InteractiveKiosk');
    }

    render() {
        return (
            <div>
                <h1 className={styles.docTitle}>Getting Started With Interactive Kiosk</h1>
                <p>
                    Welcome to the Aila Interactive Kiosk setup guide. Your Interactive Kiosk works with Apple iPad 
                    devices to enable a range of scanning functionalities.
                </p>
                <ul className={styles.list}>
                    <li>Interactive Kiosk Setup Video</li>
                    <li>Setting up your iPad</li>
                    <li>Assembling the Interactive Kiosk</li>
                </ul>
                <h2>Interactive Kiosk Setup Video</h2>
                <figure>
                    <iframe src="https://www.youtube.com/embed/GaxuObvpm-k" frameborder="0" 
                            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen>
                    </iframe>
                </figure>
                <h2>Setting up your iPad</h2>
                <p>To set up your iPad for first-time use, refer to the Apple iPad Setup Guide.</p>
                <h2>Assembling your Interactive Kiosk</h2>
                <p>The Interactive Kiosk ships fully assembled. To install your iPad into the Interactive Kiosk, follow these steps:</p>
                <h3>1st Step</h3>
                <figure>
                    <img src={img1}></img>
                    <figcaption>
                        Remove the Interactive Kiosk from the box. Attached to the unit, you will find a plastic bag 
                        containing kiosk keys and a key fob.
                    </figcaption>
                </figure>
                <h3>2nd Step</h3>
                <figure>
                    <img src={img2}></img>
                    <figcaption>
                        Remove the Interactive Kiosk from the box. Attached to the unit, you will find a plastic 
                        bag containing kiosk keys and a key fob.
                    </figcaption>
                </figure>
                <h3>3rd Step</h3>
                <figure>
                    <img src={img3}></img>
                    <figcaption>
                        Remove the Interactive Kiosk from the box. Attached to the unit, you will find a plastic 
                        bag containing kiosk keys and a key fob.
                    </figcaption>
                </figure>
                <h3>4th Step</h3>
                <figure>
                    <img src={img4}></img>
                    <figcaption>
                        Remove the Interactive Kiosk from the box. Attached to the unit, you will find a plastic 
                        bag containing kiosk keys and a key fob.
                    </figcaption>
                </figure>
                <h3>5th Step</h3>
                <figure>
                    <img src={img5}></img>
                    <figcaption>
                        Remove the Interactive Kiosk from the box. Attached to the unit, you will find a plastic 
                        bag containing kiosk keys and a key fob.
                    </figcaption>
                </figure>
                <h3>6th Step</h3>
                <figure>
                    <img src={img6}></img>
                    <figcaption>
                        Remove the Interactive Kiosk from the box. Attached to the unit, you will find a plastic 
                        bag containing kiosk keys and a key fob.
                    </figcaption>
                </figure>
                <h3>7th Step</h3>
                <figure>
                    <img src={img7}></img>
                    <figcaption>
                        Remove the Interactive Kiosk from the box. Attached to the unit, you will find a plastic 
                        bag containing kiosk keys and a key fob.
                    </figcaption>
                </figure>
                <h3>8th Step</h3>
                <figure>
                    <img src={img8}></img>
                    <figcaption>
                        Remove the Interactive Kiosk from the box. Attached to the unit, you will find a plastic 
                        bag containing kiosk keys and a key fob.
                    </figcaption>
                </figure>
                <h3>9th Step</h3>
                <figure>
                    <img src={img9}></img>
                    <figcaption>
                        Remove the Interactive Kiosk from the box. Attached to the unit, you will find a plastic 
                        bag containing kiosk keys and a key fob.
                    </figcaption>
                </figure>
                <h3>10th Step</h3>
                <figure>
                    <img src={img10}></img>
                    <figcaption>
                        Remove the Interactive Kiosk from the box. Attached to the unit, you will find a plastic 
                        bag containing kiosk keys and a key fob.
                    </figcaption>
                </figure>
                <h3>11th Step</h3>
                <figure>
                    <img src={img11}></img>
                    <figcaption>
                        Remove the Interactive Kiosk from the box. Attached to the unit, you will find a plastic 
                        bag containing kiosk keys and a key fob.
                    </figcaption>
                </figure>
                <h3>12th Step</h3>
                <figure>
                    <img src={img12}></img>
                    <figcaption>
                        Remove the Interactive Kiosk from the box. Attached to the unit, you will find a plastic 
                        bag containing kiosk keys and a key fob.
                    </figcaption>
                </figure>
                <h3>13th Step</h3>
                <figure>
                    <img src={img13}></img>
                    <figcaption>
                        Remove the Interactive Kiosk from the box. Attached to the unit, you will find a plastic 
                        bag containing kiosk keys and a key fob.
                        <p>
                            <br></br>
                            <b>Note: The 9.7-inch iPad does not come with a power block; the 12.9-inch iPad does.</b>
                        </p>
                    </figcaption>
                </figure>
                <h2>Questions?</h2>
                <p>
                    Contact us at <a href="malito:support@ailatech.com">support@ailatech.com</a> 
                </p>
            </div>
        );
    }
}

export default InteractiveKiosk;