import { SUCCESS, FAIL } from '../constants/main';
import { 
    BILLING, 
    BILLING_PLANS,
    BILLING_PAYMENT_METHODS, 
    BILLING_CLEAN_PAYMENT_METHODS, 
    BILLING_CANCEL,
    BILLING_CALCULATE_COUPON,
    BILLING_CLEAR_CALCULATE_COUPON
} from '../constants/billing';

let initialState = {
    charged: null,
    plans: null,
    paymentMethods: null,
    coupon: {
        calculated: null,
        error: false
    }
};

export default function billing(state = initialState, action) {
    const { payload, type } = action;
    switch (type) {
        case BILLING + SUCCESS:
            return {
                ...state,
                charged: payload
            }
        case BILLING + FAIL:
            return {
                ...state,
                charged: null
            }
        case BILLING_PLANS + SUCCESS:           
            return {
                ...state,
                plans: payload
            } 
        case BILLING_PLANS + FAIL:           
            return {
                ...state,
                currentPlan: null
            }     
        case BILLING_PAYMENT_METHODS + SUCCESS:
            return {
                ...state,
                paymentMethods: payload
            }
        case BILLING_PAYMENT_METHODS + FAIL: 
            return {
                ...state,
                paymentMethods: null
            }
        case BILLING_CLEAN_PAYMENT_METHODS:
            return {
                ...state,
                paymentMethods: payload
            }   
        case BILLING_CANCEL + SUCCESS:             
            return {
                ...state
            }
        case BILLING_CANCEL + FAIL:        
            return {
                ...state
            }
        case BILLING_CALCULATE_COUPON + SUCCESS:
            return {
                ...state,
                coupon: {
                    calculated: payload,
                    error: false
                }
            }
        case BILLING_CALCULATE_COUPON + FAIL:
            return {
                ...state,
                coupon: {
                    calculated: null,
                    error: true
                }
            }
        case BILLING_CLEAR_CALCULATE_COUPON:
            return {
                ...state,
                coupon: {
                    calculated: null,
                    error: false
                }
            }
        default:
            break;
    }
    return {
        ...state,
}
}