import React, { Component } from 'react';
import { connect } from 'react-redux';
import styles from './EditKeycode.module.scss';
import DatePicker from "react-datepicker";
import { Input } from 'react-materialize';
import { Select, Icon } from 'semantic-ui-react'
import Autosuggest from 'react-autosuggest';
import "react-datepicker/dist/react-datepicker.css";
import 'react-datepicker/dist/react-datepicker-cssmodules.css';
import { generateKeycode } from '../../utils/keycode';
import IsolatedScroll from 'react-isolated-scroll';
import ModalWindow from '../../common/ModalWindow/ModalWindow';
import { emailValidator, fieldNotEmpty } from '../../utils/validators';
import { getCurrentPlans } from '../../store/AC/billing';
import cn from 'classnames'

function renderSuggestionsContainer({ containerProps, children }) {
    const { ref, ...restContainerProps } = containerProps;
    const callRef = isolatedScroll => {
        if (isolatedScroll !== null) {
            ref(isolatedScroll.component);
        }
    };

    return (
        <IsolatedScroll ref={callRef} {...restContainerProps}>
            {children}
        </IsolatedScroll>
    );
}

const getSuggestionValue = suggestion => suggestion.email;
const renderSuggestion = suggestion => (
    <div>
        {suggestion.email}
    </div>
);

const alwaysTrue = () => true;

class EditKeycode extends Component {

    constructor(props) {
        super(props);
        const { keycodeData, getCurrentPlans } = this.props;
        const { code, startDate, expirationDate, customer, description } = keycodeData ? keycodeData : {};

        this.state = {
            open: true,
            form: {
                customerId: customer ? customer.email : '',
                customerIdError: false,
                code: code ? code : generateKeycode(),
                codeError: false,
                startDate: startDate ? startDate : new Date(),
                expirationDate: expirationDate ? expirationDate : new Date(),
                expirationDateError: false,                
                description: description ? description : '',
                descriptionError: false
            },
            suggestions: this.props.users
        };

        getCurrentPlans();
    }

    save = (e) => {
        e.preventDefault();
        const { customerId, customerIdError, code, codeError, startDate, expirationDate,
            expirationDateError, description, descriptionError } = this.state.form;
                
        if (!customerIdError && !codeError && !expirationDateError && !descriptionError 
            && customerId.length * code.length * description.length > 0) {            
            const userId = this.getCustomerId(customerId);
            const data = { 
                customerId: userId, 
                code, 
                startDate, expirationDate, 
                description: description.charAt(0).toUpperCase() + description.slice(1) 
            };                    
            this.props.saveBtn(data);
        } else {
            this.setState({
                ...this.state,
                form: {
                    ...this.state.form,
                    customerIdError: !emailValidator(customerId),                
                    codeError: !fieldNotEmpty(code),
                    descriptionError: !fieldNotEmpty(description)
                }                
            });
        }
    }

    handleChange = (e) => {
        const { target } = e;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        
        this.setState({
            ...this.state,
            form: {
                ...this.state.form,
                [e.target.name]: value
            }
        });
    }

    handleChangeCode = (e) => {
        const { value } = e.target;
        this.setState({
            ...this.state,
            form: {
                ...this.state.form,
                code: value,
                codeError: !fieldNotEmpty(value)
            }
        });
    }

    handleChangeDate = (name, date) => {
        const { startDate, expirationDate } = this.state.form;
        let expirationDateError = false;

        if ((name === "expirationDate" && date < startDate) 
            || (name === "startDate" && expirationDate < date)){        
            expirationDateError = true;            
        }
        
        this.setState({
            ...this.state,
            form: {
                ...this.state.form,
                [name]: date,
                expirationDateError: expirationDateError
            }
        });
    }

    getCustomerId = (value) => {
        let id;        
        this.props.users.forEach(element => {
            if (element.email === value) {
                id = element.id;                
                return id;
            }
        });
        
        return id;
    }

    getSuggestions = value => {
        const inputValue = value.trim().toLowerCase();
        const inputLength = inputValue.length;

        if (inputValue === '') {
            return this.props.users;
        }

        return inputLength === 0 ? [] : this.props.users.filter(lang =>
            lang.email.toLowerCase().slice(0, inputLength) === inputValue
        );
    };

    onSuggestionsFetchRequested = ({ value }) => {
        this.setState({
            suggestions: this.getSuggestions(value)
        });
    };

    onSuggestionsClearRequested = () => {
        this.setState({
            suggestions: []
        });
    };

    handleChangeUserEmail = (event, { newValue }) => {
        this.setState({
            form: {
                ...this.state.form,
                customerId: newValue,
                customerIdError: !emailValidator(newValue)
            }
        })
    }

    handleChangePlan = (e, { value }) => {
        this.setState({
            form: {
                ...this.state.form,
                description: value,
                descriptionError: !fieldNotEmpty(value)
            }
        })
    }

    storeInputReference = autosuggest => {
        if (autosuggest !== null) {
            this.input = autosuggest.input;
        }
    };

    onClose = () => {
        const { onClose } = this.props;
        this.props.editKeycodeById(null);
        if (onClose) {
            onClose();
        }
        this.setState({ open: false });
    }


    render() {
        const { errorMessage, keycodeData, plans } = this.props;
        const { open } = this.state;
        const { customerId, customerIdError, code, codeError, startDate, 
                expirationDate, expirationDateError, description, descriptionError } = this.state.form;

        const inputProps = {
            placeholder: 'User email',
            value: customerId,
            onChange: this.handleChangeUserEmail
        };

        const classDatePickerDashed = expirationDateError ? styles.dashed : null;
                
        const planOptions = plans ? plans.map(plan => ({                                    
                key: plan.id,
                text: plan.title,
                value: plan.id,
        })) : null          
        
        return (
            <ModalWindow open={open} onClose={this.onClose} center>
                <div className={cn(styles.title, "big-size-font")}>
                    {!keycodeData ?
                        'New Keycode'
                    :
                        'Edit Keycode'
                    }
                </div>                                        
                <div className={styles.editKeycodeBlock}>
                    {errorMessage ? 
                        <div className="message error">
                            <Icon name="warning circle"></Icon>
                            <p>{errorMessage}</p>
                        </div>              
                    : null }  
                    <div className={styles.dataInput}>
                        <p className={styles.label}>USER EMAIL</p>
                        <div className={customerIdError ? styles.dashed : null}>
                            <Autosuggest
                                suggestions={this.state.suggestions}
                                onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                                onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                                getSuggestionValue={getSuggestionValue}
                                renderSuggestion={renderSuggestion}
                                inputProps={inputProps}
                                shouldRenderSuggestions={alwaysTrue}
                                renderSuggestionsContainer={renderSuggestionsContainer}
                            />
                        </div>
                    </div>
                    <div className={codeError ? styles.dashed : null}>
                        <Input name="code" onChange={this.handleChangeCode} label="Keycode" defaultValue={code} />
                    </div>
                    <div className={cn(styles.datePickerContainer, classDatePickerDashed) }>
                        <div className={styles.datePicker}>
                            <p className={styles.label}>Start Date</p>
                            <DatePicker
                                selected={new Date(startDate)}                                
                                onChange={(date) => this.handleChangeDate('startDate', date)}
                            />
                        </div>
                        <div className={styles.datePicker}>
                            <p className={styles.label}>End Date</p>
                            <DatePicker
                                selected={new Date(expirationDate)}
                                className={expirationDateError ? styles.dashed : null}
                                onChange={(date) => this.handleChangeDate('expirationDate', date)}
                            />
                        </div>
                    </div>
                    <div className={styles.dataInput}>                    
                        <p className={styles.label}>PLAN</p>
                        <div className={descriptionError ? styles.dashedPlan : styles.selectPlan}>
                            <Select placeholder="Select a Plan" options={planOptions} onChange={this.handleChangePlan} value={description.toLowerCase()}/>                                                    
                        </div>                        
                    </div>                    
                    <div className={styles.saveButton} onClick={this.save}>Save</div>
                </div>
            </ModalWindow>
        );
    }
}

const mapStateToProps = (state) => {
    const { plans } = state.billing;    
    return {
        plans
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getCurrentPlans: () => { dispatch(getCurrentPlans()) }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditKeycode);
