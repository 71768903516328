import { 
        BILLING_CUSTOMER, 
        BILLING_INVOICE, 
        BILLING_CHARGED, 
        BILLING_PLANS,
        BILLING_PAYMENT_METHODS,
        BILLING_CLEAN_PAYMENT_METHODS,
        BILLING_CANCEL,
        BILLING_CALCULATE_COUPON,
        BILLING_CLEAR_CALCULATE_COUPON
    } from "../constants/billing";

export const setBillingCharged = (charged) => ({ type: BILLING_CHARGED, payload: charged });
export const setBillingCustomer = (customer) => ({ type: BILLING_CUSTOMER, payload: customer });
export const setBillingInvoice = (invoice) => ({ type: BILLING_INVOICE, payload: invoice });

export const getCurrentPlans = () => ({
    type: BILLING_PLANS,
    payload: Object.assign({}, {requestMethod: 'GET', requestPath: 'api/Subscriptions/plans' })
});

export const getPaymentMethods = (cb) => ({
    type: BILLING_PAYMENT_METHODS,
    payload: Object.assign({}, {requestMethod: 'GET', requestPath: 'api/Subscriptions/payment/methods' }, { callback: cb })
});

export const cleanPaymentMethods = (cb) => ({
    type: BILLING_CLEAN_PAYMENT_METHODS,
    payload: null
})

export const cancelSubscription = (id, cb) => ({
    type: BILLING_CANCEL,
    payload: Object.assign({}, {requestMethod:'DELETE', requestPath: `api/Subscriptions/${id}/cancel`}, { callback: cb })
})

export const calculateCoupon = (purchase, cb) => ({
    type: BILLING_CALCULATE_COUPON,
    payload: Object.assign({}, purchase, {requestMethod: 'POST', requestPath: 'api/Subscriptions/calculate-coupon' }, { callback: cb })
})

export const clearCalculateCoupon = () => ({
    type: BILLING_CLEAR_CALCULATE_COUPON,
    payload: {}  
})