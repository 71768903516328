import React, { Component } from 'react';
import { connect, } from 'react-redux';
import styles from './ManageKeycodes.module.scss';
import ModalWindow from '../../common/ModalWindow/ModalWindow';
import ManageDevices from '../../containers/ManageDevices/ManageDevices';
import KeycodeItem from '../../blocks/KeycodeItem/KeycodeItem';
import EditKeycode from '../../blocks/EditKeycode/EditKeycode';
import { editKeycodeById, getAllKeycodes, addNewKeycode, saveEditKeycode, setDeleteKeycodeId, } from '../../store/AC/keycodesManage' ;
import { showModalWindow, } from '../../store/AC/manageModalWindow';
import { getAllUsers } from '../../store/AC/usersManage';
import { ADMIN } from '../../store/constants/main';

const excludeAdmin = (users) => {
    return users.filter(item => {
        let result = true;
        item.roles && item.roles.forEach(element => {
            if(element.name === ADMIN) {
                result = false;
                return;
            }
        })
        return result;
    });
}

class ManageKeycodes extends Component {
    constructor(props) {
        super(props);
        this.props.getAllKeycodes();
        this.props.getAllUsers();
        this.state = {
            openDevicesModal: false,
            keycodeIdForModal: null
        };
    }
    
    openDeviceModal = () => {
        this.setState({
            openDevicesModal: true
        }); 
    }
    
    onCloseDevicesModal = () => {
        this.setState({
            openDevicesModal: false
        });
    }

    showAddKeycodeform = () => {     
        this.props.editKeycodeById('addNew');
    }
    saveNewKeycode = (data) => {
        this.props.addNewKeycode(data);
    }
    
    handleDevicesManage = (keycodeId) => {
        this.setState({
            openDevicesModal: true,
            keycodeIdForModal: keycodeId
        });
    }
    
    getKeycodes = () => {
        return this.props.keycodes.map(keycode => {
           return (<KeycodeItem 
                keycodeData={keycode} 
                key={keycode.keycodeId} 
                {...this.props}
                onClickDevices={this.handleDevicesManage}
                userByEmail={this.props.userByEmail || keycode.customer }
                deleteKeycode={this.deleteKeycode}
            />)
        }
        )
    }

    saveEditKeycode = (data) => {
        this.props.saveEditKeycode(this.props.editableKeycodeId, data);
    }

    deleteKeycode = (id) => {
        this.props.deleteKeycodeById(id);
    }
        
    render() {
        const { openDevicesModal, keycodeIdForModal} = this.state;
        const { editableKeycodeId } = this.props;
        
        return (
            <div className={styles.keycodesPage + ' row'}>                
                <ModalWindow  open={openDevicesModal} onClose={this.onCloseDevicesModal} center>
                    { keycodeIdForModal && <ManageDevices keycodeId={keycodeIdForModal} /> }
                </ModalWindow>         
                <div className={styles.keycodeContainer}>
                    <p className="big-size-font">Manage Keycodes</p>
                    { editableKeycodeId==='addNew' ? 
                        <EditKeycode  {...this.props} saveBtn={this.saveNewKeycode}/>
                    :
                        null
                    }
                    <div className={styles.editButton} onClick={this.showAddKeycodeform}> NEW KEYCODE</div>
                    <table className={styles.keycodeTable + ' table table-hover'}>
                        <tbody>
                            <tr className={styles.titles}>
                                <th>User email</th>
                                <th>Keycode</th>
                                <th>Devices</th>
                                <th>Start</th>
                                <th>Expiration</th>
                                <th>Type</th>
                                <th>Actions</th>
                            </tr>
                            {this.getKeycodes()}
                        </tbody>
                    </table>
                </div>                                
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const { editableKeycodeId, keycodes, errorMessage } = state.keycodesManage;
    const { users } = state.usersManage;
    return {
        editableKeycodeId,
        keycodes,
        users: excludeAdmin(users),
        errorMessage
    };
};
  
const mapDispatchToProps = dispatch => ({
    editKeycodeById: id => dispatch(editKeycodeById(id)),
    showModalWindow: modalData => dispatch(showModalWindow(modalData)),
    getAllKeycodes: () => dispatch(getAllKeycodes()),
    addNewKeycode: data => dispatch(addNewKeycode(data)),
    saveEditKeycode: (id, data) => dispatch(saveEditKeycode(id, data)),
    deleteKeycodeById: (id) => dispatch(setDeleteKeycodeId(id)),
    getAllUsers: () => dispatch(getAllUsers())
});
  
export default connect(mapStateToProps, mapDispatchToProps)(ManageKeycodes);