import React, { Component } from 'react';
import styles from '../Documentation.module.scss';
import { Link } from 'react-router-dom';
import Snippet from '../../Snippet/Snippet';
import img1 from '../../../img/documentation/SDKPart1_1.png'
import img2 from '../../../img/documentation/SDKPart1_2.png'

class SDKPart1 extends Component {
    componentDidMount() {
        this.props.activeIndex('SDK');
    }
    render() {
        return (
            <div>
                <h1 className={styles.docTitle}>Part 1: Integrating the Aila SDK</h1>
                <p>
                    The Aila library provides an integrated barcode scanning platform for Apple iOS devices paired (or not) with Aila cases. Aila cases provide camera optics, aiming and scan field illumination, and audible feedback, while the Aila library performs barcode scanning using the native camera and processor.
                </p>
                <h2>Integrate the Aila SDK into your app</h2>
                <p>
                    Go ahead and download the SDK from <Link to="/downloads">here</Link>. Unpack it and you’ll see a
                    file named "Aila.xcframework", that’s what we are after. Drag and drop it into your Xcode project’s
                    <b><i> Embedded Binaries</i></b> section. Make sure “Copy items if needed” is selected.
                </p>
                <figure>
                    <img src={img1}></img>
                    <figcaption>Double check that “Copy items if needed” is selected.</figcaption>
                </figure>

                <p>Your project should look like this:</p>
                <figure>
                    <img src={img2}></img>
                    <figcaption>Framework should appear both under “Embedded Binaries” and “Linked Frameworks and Libraries”</figcaption>
                </figure>
                <p>
                    Now you should be able to compile (and run) the project to check everything is in place.
                    There’s still a few things to cover though, once you confirm the project compiles.
                </p>
                <p>
                    The SDK needs <b><i>Background Modes</i></b> for Audio, AirPlay, and Picture in Picture and Background fetch
                    turned on to allow the case to drain power through the audio port. If you are not attaching a
                    case you can avoid this step but Xcode will complain on the console. <b><i>Background Modes</i></b> are
                    located under the <b><i>Capabilities tab</i></b>.
                </p>
                <p>
                    Next thing to do is to add entries for NSCameraUsageDescription and NSMicrophoneUsageDescription
                    to the project’s info.plist.
                </p>
                <figure>
                    <div>
                        {`<key>NSCameraUsageDescription</key>
                        <string>REASON_TO_USE_THE_CAMERA</string>`
                        }
                    </div>
                </figure>
                <p>
                    Finally, we need to add a bridging-header file to our project. The easiest way to create such files
                    is to add an Objective-C file to your swift project. When added, Xcode will prompt you to create a
                    bridging-header. Accept and add the following import to it. When done, remember to delete the dummy
                    Objective-C file.
                </p>
                <figure>
                    <div>
                        {`#import <Aila/Aila.h>`}
                    </div>
                </figure>
                <h2>Setup</h2>
                <p>
                   Now that we have the Xcode project ready with the framework fully integrated, it’s time to do something
                   meaningful with it.
                </p>
                <p>
                    First of all, we need to create and keep a reference to a configuration var of type AilaConfiguration.
                    With it, we’ll be able to customize things like what the SDK does when it reads a barcode or which barcodes
                    to read, among others.
                </p>
                <figure>
                    <Snippet>
                    {`
        import UIKit
 
       class ViewController: UIViewController {
                  
           /// Holds the exposed AILA SDK's configurations.
           lazy var ailaConfiguration: AilaConfiguration = {
               var config = AilaConfiguration()
                          
               // 1 - Set scanning parameters.
               // 2 - Select what type of barcodes to recognize.
               // 3 - Set the callback, code to execute when a barcode is scanned.
                          
               return config
           }()
                  
           override func viewDidLoad() {
               super.viewDidLoad()
           }
       }
`
                    }
                    </Snippet>
                    <figcaption>
                        By making “ailaConfiguration” a lazy var, we can keep it’s initialization close to it’s declaration.
                        We can’t use a simple closure because we’ll be accessing ‘self’ inside it.
                    </figcaption>
                </figure>
                <p>
                    First of all, let’s set the scanning parameters. Refer to the SDK’s documentation for an explanation on each.
                </p>
                <figure>
                    <Snippet>
                    {`
config.beepMode = .on
config.id1CardDetectionMode = .off
config.multiScanMode = .off`
                    }
                    </Snippet>
                </figure>
                <p>
                    Next we need to tell the SDK what type of barcodes it should recognize. Below we’re telling the app to recognize
                    every option available.
                </p>
                <figure>
                    <Snippet>
                    {`
config.setCode(.typeUPC, enabled: true)
config.setCode(.typeEAN, enabled: true)
config.setCode(.type128, enabled: true)
config.setCode(.typeQR, enabled: true)`
                    }
                    </Snippet>
                </figure>
                <p>
                    Finally, tell the SDK what to do when a barcode is recognized. For now, let’s just print out to the console the
                    information it’s holding.
                </p>
                <figure>
                    <Snippet>
                    {`
    config.scanCallback = { [weak self] (results: Array<AilaScanObject>?) in
            DispatchQueue.main.async {
                if let results = results {
                    for scanObject in results {
                        let data : String = scanObject.data
                        print("Scanned: \\(data)")
                    }
                }
            }
        }
`}
                    </Snippet>
                </figure>
                <p>
                    We’re almost ready to see the SDK in action, we just need to initialize the framework with the configuration we created
                    and start scanning barcodes. We will do it in <em>viewDidload()</em>
                </p>
                <figure>
                    <Snippet>
                    {`
    import UIKit

    class ViewController: UIViewController {
    
    /// Holds the exposed AILA SDK's configurations.
    lazy var ailaConfiguration: AilaConfiguration = {
        var config = AilaConfiguration()
        
        // 1 - Set scanning parameters.
        config.beepMode = .on
        config.id1CardDetectionMode = .off
        config.multiScanMode = .off
        
        // 2 - Select what type of barcodes to recognize.
        config.setCode(.typeUPC, enabled: true)
        config.setCode(.typeEAN, enabled: true)
        config.setCode(.type128, enabled: true)
        config.setCode(.typeQR, enabled: true)
        
        // 3 - Set the callback, code to execute when a barcode is scanned.
        config.scanCallback = { [weak self] (results: Array<AilaScanObject>?) in
            DispatchQueue.main.async {
                if let results = results {
                    for scanObject in results {
                        let data : String = scanObject.data
                        print("Scanned: \\(data)")
                    }
                }
            }
        }
        
        return config
    }()
    
    override func viewDidLoad() {
        super.viewDidLoad()
        Aila_Init()
        Aila_SetConfiguration(ailaConfiguration)
        Aila_Start()
    }
}`
                    }
                    </Snippet>
                    <figcaption>This is how your viewcontroller should look by now.</figcaption>
                </figure>
                <h2>What’s next?</h2>
                <p>
                    This is it for Part 1 of this tutorial: by now you should be able to run and scan multiple barcodes with the app.
                    In <Link to="/documentation/sdk-documentation2">Part 2</Link> we’re reviewing more advanced features like knowing what type of barcode we just scanned, showing the
                    camera feed on the phone’s screen, etc.
                </p>
            </div>
        );
    }
}

export default SDKPart1;
