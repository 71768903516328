import { 
    SHOW_MODAL_WINDOW,
    // CLOSE_MODAL_WINDOW,
    // CONFIRM_MODAL_ACTION,
    SHOW_INFO_MODAL,
} from '../constants/manageModalWindow';
import { DELETE_KEYCODE } from '../constants/keycodesManage';
import { deleteKeycodeById } from './keycodesManage';
import { INACTIVE_USER, DELETE_USER_SELF } from '../constants/usersManage';
import { makeInactiveUserById, deleteUserSelf } from './usersManage';
import { RELEASE_DEVICE, RELEASE_DEVICE_INIT } from '../constants/devicesManage';
import { releaseDevice } from './devicesManage';

export const showModalWindow = modalData => ({ type: SHOW_MODAL_WINDOW, payload: modalData });
// export const closeModalWindow = modalData => ({ type: CLOSE_MODAL_WINDOW });

export const releaseDeviceInit = () => ({ type: RELEASE_DEVICE_INIT, payload: {} });

export const showInformModal = modalMsg => ({ type: SHOW_INFO_MODAL, payload: modalMsg });

export const confirmActionModal = (actionName) => async (dispatch, getState) => {
    switch (actionName){
        case INACTIVE_USER:
            const { usersManage } = getState();
            const userId = usersManage.deleteUserId;
            dispatch(makeInactiveUserById(userId));
            break;
        case DELETE_KEYCODE:
            const { keycodesManage } = getState();
            const keycodeId = keycodesManage.deleteKeycodeId;
            dispatch(deleteKeycodeById(keycodeId));
            break;
        case DELETE_USER_SELF:
            dispatch(deleteUserSelf());
            break;
        case RELEASE_DEVICE:
            const { devicesManage } = getState();
            const { releaseDeviceId, releaseKeycodeId } = devicesManage;
            dispatch(releaseDevice(releaseDeviceId, releaseKeycodeId));
            break;
        default: break
    }
}