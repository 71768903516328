import { 
    GET_ALL_USERS,
    EDIT_USER_BY_ID,
    SAVE_USER,
    SAVE_EDIT_USER,
    // SAVE_EDIT_USER,
    DELETE_USER,
    DELETE_USER_ID,
    GET_USER_BY_EMAIL,
    INACTIVE_USER,
    SET_AGREEMENT_DATE,
    // RESET_USER_PASSWORD,

} from '../constants/usersManage'; 
import { SUCCESS, FAIL } from '../constants/main';
import { SAVE_KEYCODE } from '../constants/keycodesManage';

let initialState = {
    deleteUserId: null,
    editableUserId: null,
    users: [],
    userByEmail: null,
    errorMessage: null,
    SDKLicenseAgreed: false
};

export default function usersManage(state = initialState, action) {
    switch (action.type) {
        case EDIT_USER_BY_ID:
        	return{
                ...state,
                editableUserId: action.payload,
                errorMessage: null
            }
        case GET_ALL_USERS + SUCCESS:
            return {
                ...state,
                users: action.payload,
            }
        case SAVE_USER + SUCCESS:
            return {
                ...state,
                editableUserId: null,
                users: [
                    ...state.users,
                    action.payload,
                ]
            }
        case SAVE_EDIT_USER + SUCCESS:
            return {
                ...state,
                editableUserId: null,
                users: state.users.map(item => {
                    if(item.id === action.payload.id) {
                        return action.payload;
                    } else {
                        return item;
                    }
                })
            }
        case DELETE_USER_ID:
            return{
                ...state,
                deleteUserId: action.payload,
            }
        case DELETE_USER + SUCCESS:
            return {
                ...state,
                users: state.users.filter(x => x.id !== state.deleteUserId)
            }
        case GET_USER_BY_EMAIL + SUCCESS:
            return {
                ...state,
                userByEmail: action.payload[0]
            }
        case SAVE_KEYCODE + SUCCESS:
            return {
                ...state,
                userByEmail: null
            }
        case INACTIVE_USER + SUCCESS:
            return {
                ...state,
                users: state.users.filter(x => x.id !== state.deleteUserId)
            }
        case SAVE_EDIT_USER + FAIL:
        case SAVE_USER + FAIL:
            return {
                ...state,
                errorMessage: action.payload.response.data.error.message
            }
        case SET_AGREEMENT_DATE + SUCCESS:
            return {
                ...state,
                SDKLicenseAgreed: true
            }
        default: 
            break;
 	} 
    return {
        ...state, 
    }
}
