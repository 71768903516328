import {
    GET_ALL_KEYCODES,
    EDIT_KEYCODE_BY_ID,
    SAVE_KEYCODE,
    SAVE_EDIT_KEYCODE,
    DELETE_KEYCODE,
    DELETE_KEYCODE_ID,
    RELEASE_KEYCODE,
    // DELETE_KEYCODE,
    // RESET_KEYCODE_PASSWORD,
} from '../constants/keycodesManage';
import { SUCCESS, FAIL } from '../constants/main';

let initialState = {
    editableKeycodeId: null,
    keycodes: [],
    deleteKeycodeId: null,
    errorMessage: null
};

export default function keycodesManage(state = initialState, action) {
    switch (action.type) {
        case EDIT_KEYCODE_BY_ID:
        	return{
                ...state,
                editableKeycodeId: action.payload,
                errorMessage: null
            }
        case GET_ALL_KEYCODES + SUCCESS:
            return {
                ...state,
                keycodes: action.payload,
            }
        case SAVE_KEYCODE + SUCCESS:
            return {
                ...state,
                editableKeycodeId: null,
                keycodes: [
                    ...state.keycodes,
                    action.payload,
                ]
            }
        case SAVE_EDIT_KEYCODE + SUCCESS:            
            return {
                ...state,
                editableKeycodeId: null,
                keycodes: state.keycodes.map(item => {
                    if(item.keycodeId === action.payload.keycodeId) {
                        return action.payload;
                    } else {
                        return item;
                    }
                })
            }
        case DELETE_KEYCODE_ID:
            return{
                ...state,
                deleteKeycodeId: action.payload,
            }
        case DELETE_KEYCODE + SUCCESS:            
            return {
                ...state,
                keycodes: state.keycodes.filter(x => x.keycodeId !== state.deleteKeycodeId)
            }
        case SAVE_KEYCODE + FAIL:
            return {
                ...state,
                errorMessage: action.payload.response.data.error.message
            }
        case RELEASE_KEYCODE + SUCCESS: 
            return {
                ...state
            }    
        case RELEASE_KEYCODE + FAIL:
            return {
                ...state
            }            
        default: 
            break;
 	} 
    return {
        ...state, 
    }
}
