import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Input } from 'react-materialize';
import styles from './SignUp.module.scss'
import { connect } from 'react-redux';
import { createUser } from '../../store/AC/authentification'
import { emailValidator, phoneValidator, fieldNotEmpty } from '../../utils/validators';
import Phone from '../../common/Phone';
import Header from '../../blocks/Header/Header';
import logo from "../../img/logo.png";

class SignUp extends Component {

    constructor(props) {
        super(props);
        this.state = {
            firstName: '',
            errorFirstName: false,
            lastName: '',
            errorLastName: false,
            email: '',
            errorEmail: false,
            errorEmailExists: false,
            companyName: '',
            errorCompany: false,
            phone: '',
            phoneCountry: 'us',
            errorPhone: false,
            pwd: '',
            pwdConfirm: '',
        };

        this.handleChangeName = this.handleChangeName.bind(this);
        this.handleSubmitLastName = this.handleSubmitLastName.bind(this);
        this.handleChangeEmail = this.handleChangeEmail.bind(this);
        this.handleSubmitCompany = this.handleSubmitCompany.bind(this);
        this.handleChangePwd = this.handleChangePwd.bind(this);
        this.handleSubmitPwdConfirm = this.handleSubmitPwdConfirm.bind(this);
      }


    handleChangeName(e){
        this.setState({
            firstName: e.target.value,
            errorFirstName: !fieldNotEmpty(e.target.value)
        });
    }
    handleSubmitLastName(e){
        this.setState({
            lastName: e.target.value,
            errorLastName:!fieldNotEmpty(e.target.value),
        });
    }
    handleChangeEmail(e){
        this.setState({
            email: e.target.value,
            errorEmail: !emailValidator(e.target.value),
            });
    }
    handleSubmitCompany(e){
        this.setState({
            companyName: e.target.value,
            errorCompany: !fieldNotEmpty(e.target.value),
        });
    }
    handleChangePhone = (value, country) => {
        console.log(value);
        this.setState({
            phone: value,
            phoneCountry: country.countryCode,
            errorPhone: !phoneValidator(value),
        });
    }
    handleChangePwd(e){
        this.setState({ pwd: e.target.value});
    }
    handleSubmitPwdConfirm(e){
        this.setState({ pwdConfirm: e.target.value});
    }

    handleCreateUserResponse = (error) => {
        if (error && error.response) {
            switch(error.response.status) {
                case 422:
                    this.setState({errorEmail: true, errorEmailExists: true})
                    break;
            }
        }
    }

    createNewUser = () => {
        const { createUser, } = this.props;
        const { firstName, lastName, email, companyName, phone, errorFirstName,
            errorLastName,
            errorEmail,
            errorPhone,
            errorCompany, } = this.state;
        if(!errorFirstName && !errorLastName && !errorEmail && !errorPhone && !errorCompany &&
            firstName.length*lastName.length*email.length*companyName.length*phone.length*companyName.length>0){
            createUser({
                firstName, lastName, companyName, email,
                partner: false,
                active: true,
                phone: phone
            },
            this.handleCreateUserResponse
            );
        } else {
            this.setState({
                errorFirstName: !fieldNotEmpty(firstName),
                errorLastName: !fieldNotEmpty(lastName),
                errorEmail: !emailValidator(email),
                errorPhone: !phoneValidator(phone),
                errorCompany: !fieldNotEmpty(companyName),
            })

        }
    }

    render() {
        const { errorFirstName, errorLastName, errorEmail, errorPhone,
                errorCompany, errorEmailExists} = this.state;
        console.log(this.state);
        const { errorMessage } = this.props;
        return (
        <div>
            <header className={'flex items-center'}>
                <ul className={`block items-center md:flex`}>
                    <li onClick={this.hideMenu}>
                        <Link to="/">
                            <img src={logo} alt='logo' />
                        </Link>
                    </li>
                </ul>
            </header>
          <div className={styles.signup_wrapper+' row'}>

            <div className={styles.topSquare}></div>

            <p className='col-md-8 offset-md-2 col-sm-10 offset-sm-1 big-size-font'>
                Get started with an Aila developer account. <br/> No credit card required.
            </p>

            <div className='col-md-8 offset-md-2'>
                <span className={errorFirstName ? styles.errorField : '' }>
                    <Input onChange={this.handleChangeName} s={6} label="* First Name" />
                </span>
                <span className={errorLastName ? styles.errorField : '' }>
                    <Input onChange={this.handleSubmitLastName} s={6} label="* Last Name" />
                </span>
            </div>

            <div className={(errorEmail ? styles.errorField : '') + ' col-md-8 offset-md-2'}>
                <Input onChange={this.handleChangeEmail} s={12} label="* Email Address"  />
                {errorEmail && errorEmailExists ? <p className={styles.error}>The specified email address already exists</p>: null}
            </div>

            <div className={(errorCompany ? styles.errorField : '') + ' col-md-8 offset-md-2'}>
               <Input onChange={this.handleSubmitCompany} s={12} label="* Company"  />
            </div>
              <div className={(errorPhone ? styles.errorField : '') + ' col-md-8 offset-md-2'}>
                <Phone value={this.state.phone} placeholder="* Phone" onChange={this.handleChangePhone} phoneCountry={this.state.phoneCountry} />
              </div>
              <div className="col-md-8 offset-md-2">
                <div className={styles.error}>
                    {!errorEmailExists ? errorMessage : null}
                </div>
              </div>
            <div className={styles.get_started_block+' col-md-8 offset-md-2 col-sm-10 offset-sm-1'}>
                <div
                    className={styles.blueBtn}
                    onClick={this.createNewUser}> Get Started </div>
                     <p>Already have account?
                    <span className={styles.login}>
                        <Link to='/'>Log in</Link>
                    </span>
                </p>
            </div>
          </div>
        </div>
        );
    }
}


const mapStateToProps = (state) => {
    const {
        userID,
        shouldChangePassword,
        userCreated,
        errorMessage,
    } = state.authentification;
    return {
        userID,
        shouldChangePassword,
        userCreated,
        errorMessage
    };
};

const mapDispatchToProps = dispatch => ({
    createUser: (userData, cb) => dispatch(createUser(userData, cb)),
});

export default connect( mapStateToProps, mapDispatchToProps)(SignUp);
