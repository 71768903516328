import {createStore, applyMiddleware, compose} from 'redux';
import serviceMiddleware from './middlewares/serviceMiddleware';
import { persistStore } from 'redux-persist';
import reducer from './reducers'; 
import reduxThunk from 'redux-thunk';

const middlewares = [
    serviceMiddleware,
    reduxThunk,
];

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const enhancer = composeEnhancers(applyMiddleware(...middlewares));
const store = createStore(reducer, {}, enhancer);

// dev only
window.store = store;

export default () => {

    return {     
        store: store,
        persistor: persistStore(store)
    }
}
