import React, { PureComponent } from 'react';
import styles from '../BillingModal.module.scss';
import { Icon, Loader } from 'semantic-ui-react'

class PaymentMethods extends PureComponent {

    renderExistingMethods = () => {
        const { methods, select, selected, toggleCreateMethods } = this.props;                
        return (
            <div>
                <p className={styles.subtitle}>Choose an existing payment method</p>
                {methods && methods.length > 0 ?
                    <ul className={styles.methods}>
                        {methods.map((method) => {
                            return (
                                <li className={method.id == selected.id ? styles.active : styles.inactive}
                                    onClick={() => { select(method) }}>
                                    {method.object == 'card' ?
                                        <div>
                                            {method.brand == 'Visa' ?
                                                <Icon name="cc visa"></Icon>
                                                :
                                                <div>
                                                    {method.brand == 'MasterCard' ? 
                                                        <Icon name="cc mastercard"></Icon>
                                                    :
                                                        <Icon name="cc"></Icon>
                                                    }
                                                </div>
                                                
                                            }
                                            <div className={styles.methodBrand}>{method.brand}</div>
                                            <div>
                                                <span>Ends: ***{method.last4} Expiry: {method.exp_month}/{method.exp_year}</span>
                                            </div>
                                        </div>
                                        :
                                        <div>
                                            <Icon name="university"></Icon>
                                            <div>
                                                <div className={styles.methodBrandACH}>{method.bank_name[0] + method.bank_name.substring(1).toLowerCase()}</div>
                                                <span>RN: {method.routing_number} Ends: ***{method.last4} </span>
                                            </div>
                                        </div>
                                    }
                                </li>
                            )
                        })}
                    </ul>
                    :
                    null
                }
                <p className={styles.toggleMethods}
                    onClick={() => { toggleCreateMethods() }}>
                    Or Register a new payment method
                </p>
            </div>);
    }

    renderCreateMethod = () => {
        const { choosePaymentType, paymentType, methods, toggleCreateMethods } = this.props;
        return (
            <div>
                <p className={styles.subtitle}>Create a new payment method</p>                
                <ul className={styles.methods}>
                    <li onClick={() => choosePaymentType('credit')} className={paymentType === 'credit' ? styles.active : styles.inactive}>
                        <div>
                            <Icon name="cc"></Icon>
                            <div className={styles.methodNew}>Credit/Debit Card</div>
                            <div>                            
                                <span>Pay with Visa, Master Card, AMEX</span>
                            </div>
                        </div>
                    </li>
                    <li onClick={() => choosePaymentType('ach')} className={paymentType === 'ach' ? styles.active : styles.inactive}>
                        <Icon name="university"></Icon>
                        <div className={styles.methodNew}>ACH Option</div>
                        <div>                            
                            <span>Pay with Checking account</span>
                        </div>
                    </li>
                </ul>
                { methods.length !== 0 ?
                    <p className={styles.toggleMethods}
                       onClick={() => { toggleCreateMethods() }}>
                            Or use an existing payment method
                    </p>
                    : null
                }
                
            </div>
        );
    }

    render() {                
        const { methods, showCreateMethods } = this.props;        
        return (
            <div>
                {!methods ? 
                    <div>
                        <p className={styles.subtitle}>Choose a payment method</p>       
                        <div className={styles.loaderContainer}>                
                            <Loader active inline />
                        </div>                    
                    </div>                    
                :
                    <div>
                        { showCreateMethods || methods.length == 0 ? this.renderCreateMethod() : this.renderExistingMethods() }
                    </div>                    
                }
                
            </div>             
        )
        
    }
}

export default PaymentMethods;