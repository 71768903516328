import React, { Component } from 'react';
import styles from './PaymentHistoryItem.module.scss';
import { formatDate } from '../../utils/helpers';

class PaymentHistoryItem extends Component {
    constructor(props) {
        super(props);
        this.state = {}
        
        this.handleOnclickDownload = this.handleOnclickDownload.bind(this);
    }

    handleOnclickDownload() {
      const { invoicedPdf } = this.props.paymentData;
      this.props.downloadPDF(invoicedPdf);
    }
    
    render() {
        const { paymentData } = this.props;
        const { date, status, amount, method } = paymentData;
                    
        return (
            <tr className={styles.paymentHistoryItem}>
                <td>{formatDate(date)}</td>
                <td >{method}</td>
                <td className={styles.currency}>{amount}</td>
                <td>{status}</td>
                <td>
                    <div className={styles.download} onClick={this.handleOnclickDownload}>
                        Download PDF                     
                    </div>     
                </td>                
            </tr>            
        );
  }
}

export default PaymentHistoryItem;