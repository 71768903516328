import React, { Component } from 'react';
import { connect } from 'react-redux';
import styles from './UserItem.module.scss';
import EditUser from '../EditUser/EditUser';
import { INACTIVE_USER } from '../../store/constants/usersManage';
import { requestResetPwd } from '../../store/AC/authentification'

class UserItem extends Component {

    
    deleteUser = () => {
        const { id, email } = this.props.userData;
        this.props.makeInactiveUserById(id);
        this.props.showModalWindow({
            openModal: true,
            modalTitle: 'Delete User',
            modalDescription: `Are you sure you would like to delete customer ${email}?`,
            action: INACTIVE_USER
        })
    }
    editUser = () => {
        const { userData, editUserById, } = this.props;
        editUserById(userData.id)
    }
    resetUserPwd = () => {        
        const { userData, requestResetPwd} = this.props;
        requestResetPwd({email: userData.email});
    }
    updateUser = (data) => {
        this.props.save(data);
    }

    render() {
        const { userData, editableUserId } = this.props;
        const { id, firstName, lastName, phone, email, partner, active } = userData;            
        return (
            <tr className={styles.userItem}>
                <td>{firstName}</td>
                <td>{lastName}</td>
                <td>{email}</td>
                <td>{phone}</td>
                <td>{partner ? 'Partner' : 'Not Partner'}</td>
                <td>{active ? 'True' : 'False'}</td>
                <td>
                    <div className={styles.edit} onClick={this.editUser}>
                        Edit                        
                    </div>
                    <div className={styles.reset} onClick={this.resetUserPwd}>
                        Reset
                    </div>                    
                    <div className={styles.modalContainer}>
                        {editableUserId === id ?  
                            <EditUser
                                saveBtn={this.updateUser}
                                userData={userData}
                                {...this.props}
                            />
                        : null }
                    </div>
                </td>
            </tr>
        );
  }
}

const mapDispatchToProps = (dispatch) => {
    return {
        requestResetPwd: (email, cb) => dispatch(requestResetPwd(email, cb))
    }    
}

export default connect(null, mapDispatchToProps)(UserItem);